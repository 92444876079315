
import { Text } from "@chakra-ui/react";
import React from "react";

function Help() {

    return (
        <Text>Help</Text>
    )
}


export default Help;