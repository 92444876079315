import React from "react";
import { FormControl, FormLabel, Input, Button, Text, Icon, Flex } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { FiAlertTriangle } from "react-icons/fi";

interface ModalProps {
  title: string;
  item: string;
  isOpen: boolean;
  onClose: any;
  onChange: any;
  onSave: any;
  disableSubmit?: boolean;
  description?: boolean;
  onChangeDescription?: any;
  warningMessage?: string | null;
}

export default function AddModal({ title, item, isOpen, onClose, onChange, onSave, disableSubmit, description = false,
  onChangeDescription = null, warningMessage = null }: ModalProps) {

  const initialRef = React.useRef()
  const finalRef = React.useRef()

  return (

    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {warningMessage &&
          <Flex direction={"row"} mx="1rem">
            <Icon as={FiAlertTriangle} color={"brand.400"} width="3rem" h="full" />
            <Text as="b"> {warningMessage} </Text>
          </Flex>
        }
        <FormControl isRequired >
          <ModalBody pb={6}>
            <FormLabel>{item} name</FormLabel>
            <Input colorScheme="brand.100" placeholder={`${item} name`} onChange={(e) => onChange(e.target.value)} />
            {description && onChangeDescription &&
              <>
                <FormLabel>description</FormLabel>
                <Input colorScheme="brand.100" placeholder={`${item} description`} onChange={(e) => onChangeDescription(e.target.value)} />
              </>
            }
          </ModalBody>
          <ModalFooter>
            <Button variant="buttonPrimary" mr={3} type="submit" isDisabled={disableSubmit} onClick={(e) => { e.preventDefault(); onSave(); onClose() }}>
              Save
            </Button>
            <Button variant="buttonSecondary" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </FormControl>
      </ModalContent>
    </Modal>
  )
}