import React, { useEffect, useState } from "react";
import { Box, Flex, Spacer, Text, List, ListItem, Grid, GridItem, Icon, Divider, Popover, PopoverTrigger, IconButton, Spinner } from "@chakra-ui/react";
import { useOutletContext, useParams } from "react-router-dom";
import { FiX, FiTrash2 } from "react-icons/fi";
import GroupAccordion from "./GroupAccordion.tsx";
import DeletePopover from "./shared/DeletePopover.tsx";
import { deleteOne, getOne, overwriteOne } from "../../services/crud.ts";
import { SettingsItem } from "../../structures/types.tsx";
import { formatName } from "../../utils/formatUtils.ts";
import Alert from "../shared/Alert.tsx";


function Group() {

  let { groupName } = useParams();
  let [users, privileges, onDeleteGroup, setErrorMessage] = useOutletContext();

  const initialFocusRef = React.useRef

  const [loadingPrivileges, setLoadingPrivileges] = useState<boolean>(false)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [groupUsers, setGroupUsers] = useState<Array<SettingsItem>>([{ label: "s.mantuano", name: "s.mantuano" }, { name: "g.mandarano", label: "g.mandarano" }])
  const [groupPrivileges, setGroupPrivileges] = useState<Array<SettingsItem>>([{ name: "add_dependencies", label: "Add dependencies" }])

  const groupLabel = formatName(groupName)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onClose = () => { setIsOpen(false) }
  const onOpen = () => { setIsOpen(true) }

  function onDeletePrivilege(privilege) {
    setLoadingPrivileges(true)
    deleteOne("groups/privilege", `?privilege_name=${privilege?.name}&group_name=${groupName}`).then(() => {
      getOne("groups/group_spec", groupName || "").then((resp) => {
        setGroupUsers(resp?.data.users); setGroupPrivileges(resp?.data.privileges);
        setLoadingPrivileges(false)
      })
    })
  }

  function onDeleteUsers(user) {
    setLoadingUsers(true)
    deleteOne("groups/user", `?user_name=${user?.name}&group_name=${groupName}`).then(() => {
      getOne("groups/group_spec", groupName || "").then((resp) => {
        setGroupUsers(resp?.data.users); setGroupPrivileges(resp?.data.privileges);
        setLoadingUsers(false)
      })
    })
  }


  function onAddUsers(usersToAdd) {
    setLoadingUsers(true)
    overwriteOne("groups/users", `?group_name=${groupName}`, usersToAdd).then(() => {
      getOne("groups/group_spec", groupName || "").then((resp) => {
        setGroupUsers(resp?.data.users);
        setGroupPrivileges(resp?.data.privileges)
        setLoadingUsers(false)
      })
    }

    )

  }
  function onAddPrivileges(privilegesToAdd) {
    setLoadingPrivileges(true)
    overwriteOne("groups/privileges", `?group_name=${groupName}`, privilegesToAdd).then(() => {
      getOne("groups/group_spec", groupName || "").then((resp) => {
        setGroupUsers(resp?.data.users);
        setGroupPrivileges(resp?.data.privileges)
        setLoadingPrivileges(false)
      })
    }
    )

  }


  useEffect(() => {
    setLoadingUsers(true)
    setLoadingPrivileges(true)
    setGroupUsers([]);
    setGroupPrivileges([])
    getOne("groups/group_spec", groupName || "").then((resp) => {
      if (resp?.status === 200 || resp?.status === "200" || resp?.data) {
        setGroupUsers(resp?.data.users); setGroupPrivileges(resp?.data.privileges); setLoadingUsers(false);
        setLoadingPrivileges(false)
      }
      else {
        setErrorMessage(resp?.message)
        setLoadingUsers(false)
        setLoadingPrivileges(false)
      }
    })

  }, [groupName, setErrorMessage])


  return (
    <Box>
      <Flex alignItems='center' height="4rem" pl="2rem">
        <Text fontSize="xl">{groupLabel}</Text>
        <Spacer />
        <IconButton aria-label="delete-group" icon={<FiTrash2 />} size='lg' mx="2rem" ml="1rem" cursor="pointer"
              bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen} />
        <Alert message={`You are deleting group ${groupLabel}`} isOpen={isOpen} onClose={onClose}
         onConfirm={(e) => { onDeleteGroup(groupName); setIsOpen(false) }}
        />
        {/* <Popover
          initialFocusRef={initialFocusRef}
          placement={'bottom'}
          closeOnBlur={false}
          isOpen={isOpen}
          size={"lg"}
        >
          <PopoverTrigger>
            <IconButton aria-label="delete-reason" icon={<FiTrash2 />} size='lg' mx="2rem" ml="1rem" cursor="pointer"
              bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen} />

          </PopoverTrigger>
          <DeletePopover item="group" label={groupLabel} onClose={onClose} onConfirm={(e) => { onDeleteGroup(groupName); setIsOpen(false) }} />
        </Popover> */}
      </Flex>
      <Divider borderColor="brand.100" w="95%" mx="1rem" />
      <Grid alignItems="baseline" h="full" mx="2rem" my="4rem"
        templateRows='repeat(2, 1fr)'
        templateColumns='repeat(2, 1fr)'
        gap={4} >
        <GridItem rowSpan={2} colSpan={1} mx="2rem">
          {users && <GroupAccordion groupName={groupName} title="Users" specifics={users.filter((user) => !groupUsers.map((item) => { return item["name"] }).includes(user?.name))}
            onAdd={onAddUsers} />}
          {loadingUsers
            ?
            <Flex justifyContent={"center"} alignItems={"center"} pt="5rem">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.100" size="lg" />
            </Flex>
            :
            <List my="2rem" mx="2rem">{
              groupUsers.map((item, index) => {
                return (
                  <ListItem my="0.5rem" borderBottom="1px" borderBottomColor="gray.300">
                    <Flex direction="row">
                      <Text>{item.label}</Text>
                      <Spacer />
                      <Icon as={FiX} cursor="pointer" onClick={(e) => onDeleteUsers(item)} />
                    </Flex>
                  </ListItem>)
              })
            }</List>}


        </GridItem>
        <GridItem rowSpan={2} colSpan={1} mx="2rem">
          {privileges && <GroupAccordion groupName={groupName} title="Privileges" specifics={privileges.filter((privilege) =>
            !groupPrivileges.map((item) => { return item["name"] }).includes(privilege?.name))} onAdd={onAddPrivileges} />
          }
          {loadingPrivileges ?
            <Flex justifyContent={"center"} alignSelf={"center"} pt="5rem">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.100" size="lg" />
            </Flex>
            :
            <List my="2rem" mx="2rem">{
              groupPrivileges.map((item, index) => {
                return (
                  <ListItem my="0.5rem" borderBottom="1px" borderBottomColor="gray.300" >
                    <Flex direction="row">
                      <Text>{item.label}</Text>
                      <Spacer />
                      <Icon as={FiX} cursor="pointer" onClick={(e) => onDeletePrivilege(item)} />
                    </Flex>
                  </ListItem>)
              })
            }
            </List>
          }
        </GridItem>
      </Grid>
    </Box>
  )
}

export default Group;