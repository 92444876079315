import { AxiosRequestConfig, AxiosResponse } from 'axios';
import urljoin from 'url-join';
import { baseURL, http, httpSave } from './api.ts';
import { loginRequest } from "../services/authConfig";
import { msalInstance } from "../index.tsx";
import { InteractionRequiredAuthError } from '@azure/msal-common';


export interface ExtendedAxiosConfig {
    full?: boolean;
    skippedCodes?: number[];
}


const OAuthConfig = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    }

    var request = {
        scopes: ["User.Read"],
        loginHint: activeAccount?.username || accounts[0].username // For v1 endpoints, use upn from idToken claims
    };


    const authResult = await msalInstance.acquireTokenSilent({ ...loginRequest, account: activeAccount || accounts[0] }).catch(
        error => {
            console.log(error)
            if (error instanceof InteractionRequiredAuthError) {
                
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request)
            }
        });;
    console.log(authResult)

    return {
        headers: {
            Authorization: authResult.idToken, AccessToken: authResult.accessToken
        }
    }
};


async function getUserPrivileges(resource: string, config?: AxiosRequestConfig,): Promise<any | AxiosResponse> {

    return http.get(resource, { ...await OAuthConfig(), ...config });
}

async function getAll(resource: string, config?: AxiosRequestConfig): Promise<any | AxiosResponse> {

    return http.get(resource, { ...await OAuthConfig(), ...config });
}

async function getOne(
    resource: string,
    params: string,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.get(urljoin(resource, params), { ...await OAuthConfig(), ...config });
}

async function getDatabase(
    resource: string,
    params: any,
    payload?: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.post(urljoin(resource, params), payload, { ...await OAuthConfig(), ...config });
}

async function calculateFormula(
    resource: string,
    payload: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.post(resource, payload, { ...await OAuthConfig(), ...config });
}

async function createOne(
    resource: string,
    group_name: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.post(resource, group_name, { ...await OAuthConfig(), ...config });
}

async function getWithPayload(
    resource: string,
    payload: any,
    params?: string,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return http.post(urljoin(resource, params || ""), payload, { ...await OAuthConfig(), ...config });
}

async function updateOne(
    resource: string,
    params?: string,
    payload?: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return httpSave.patch(urljoin(resource, params || ""), payload, { ...await OAuthConfig(), ...config });
}

async function saveOne(
    resource: string,
    params?: string,
    payload?: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return httpSave.put(urljoin(resource, params || ""), payload, { ...await OAuthConfig(), ...config });
}


async function overwriteOne(
    resource: string,
    params?: string,
    payload?: any,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    return httpSave.put(urljoin(resource, params || ""), payload, { ...await OAuthConfig(), ...config });
}

async function deleteOne(
    resource: string,
    params: string,
    config?: AxiosRequestConfig & ExtendedAxiosConfig,
): Promise<any | AxiosResponse> {
    console.log(urljoin(resource, params))
    return httpSave.delete(urljoin(resource, params), { ...await OAuthConfig(), ...config });
}

export {
    getAll, getOne, getDatabase, createOne, saveOne, overwriteOne, updateOne, deleteOne, calculateFormula,
    getUserPrivileges, getWithPayload
};
