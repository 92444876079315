
import React from "react";
import { Box } from "@chakra-ui/react";
import TireSetup from "./TireSetup";
import Static from "./Static";
import HeatBuildUp from "./HeatBuildUp";


function AIPanel({ section }) {
    return (
        <Box mt="1rem">
            {section === "tire setup" ?
                <TireSetup /> :
                section === "static tests" ?
                    <Static />
                    :
                    section === "hbu tests" ?
                        <HeatBuildUp />
                        : 
                        <></>
                        }
        </Box>

    )
}


export default AIPanel;