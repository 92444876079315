import React from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { Box } from '@chakra-ui/react';
import MsalGroup from './components/login/MsalGroup';
import { InteractionType } from '@azure/msal-browser';
import YokohamaTwsApp from './components/YokohamaTwsApp';


export default function App() {

    const validAzureAdGroupIds = ["260e7114-005b-4ca1-bc4c-28d53a26c7c0", "84f0ae82-7c09-4e31-9577-eb1ab6524a65"]

    //const validAzureAdGroupIds = ["260e7114-005b-4ca1-bc4c-28d53a26c7c1", "84f0ae82-7c09-4e31-9577-eb1ab6523a65"] //prova id non validi

    useMsalAuthentication(InteractionType.Redirect);


    return (
        <Box as="section" width="full" height="100vh" >
            <MsalGroup validAzureAdGroupIds={validAzureAdGroupIds}>
                <Box bgColor="white" width="full" h="inherit" >
                    <YokohamaTwsApp />
                </Box>
            </MsalGroup>
        </Box>
    );
}
