import React, { useState, useEffect } from "react";
import { Box, Button, Container, Flex, Grid, Icon, IconButton, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";


function PermissionDenied() {

    const navigate = useNavigate()

    return (
        <Flex justifyContent={"center"} alignItems={"center"} h="inherit" >
        <Flex direction="column" justifyContent={"center"} alignItems={"center"}>
            <Container id="denied_permission_container" mb="5rem" >
                <Grid>
                    <Box py="2rem" px="1rem">
                        <Flex
                            pt="3rem"
                            alignItems="center"
                            justifyContent="start"
                            flexDirection="column"
                            my="2rem"
                        >
                            <Icon as={FiAlertTriangle} color={"red"} width="40px" height={"40px"}/>
                            <Text my="2rem" fontSize={"2xl"}>Permission denied</Text>
                            <Text fontSize={"xl"}>You have not the permission to access the page </Text>
                            <Flex direction="column" alignItems={"center"} mx={["1rem", "1.5rem", "2rem"]} mt="3rem">
                                <Button variant="buttonSecondary" width="fit-content"
                                    onClick={() => {navigate("/")}} >
                                Return to dashboard</Button>
                            </Flex>
                        </Flex>
                    </Box>
                </Grid>
            </Container>
        </Flex>
    </Flex>
    )
}

export default PermissionDenied;