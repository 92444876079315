
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Active from "./Active";
import Deleted from "./Deleted";

export function DetailsData({ data }) {
    return (
        <Flex direction="row" wrap={"wrap"} mt="2rem" overflowY={"scroll"} h="350px">
            {data?.map((feature, i) => (
                <Box mx="1.5rem" my="1rem" w={i === 0 ? 'sm' : ''} >
                    <Heading size='xs' mb="5px" textTransform='uppercase'
                    >
                        {feature.label}
                    </Heading>
                    {Array.isArray(feature.value) && feature.value?.length > 0 ?
                        <Flex direction="column" >
                            {feature.value?.map((val) => (
                                <Text >
                                    {val ? val.toString() : ""}
                                </Text>)
                            )}
                        </Flex>
                        :
                        <Text pt='2' fontSize='lg' w="full" bgColor={"brand.200"} textAlign="justify" maxW={"400px"}>
                            {feature.value}
                        </Text>}
                </Box>
            ))}
        </Flex>)
}

export default function Details({ type, title, onClose, onConfirmAction, updateDetail }) {

    return (
        <Flex m="1rem">
            {type === "active" ?
                <Active title={title} onClose={onClose} onConfirmAction={onConfirmAction} updateDetail={updateDetail} />
                :
                <Deleted title={title} onClose={onClose} onConfirmAction={onConfirmAction} />}
        </Flex>
    )
}