
import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, Flex, FormLabel, FormControl, Grid, GridItem, Text, Input, Button } from "@chakra-ui/react";
import { resultsStructureHbuTemp } from "../../../../constants/temp";
import { Loader } from "../../../shared/Loader";

function Test({ onOpenReset, reset, onRun, isRunDisabled, predictResults, onSetSwl }) {

    const [swlField, setSwlField] = useState<Object>(null)
    const [results, setResults] = useState({})
    //const [resultsPercentages, setResultsPercentages] = useState([])
    const [loadPercentage, setLoadPercentage] = useState([])

    const [loading, setLoading] = useState<boolean>(false)

    const onChangeSwl = (value) => {
        let tempField = { ...swlField }
        tempField["value"] = value
        setSwlField(tempField)
        onSetSwl(value)
        setLoadPercentage([{
            "percentage": "60",
            "value": value * 60 / 100,
            "name": "Load_kg_60"
        }, {
            "percentage": "80",
            "value": value * 80 / 100,
            "name": "Load_kg_80"
        }, {
            "percentage": "100",
            "value": value,
            "name": "Load_kg_100"
        }, {
            "percentage": "120",
            "value": value * 120 / 100,
            "name": "Load_kg_120"
        }
        ])
    }

    const onReset = () => {
        setSwlField({ value: "", label: "SWL(Kg)", name: "swl_kg" })
        setLoadPercentage(resultsStructureHbuTemp.percentage)
        const newResults = { ...resultsStructureHbuTemp }
        delete newResults.percentage
        setResults(newResults)
    }

    useEffect(() => {
        if (reset) {
            onReset()
        }
    }, [reset])

    useEffect(() => {
        if (predictResults) {
            //setResultsPercentages(predictResults.percentage)
            const newResults = { ...predictResults }
            delete newResults.percentage
            delete newResults.graphs
            setResults(newResults)
            setLoading(false)
        }
    }, [predictResults])

    useEffect(() => {
        setSwlField({ value: "", label: "SWL(Kg)", name: "swl_kg" })
        setLoadPercentage(resultsStructureHbuTemp.percentage)
        const newResults = { ...resultsStructureHbuTemp }
        delete newResults.percentage
        setResults(newResults)
    }, [])



    return (

        <Box>
            <Flex direction={"row"} alignItems={"baseline"} justifyContent={"space-between"}>
                <Flex>
                    {swlField &&
                        <FormControl
                            onChange={(e) => onChangeSwl(e.target?.value)}
                            width={"100%"}
                            mx="0.5rem"
                        >
                            <Flex direction={"row"} alignItems={"end"}>
                                <FormLabel>{swlField.label}</FormLabel>
                                <Input borderColor="brand.300" width="fit-content" value={swlField?.value} />
                            </Flex>
                        </FormControl>
                    }
                </Flex>
                <Flex direction="row" gap="2rem" mr="3rem">
                    {loadPercentage.map((percentage) => {
                        return (
                            <Flex direction="row" gap="0.5rem" textAlign={"center"}>
                                <label >{percentage.percentage}%</label>
                                <Text border="1px solid" borderColor={"brand.300"} h="30px" width="100px">
                                    {percentage.value}
                                </Text>
                            </Flex>
                        )
                    })}
                </Flex>
                {/* <Button variant="buttonSecondary" onClick={onOpenReset}>RESET</Button> */}
            </Flex>
            <Card bg="brand.200" border="1px solid" borderColor={"brand.300"} mt="1rem" h="500px" >
                <CardBody >
                    <Loader isLoading={loading} message={"Executing AI algorithms ... "}>
                        <Grid templateColumns={"repeat(10, 1fr)"}  >
                            <GridItem colSpan={7} px="0.5rem"
                            //</Grid>borderLeft="1px solid" borderColor={"brand.300"} px="1rem" 
                            >
                                <Flex justifyContent={"center"} ml="12rem"> <Text >{"Temperature (°C)"}</Text></Flex>
                                <Flex direction="row" >
                                    <Flex direction="column" justifyContent={"center"}>
                                        <Text mb="0.6rem">{"Time (min)"}</Text>
                                        <Flex direction={"column"} gap="0.12rem">
                                            {results && results?.time_min?.map((time) => (
                                                <Text as="b" bg="#FFFFF4" border="1px solid"
                                                    borderTopColor={"brand.100"}
                                                    borderBottomColor={"brand.100"} borderLeftColor={"brand.100"}
                                                    borderRightColor={"brand.300"} w="200px"
                                                    h="30px" pl="1rem">
                                                    {time.value}
                                                </Text>
                                            )
                                            )}
                                        </Flex>
                                    </Flex>
                                    {results && results?.temperatures?.map((slot, index) => {
                                        return (
                                            <Flex direction="column" justifyContent={"center"} textAlign={"center"} width="full">
                                                <Text as="b" border="1px solid" borderColor={"brand.300"} mb="0.5rem" >
                                                    {slot.slot.split("_")[1]}
                                                </Text>
                                                <Flex direction="column" gap="0.12rem">
                                                    {slot.values.map((value) => (
                                                        <Text border="1px solid" borderTopColor={"brand.100"}
                                                            borderBottomColor={"brand.100"} borderLeftColor={"brand.300"}
                                                            borderRightColor={index === 3 ? "brand.100" : "brand.300"} bg="#FFFFF4"
                                                            h="30px">
                                                            {value.value}
                                                        </Text>
                                                    ))

                                                    }
                                                </Flex>
                                            </Flex>
                                        )
                                    })}
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={3} borderLeft="1px solid" borderColor={"brand.300"} px="0.5rem">
                                <Flex direction="column" justifyContent={"space-between"} h="full" mr="-1rem">
                                    <Flex direction={"column"} gap="0.1rem" alignItems={"center"}>
                                        <Text mb="0.5rem">Time to 180 °C</Text>
                                        {results.time_to_180?.map((slot) => (
                                            <Flex direction="row" justifyContent={"center"} textAlign={"center"} width="full"
                                                gap="0.2rem">
                                                <Text as="b" border="1px solid" borderColor={"brand.300"} mb="0.5rem" h="30px"
                                                    width="20%">
                                                    {slot.slot.split("_")[1]}
                                                </Text>
                                                <Text border="1px solid" borderColor={"brand.300"} bg="white" h="30px" width="80%">
                                                    {slot.time_to_180_custom}
                                                </Text>

                                            </Flex>
                                        ))
                                        }
                                    </Flex>
                                    <Flex direction={"row"}>
                                        <Flex direction={"column"} gap="0.1rem" mx="-0.5rem" justifyContent={"end"}>
                                            {/* <Button variant="buttonPrimary" width="170px" h="30px" onClick={onOpenGraphs}>View graphs</Button> */}
                                            <Button variant="buttonSecondary" width="170px" mx="-1rem" h="30px" onClick={onOpenReset}>RESET</Button>
                                        </Flex>
                                        <Flex mx="-1rem">
                                            <Button variant="buttonPrimary" width="170px" mx="-1rem" h="62px" isDisabled={!swlField?.value || isRunDisabled}
                                                onClick={onRun}>RUN</Button>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </Loader>
                </CardBody>
            </Card>
            {/* <GraphsModal results={graphDataTemp} isOpen={isOpenGraphs} onClose={onCloseGraphs} fileNames={fileNames} /> */}
        </Box>
    )
}

export default Test;