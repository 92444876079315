import React from "react";
import { useMsal } from "@azure/msal-react";
import logo from '../../assets/images/Yokohama-TWS_Logo_positive.svg';
import { Box, Button, Container, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { handleLogin } from "../../services/login";

export function LoginPage() {

  const { instance } = useMsal();

  return (
    <Flex justifyContent={"center"} alignItems={"center"} h="inherit" bgColor="brand.200" >
      <Flex >
        <Container id="form_container" mb="5rem" >
          <Grid>
            <Box backgroundColor="white" boxShadow="base" py="2rem" px="1rem">
              <Flex
                alignItems="center"
                flexDirection="column"
                justifyContent={"space-between"}
              >
                <Image src={logo} w={["200px", "300px"]} marginX={["1rem", "2rem", "3rem"]} m="2rem" />
                <Text my="1rem" fontSize={["md", "lg", "xl"]} marginX={["1rem", "1rem", "3rem"]}
                  alignSelf={"end"} lineHeight={"1"}>Solid Tires Tests & AI App</Text>
              </Flex>

              <Flex
                pt="3rem"
                alignItems="center"
                justifyContent="start"
                flexDirection="column"
                my="2rem"
              >
                <Button variant="buttonSecondary" size="buttonPrimarySize" width="300px" fontSize={"lg"} 
                onClick={() => handleLogin(instance)}>
                  Login
                </Button>
                <Box my={4}>
                  <Text fontSize={["md", "lg", "xl"]} marginX={["1rem", "1rem", "3rem"]}>
                    Login with azure
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Grid>
        </Container>
      </Flex>
    </Flex>
  );
};

