import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import UnauthorizedPage from "./UnauthorizedPage";


const MsalGroup = (props) => {
  const { instance, accounts } = useMsal();
  const { children, validAzureAdGroupIds } = props;
  const [authorized, setAuthorized] = useState(null);

  async function checkADGroups() {

    if (accounts.length === 0) {
      setAuthorized(false)
      return
    }

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        if (
          !response ||
          !response.idTokenClaims ||
          !response.idTokenClaims.groups
        ) {
          setAuthorized(false);
        } else {
          setAuthorized(
            response.idTokenClaims.groups.includes(validAzureAdGroupIds[0]) ||
            response.idTokenClaims.groups.includes(validAzureAdGroupIds[1])
          );
        }
      });
  }
  useEffect(() => {
    checkADGroups()

  }, [])



  return (

    <>
      {authorized &&
        <>{children}</>}
      {authorized === false &&
        <UnauthorizedPage />
      }
    </>
  );
};

export default MsalGroup;