import React, { useContext } from "react";
import { Divider, Flex, Link } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { CheckSettingsPrivilege } from "../utils/privilegeUtils";
import { DataContext } from "./YokohamaTwsApp";


function Settings() {
    const navigate = useNavigate()

    const userPrivileges = useContext(DataContext)

    return (
        <Flex >
            <Flex bgColor="brand.200" height={"4rem"} alignItems="center" width="full">
                {CheckSettingsPrivilege("Groups", userPrivileges) &&
                    <Link mx="2rem" onClick={(e) => navigate("/settings/groups")} >Groups</Link>
                }
                {CheckSettingsPrivilege("DeleteReasons", userPrivileges) &&
                    <>
                        <Divider borderColor="brand.100" h="75%" orientation='vertical' />
                        <Link mx="2rem" onClick={(e) => navigate("/settings/delete_reasons")}>Delete reasons</Link>
                    </>}
                {CheckSettingsPrivilege("Ratings", userPrivileges) &&
                    <>
                        <Divider borderColor="brand.100" h="75%" orientation='vertical' />
                        <Link mx="2rem" onClick={(e) => navigate("/settings/ratings")}>Ratings</Link>
                    </>}
            </Flex>
        </Flex>)
}

export default Settings;