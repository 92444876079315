
import React, { useState } from "react";
import {
    Button, Input, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Flex,
    FormControl, Text, Box, Divider, FormLabel, Checkbox, Icon
} from "@chakra-ui/react";
import ResponsiveLineGraph from "./ResponsiveLineGraph";
import { Loader } from "../../shared/Loader";
import { exportGraphToJpeg } from "../../../utils/exportUtils";
import { FiAlertTriangle } from "react-icons/fi";



function ExportModal({ graphData, isOpen, onClose, graphRefs, graphStyle, graphFileNames, onDownload }) {

    const [showLegend, setShowLegend] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState<string>("")
    const [includeGraphs, setIncludeGraphs] = useState<boolean>(false)

    const afterResponseAction = () => {
        setLoading(false)
        setShowLegend(false)
    }

    const onDownloadGraph = () => {

        setLoading(true)
        setShowLegend(true)
        graphRefs.map((graph, index) => {
            exportGraphToJpeg(graph, graphFileNames[index], afterResponseAction)

        })
    }



    return (

        <Modal isOpen={isOpen} onClose={onClose} isCentered size="full" >
            <Box height="500px" width="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader mt="1rem">Export test data</ModalHeader>
                    <Divider border="0.1px solid" borderColor={"brand.100"} width="98%" alignSelf={"center"} />
                    <ModalCloseButton />
                    <ModalBody my="1rem">
                        {graphData && graphData.length > 0 &&
                            <Loader isLoading={loading} opacity="1" >
                                <Flex direction={"row"} gap={2} width="fu">
                                    {graphRefs && graphData.map((result, index) => {
                                        return (
                                            <ResponsiveLineGraph data={result} graphRef={graphRefs[index]}
                                                showLegend={index === 0 || showLegend} index={index}
                                                graphStyle={graphStyle} />)

                                    })

                                    }
                                    {/* {graphRefs && graphData.map((result, index) => {
                                        return <GridItem colSpan={1} rowSpan={1}>
                                            <ResponsiveLineGraph data={result} graphRef={graphRefs[index]}
                                                showLegend={index === 0 || showLegend} index={index}/>
                                        </GridItem>
                                    })} */}
                                </Flex>
                            </Loader>
                        }


                        <Flex direction="column" gap="1rem" mt="1rem" mb="1rem">
                            <FormControl isRequired
                                onChange={(e) => setFileName(e.target?.value)}
                                width="40%">
                                <FormLabel>File name</FormLabel>
                                <Input value={fileName} />
                            </FormControl>
                            <FormControl onChange={(e) => setIncludeGraphs(e.target?.checked)}>
                                <Flex direction="row" gap="0.5rem" alignContent={"end"}>
                                    <Checkbox size="lg" checked={includeGraphs} />
                                    <FormLabel mb="-0.5rem">{"Include graphs in <<xlsx>> file."}</FormLabel>
                                </Flex>
                            </FormControl>
                        </Flex>


                        <Flex direction={"row"} alignItems={"center"} mt="2rem">
                            <Icon as={FiAlertTriangle} width="3rem" h="40px" mr="0.5rem" />
                            <Text> Downloading data here will save the files locally on your PC, if you want to save the results permanently
                                on the database, please close and run “SAVE IN DATABASE” . </Text>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button variant="buttonSecondary" onClick={() => downloadFileXlsx(downloadDataTemp, "", graphRefs)}
                        >DOWNLOAD DATA</Button> */}
                        <Button variant='buttonPrimary' width="fit-content" isDisabled={!fileName}
                            onClick={() => { onDownload(fileName, "csv") }} >Download data .csv</Button>
                        <Button variant='buttonPrimary' width="fit-content" isDisabled={!fileName}
                            onClick={() => { onDownload(fileName, "xlsx", includeGraphs) }} >Download data .xlsx</Button>
                        <Button variant='buttonPrimary' width="fit-content" onClick={onDownloadGraph} >Download graph .jpg</Button>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>)

}


export default ExportModal;