import React, { useContext } from "react";
import { Button, Flex, Icon, IconButton, Spacer, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { FiLogOut, FiUser } from "react-icons/fi";
import { DataContext } from "./YokohamaTwsApp";
import { CheckSettingsPrivilege } from "../utils/privilegeUtils";
import { useMsal } from "@azure/msal-react";

function Options() {

    const userPrivileges = useContext(DataContext)
    //["Groups", "DeleteReasons", "Ratings"]
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts()
    let username = ""
    if (accounts.length > 0) {
        const account = instance.getAllAccounts()[0]
        if (account) {
            username = account?.name
        }
    }
    const navigate = useNavigate()

    const handleLogout = () => {

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });

    };

    return (
        <Flex marginX={"2rem"}>
            {userPrivileges && CheckSettingsPrivilege("Settings", userPrivileges) &&
                <Button variant="buttonPrimary" size="buttonPrimarySize" ml={["inherit", "1rem", "1rem"]}
                    onClick={(e) => navigate('/settings')}>Settings</Button>
            }
            <Spacer />
            <Flex direction="column" alignSelf={"end"} ml={["1rem", "2rem", "4rem"]} alignContent={"center"}>
                <Icon alignSelf={"center"} boxSize={["4", "6", "8"]} as={FiUser} />
                <Text fontSize={["sm", "md", "lg"]}>{username}</Text>
            </Flex>
            <Spacer />
            <Flex direction="column" alignItems={"left"} mx={["1rem", "1.5rem", "2rem"]}>
                <IconButton variant="buttonSecondary" mx={["0px", "0.5px", "1px"]} width={["0.75"]} aria-label='Logout'
                    icon={<FiLogOut />} onClick={() => handleLogout()} />
                <Text>Logout</Text>
            </Flex>
        </Flex>
    )
}

export default Options;