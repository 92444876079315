import { slotSectionsMapping } from "../constants/export";
import { FormData, Slot } from "../structures/types";
import { formatLabel } from "./formatUtils";

function groupValues(values) {
    const groupedValues = values.reduce((acc, currentValue) => {
        const groupRender = currentValue["groupRender"]
        let groupKey = currentValue["group"];
        if (groupKey === undefined) {
            acc.push(currentValue)
            return acc
        }
        const groupFiltered = acc.filter((group) => { return group.name === groupKey })
        if (groupFiltered.length > 0) {
            groupFiltered[0].value = [...groupFiltered[0].value, currentValue]
        }
        else {
            const groupDict = {
                name: groupKey, value: [currentValue],
                type: "group", groupRender: groupRender,
                isGroupRequired: currentValue["isRequired"]
            }
            if (currentValue["action"]) {
                groupDict["action"] = currentValue["action"]
            }
            acc.push(groupDict)
        }
        return acc
    }, []);

    return groupedValues

}

function destructureValues(values) {
    let destructuredValues = []
    values.map((el) => {
        if (el.type === "group") {
            el.value.map((val) => destructuredValues.push(val))
        }
        else {
            destructuredValues.push(el)
        }
    })
    return destructuredValues
}


export function groupFormData(formData: { [key: string]: FormData[] }) {
    if (Object.values(formData).flat().some((el) => { return el?.group })) {
        let newData = {}
        Object.entries(formData).map(([key, values]) => {
            const groupedValues = groupValues(values)
            newData[key] = groupedValues
        });
        return newData
    }
    else {
        return formData
    }



}


export function groupFlatFormData(formData: FormData[]) {
    if (formData.some((el) => { return el?.group })) {

        const groupedValues = groupValues(formData)

        return groupedValues

    }
    else {
        return formData
    }



}


export function destructureGroupedFormData(groupedData: { [key: string]: FormData[] }) {
    if (groupedData && Object.values(groupedData).flat().some((el) => { return el.type === "group" })) {
        const destructuredData = {}
        Object.entries(groupedData).map(([key, values], index) => {
            destructuredData[key] = destructureValues(values)
        })
        return destructuredData
    }



}


export function destructureFlatGroupedFormData(flatGroupedData: FormData[]) {
    if (flatGroupedData && flatGroupedData.some((el) => { return el.type === "group" })) {

        return destructureValues(flatGroupedData)
    }
    else {
        return flatGroupedData
    }

}


export function convertPredictDataForXlsx(predictData, slots: Slot[], type: string, virtual: string, loadField: Object) {
    let exportData = []
    console.log(slots)
    slots.map((slot) => {
        if (slot?.id) {
            let slotData = {
                tread_type: slot.tread,
                product: slot.product,
                construction: slot.construction,
                test_type: type,
                ...loadField
            }

            Object.keys(predictData).map((section) => {
                const sectionSlots = predictData[section].filter((predictSlot) => { return predictSlot.slot === slot.name })
                console.log(sectionSlots)
                if (sectionSlots && sectionSlots.length > 0) {
                    const sectionSlot = sectionSlots[0]
                    sectionSlot?.values?.map((el) => {
                        if (el.value) {
                            slotData[slotSectionsMapping[formatLabel(type)][el.name]] = el.value.toString()
                        }
                    })
                    if (sectionSlot?.time_to_180_custom) {
                        slotData['time_to_180'] = sectionSlot.time_to_180_custom
                    }
                }

            })

            slotData['virtual_test'] = virtual

            exportData.push([slotData])
        }
    })

    return exportData

}


/* export function convertPredictDataForCsvOld(predictData, slots: Slot[], type: string, virtual: string, loadField: Object) {
    let exportData = {}
    slots.map((slot) => {
        if (slot?.id) {
            exportData[`tread_type_${slot.name}`] = slot.tread
            exportData[`product_${slot.name}`] = slot.product
            exportData[`construction_${slot.name}`] = slot.construction
            exportData[`test_type`] = type
            exportData[`tread_type_${slot.name}`] = slot.tread
            exportData = { ...exportData, ...loadField }

            Object.keys(predictData).map((section) => {
                const sectionSlots = predictData[section].filter((predictSlot) => { return predictSlot.slot === slot.name })
                if (sectionSlots && sectionSlots.length > 0) {
                    const sectionSlot = sectionSlots[0]
                    sectionSlot?.values?.map((el) => {
                        if (el.value) {
                            exportData[`${slotSectionsMapping[type][el.name]}_${slot.name}`] = el.value.toString()
                        }
                    })
                    if (sectionSlot?.time_to_180_custom) {
                        exportData[`time_to_180_${slot.name}`] = sectionSlot.time_to_180_custom
                    }

                }

            })

            exportData['virtual_test'] = virtual

        }
    })

    return exportData

}
 */


export function convertPredictDataForCsv(predictData, slots: Slot[], type: string, virtual: string, loadField: Object) {
    const load_key = Object.keys(loadField)[0]

    console.log(virtual)

    let exportData = {
        tread_type: [],
        product: [],
        construction: [],
        test_type: [],
        [load_key]: [],
    }

    Object.values(slotSectionsMapping[formatLabel(type)]).map((section) => {
        if (section !== 'time_to_180') { exportData[section] = [] }
    })

    exportData['virtual_test'] = []

    slots.map((slot) => {
        console.log(exportData)
        if (slot?.id) {
            exportData[`tread_type`].push(slot.tread)
            exportData[`product`].push(slot.product)
            exportData[`construction`].push(slot.construction)
            exportData[`test_type`].push(type)
            exportData[load_key].push(loadField[load_key])

            Object.keys(predictData).map((section) => {
                const sectionSlots = predictData[section].filter((predictSlot) => { return predictSlot.slot === slot.name })
                if (sectionSlots && sectionSlots.length > 0) {
                    const sectionSlot = sectionSlots[0]
                    sectionSlot?.values?.map((el) => {
                        if (el.value) {
                            exportData[slotSectionsMapping[formatLabel(type)][el.name]].push(el.value.toString())
                        }
                    })
                    if (sectionSlot?.time_to_180_custom) {
                        if (Object.keys(exportData).includes('time_to_180')) {
                            exportData[`time_to_180`].push(sectionSlot.time_to_180_custom)
                        }
                        else {
                            exportData[`time_to_180`] = [sectionSlot.time_to_180_custom]
                        }


                    }

                }

            })

            exportData['virtual_test'].push(virtual)

        }
    })

    return exportData

}


export function convertPredictDataForSave(predictData, slots: Slot[], loadField: Object, noteField: Object, purpose: string) {
    let exportData = []
    slots.map((slot) => {
        if (slot?.id) {
            let slotData = {
                construction_identification_string_select: { value: slot.construction, type: "string" },
                [Object.keys(loadField)[0]]: { value: Object.values(loadField)[0], type: "number" }
            }

            if (predictData?.percentage) {
                predictData.percentage.map((load) => {
                    slotData[load.name] = { value: load.value, type: "number" }
                })
            }

            Object.keys(predictData).map((section) => {
                const sectionSlots = predictData[section].filter((predictSlot) => { return predictSlot.slot === slot.name })
                if (sectionSlots && sectionSlots.length > 0) {
                    const sectionSlot = sectionSlots[0]
                    if (Object.keys(sectionSlot).includes("time_to_180_custom")) {
                        slotData['TimeTo_180_celsius'] = { value: sectionSlot.time_to_180_custom, type: "number" }
                    }
                    sectionSlot?.values?.map((el) => {
                        if (el.value) {
                            slotData[el.name] = { value: el.value, type: "number" }
                        }
                    })
                }
            })

            slotData = {
                ...slotData, [Object.keys(noteField)[0]]: { value: Object.values(noteField)[0], type: "string" }
            }
            slotData['TestPurpose'] = { value: purpose, type: "string" }

            exportData.push(slotData)
        }
    })

    return exportData
}