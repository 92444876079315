

export const manageAction = "manage"
export const visualizeAction = "visualize"
export const editorAction = "editor"
export const adminAction = "admin"


type privilege = {
    name: string;
    actions: string[];
    components?: string[];
    database?: string[];
    sections?: string[]
}

export const ignoreDatabasePrivilege = ["Tests"]

export const databaseAllowedRoles = ["visualize_compounds", "compounds_editor", "compounds_admin", "visualize_dependencies_info",
    "info_dependencies_editor", "info_dependencies_admin", "visualize_tires_dependencies", "tires_dependencies_editor",
    "tires_dependencies_admin", "visualize_tests", "tests_editor", "tests_admin"]

export const privileges: privilege[] = [

    { name: "manage_groups", components: ["Help", "Groups", "Settings"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "manage_reasons", components: ["Help", "DeleteReasons", "Settings"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "manage_ratings", components: ["Help", "Ratings", "Settings"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "visualize_compounds", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Compounds"], actions: [visualizeAction] },
    { name: "compounds_editor", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Compounds"], actions: [editorAction, visualizeAction] },
    { name: "compounds_admin", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Compounds"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "visualize_dependencies_info", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [visualizeAction] },
    { name: "info_dependencies_editor", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [editorAction, visualizeAction] },
    { name: "info_dependencies_admin", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "visualize_tires_dependencies", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [visualizeAction] },
    { name: "tires_dependencies_editor", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [editorAction, visualizeAction] },
    { name: "tires_dependencies_admin", components: ["Database", "Help", "DBView"], sections: ["Dependencies"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "visualize_tests", components: ["Database", "Help", "DBView"], sections: ["Tests"], database:["All"], actions: [visualizeAction] },
    { name: "tests_editor", components: ["Database", "Help", "DBView"], sections: ["Tests"], database:["All"], actions: [editorAction, visualizeAction] },
    { name: "tests_admin", components: ["Database", "Help", "DBView"], sections: ["Tests"], database:["All"], actions: [adminAction, editorAction, visualizeAction] },
    { name: "ai_tester", components: ["Help", "AITests"], actions: [adminAction, editorAction, visualizeAction] },
    {
        name: "general_admin", components: ["Database", "Help", "Settings", "Groups", "DeleteReasons", "Ratings", "DBView", "AITests"],
        sections: ["Dependencies", "Tests"],
        database: ["Compounds", "Brands", "Plants", "Rims", "Sizes", "Constructions", "Products", "Specs", "TreadProfiles", "All"],
        actions: [adminAction, editorAction, visualizeAction],
    }

]

/* const privileges: { [key: string]: privilege } = {
    manageGroups: { components: ["Groups"], actions: [manageAction] },
    manageReasons: { components: ["DeleteReasons"], actions: [manageAction] },
    manageRatings: { components: ["Ratings"], actions: [manageAction] },
    visualizeCompounds: { components: ["DBView"], database: ["Compounds"], actions: [visualizeAction] },
    compoundsEditor: { components: ["DBView"], database: ["Compounds"], actions: [editorAction] },
    compoundsAdmin: { components: ["DBView"], database: ["Compounds"], actions: [adminAction] },
    visualizeDependenciesInfo: { components: ["DBView"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [visualizeAction] },
    dependenciesInfoEditor: { components: ["DBView"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [editorAction] },
    dependenciesInfoAdmin: { components: ["DBView"], database: ["Brands", "Plants", "Rims", "Sizes"], actions: [adminAction] },
    visualizeTireDependencies: { components: ["DBView"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [visualizeAction] },
    tireDependenciesEditor: { components: ["DBView"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [editorAction] },
    tireDependenciesAdmin: { components: ["DBView"], database: ["Constructions", "Products", "Specs", "TreadProfiles"], actions: [adminAction] },
    visualizeTests: { components: ["DBView"], sections: ["Tests"], actions: [visualizeAction] },
    testsEditor: { components: ["DBView"], sections: ["Tests"], actions: [editorAction] },
    testsAdmin: { components: ["DBView"], sections: ["Tests"], actions: [adminAction] },
    AITester: { components: ["AITests"], actions: [manageAction] },
    generalAdmin: { actions: [manageAction] },



} */


