import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, GridItem, Icon, IconButton, Popover, PopoverTrigger, Spacer, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { deleteOne, getAll, getOne, overwriteOne, updateOne } from "../../services/crud.ts";
import CustomTable from "./shared/Table.tsx";
import AddModal from "./shared/AddModal.tsx";
import { FiEdit3, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import SettingsCard from "./shared/SettingsCard.tsx";
import { formatLabel } from "../../utils/formatUtils.ts";
import DeletePopover from "./shared/DeletePopover.tsx";
import { SettingsItem, Usage } from "../../structures/types.tsx";
import { Loader } from "../shared/Loader.tsx";
import { ErrorMessage } from "../shared/ErrorMessage.tsx";
import { useNavigate } from "react-router";
import Alert from "../shared/Alert.tsx";


function Ratings() {
    const navigate = useNavigate()

    const [ratings, setRatings] = useState<Array<SettingsItem>>([])
    /*  {
         name: "tier_1",
         label: "1 tier"
     },
     {
         name: "tier_2",
         label: "2 tier"
     } */

    const [disableAddSubmit, setDisableAddSubmit] = useState<boolean>(true)
    const [disableEditSubmit, setDisableEditSubmit] = useState<boolean>(true)
    const [rating, setRating] = useState<SettingsItem>(ratings[0])
    const [usages, setUsages] = useState<Array<Usage>>([])
    const [ratingToAdd, setRatingToAdd] = useState<SettingsItem | null>({ name: "", label: "" })
    const [ratingToEdit, setRatingToEdit] = useState<SettingsItem | null>({ name: "", label: "" })

    const [loading, setLoading] = useState<boolean>(false)
    const [loadingRating, setLoadingRating] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [isDeleteDisabled, setIsDeleteDisable] = useState<boolean>(false)

    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
    const onCloseAddModal = () => {
        onAddClose(); setRatingToAdd({ name: "", label: "" });
        setDisableAddSubmit(true)
    }
    const onCloseEditModal = () => {
        onEditClose(); setRatingToEdit({ name: "", label: "" });
        setDisableEditSubmit(true)
    }

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onClose = () => { setIsOpen(false) }
    const onOpen = () => { setIsOpen(true) }

    const initialFocusRef = React.useRef

    function onDeleteRating() {
        setLoading(true)
        deleteOne("ratings/delete", `?rating_name=${rating?.name}`).then((resp) => {
            if (resp?.status === 200 || resp?.status === "200" || resp?.data) {
                getAll("ratings/all").then((r) => {
                    setRatings(r?.data)
                    setRating(r?.data[0])
                    setLoading(false)
                })
            }
            else {
                setErrorMessage(resp?.response?.data || resp?.message)
                setLoading(false)
            }
        })
    }
    const onConfirm = () => { onDeleteRating(); setIsOpen(false) }


    const onChange = (label) => {
        let tempRating = { ...ratingToAdd }; tempRating["label"] = label; tempRating["name"] = formatLabel(label);
        setRatingToAdd(tempRating); if (tempRating.name === "") { setDisableAddSubmit(true) } else { setDisableAddSubmit(false) }
    }

    const onSaveRating = () => {
        setLoading(true)
        overwriteOne("ratings/save", `?rating_name=${ratingToAdd?.name}`).then((resp) => {
            getAll("ratings/all").then((r) => {
                setRatings(r?.data)
                setRating(r?.data[0])
            })
            setRatingToAdd({ name: "", label: "" })
            setDisableAddSubmit(true)
            setLoading(false)
        })

    }

    const onEdit = (label) => {
        let tempRating = { ...ratingToEdit }; tempRating["label"] = label; tempRating["name"] = formatLabel(label);
        setRatingToEdit(tempRating); if (tempRating.name === "") { setDisableEditSubmit(true) } else { setDisableEditSubmit(false) }
    }

    const onSaveEdit = () => {
        setLoading(true)
        updateOne("ratings/update", `?rating_name=${rating?.name}`, { ...ratingToEdit }).then((resp) => {
            getAll("ratings/all").then((r) => {
                setRatings(r?.data)
                setRating(r?.data[0])
            })
            setRatingToEdit({ name: "", label: "" })
            setDisableEditSubmit(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        getAll("ratings/all").then((resp) => {
            setRatings(resp?.data)
            setRating(resp?.data[0])
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (rating) {
            setLoadingRating(true)
            navigate(`ratings/${rating?.name}`)
            getOne("ratings/usages", rating?.name).then((resp) => {
                setUsages(resp?.data?.entities)
                setIsDeleteDisable(!resp?.data?.deletable)
                setLoadingRating(false)
            })
        }
    }, [rating])



    return (
        <Loader isLoading={loading}>
            <Box h="700px">
                {errorMessage && ErrorMessage(errorMessage)}
                <Grid h="full" m="2rem" templateRows={['repeat(2, 1fr)', 'repeat(1, 1fr)']} templateColumns={['repeat(1, 1fr)', 'repeat(5, 1fr)']} gap={4} >
                    <GridItem rowSpan={2} colSpan={3} bgColor={"brand.200"} border="1px solid" borderColor="brand.100">
                        <CustomTable columns={["Rating"]} items={ratings} selectedItem={rating} onRowSelect={(el) => setRating(el)} />
                    </GridItem>
                    <GridItem colSpan={2} bg='brand.200' >
                        <Flex direction={"row"} m="1rem">
                            <Spacer />
                            <Icon
                                as={FiPlusCircle}
                                aria-label={`prova`}
                                height="auto"
                                color="brand.100"
                                onClick={onAddOpen}
                                width="1.5rem"
                                mx="1rem"
                                cursor="pointer"
                                _hover={
                                    { color: 'brand.300' }
                                }
                            />
                            <Text fontSize="xl" >Add new</Text>
                            <AddModal title="Add new rating" item="rating" isOpen={isAddOpen} onClose={onCloseAddModal} onChange={onChange} onSave={onSaveRating}
                                disableSubmit={disableAddSubmit} />
                        </Flex>
                        {rating &&
                            <>
                                {loadingRating ?
                                    <Flex justifyContent={"center"} alignSelf={"center"} pt="5rem">
                                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.100" size="lg" />
                                    </Flex> :
                                    <>
                                        <SettingsCard title={"Used in:"} entities={usages} />
                                        <Flex direction="row" justifyContent={"end"} alignContent={"end"} mx="1rem">
                                            <IconButton aria-label="edit-rating" icon={<FiEdit3 />} size='lg' ml="1rem" cursor="pointer"
                                                bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={onEditOpen}
                                            />
                                            <Text alignSelf="center" fontSize="lg" >Edit</Text>
                                            <IconButton aria-label="delete-rating" icon={<FiTrash2 />} size='lg' ml="1rem" cursor="pointer"
                                                bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen}
                                                isDisabled={isDeleteDisabled} />
                                            <Alert message={`You are deleting group ${rating?.label}`} isOpen={isOpen} onClose={onClose}
                                                onConfirm={onConfirm}
                                            />
                                            {/* <Popover
                                                initialFocusRef={initialFocusRef}
                                                placement={'bottom'}
                                                closeOnBlur={false}
                                                isOpen={isOpen}
                                                size={"lg"}
                                            >
                                                <PopoverTrigger>
                                                    <IconButton aria-label="delete-rating" icon={<FiTrash2 />} size='lg' ml="1rem" cursor="pointer"
                                                        bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen}
                                                        isDisabled={isDeleteDisabled} />

                                                </PopoverTrigger>
                                                <DeletePopover item="rating" label={rating?.label} onClose={onClose} onConfirm={onConfirm} />

                                            </Popover> */}

                                            <Text alignSelf="center" fontSize="lg">Delete</Text>
                                        </Flex>
                                    </>}
                            </>
                        }
                    </GridItem>
                    <AddModal title="Editing rating" item="rating" isOpen={isEditOpen} onClose={onCloseEditModal} onChange={onEdit} onSave={onSaveEdit}
                        disableSubmit={disableEditSubmit} />
                </Grid>
            </Box>
        </Loader>
    )
}


export default Ratings;