import React, { useEffect, useState } from "react";
import { AccordionPanel, Accordion, AccordionButton, AccordionIcon, AccordionItem, Box, List, ListItem, Text, Divider, Button, Flex }
  from "@chakra-ui/react";

export default function GroupAccordion({ groupName, title, specifics, onAdd }) {

  const [itemsToAdd, setItemsToAdd] = useState<Array<string>>([])

  const onToggleUserSelection = (item) => {
    if (itemsToAdd.includes(item?.name)) {
      setItemsToAdd(itemsToAdd.filter(function (el) { console.log(el); return el !== item?.name }))
    }
    else {
      setItemsToAdd([...itemsToAdd, item?.name])
    }

  }

  useEffect(() => {
    setItemsToAdd([])
  }, [groupName])


  return (

    <Accordion defaultIndex={[]} allowToggle>
      <AccordionItem border="0">
        <h2>
          <AccordionButton>
            <Box as="span" flex='1' textAlign='left'>
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton >
        </h2>
        <Divider border="1px solid" borderColor="brand.100" />
        <AccordionPanel pb={4} border="1px solid" borderColor="gray.300" >
          <List>{
            specifics.map((item, index) => {
              return (
                <ListItem my="0.5rem" borderBottom="1px" borderBottomColor="gray.300" cursor={"pointer"}
                  onClick={() => onToggleUserSelection(item)} bgColor={itemsToAdd?.includes(item?.name) ? "brand.300" : "brand.200"} >
                  <Text>{item.label}</Text>
                </ListItem>)
            })
          }</List>
          <Flex justifyContent={"end"}><Button variant={"buttonSecondary"} isDisabled={!itemsToAdd || itemsToAdd.length === 0}
            onClick={() => { onAdd(itemsToAdd); setItemsToAdd([]) }}>Add {title}</Button></Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>)
}