import React, { HTMLAttributes } from 'react';
import { Box } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/date-picker.css';

interface Props {
    isClearable?: boolean;
    onChange?: (dates: any) => any;
    selectedDate: any;
    startDate: Date | undefined;
    endDate?: Date | undefined;
    showPopperArrow?: boolean;
    isRange?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

const DatePicker = ({
    selectedDate,
    onChange,
    isClearable = true,
    showPopperArrow = false,
    startDate,
    endDate,
    isRange = false,
    disabled = false,
    placeholder = 'Insert date',
}: Props & HTMLAttributes<HTMLElement>) => {

    console.log(selectedDate)

    return (

        <Box maxW={"-moz-fit-content"} display="block">
            <ReactDatePicker
                width="inherit"
                disabled={disabled}
                selected={selectedDate}
                dateFormat={'yyyy/MM/dd'}
                onChange={onChange}
                maxDate={new Date()}
                isClearable={isClearable}
                startDate={startDate}
                endDate={endDate}
                showPopperArrow={showPopperArrow}
                selectsRange={isRange}
                placeholderText={placeholder}
                className="react-datapicker__input-text" />
        </Box>
    );
};

export default DatePicker;
