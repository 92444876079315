import React from "react";
import { Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import DatePicker from "../../../shared/DatePicker";
import moment from 'moment';



function Field({ field, onChange, section, checkRequiredOnCondition = null, checkDisabledOnCondition = null, groupName = null, formWidth = "100%",
    inputBorder = false, showLabel = true , highLabel=false}) {
    const isRequired = field?.requiredOnCondition ? checkRequiredOnCondition(field?.requiredOnCondition) : field.isRequired
    const isDisabled = field?.disabledOnCondition ? checkDisabledOnCondition(field?.disabledOnCondition) : field?.isDisabled
    const onFieldChange = (event) => {
        const value = field?.type === "datetime" ? event : field?.render === "checkbox" ? event.target.checked : event.target.value
        onChange(field.name, value, field?.type, section, groupName)
    }

    return (
        <FormControl
            isRequired={isRequired}
            isDisabled={isDisabled}
            onChange={(e) => onFieldChange(e)}
            defaultValue={field?.defaultValue}
            defaultChecked={field?.value}
            width={formWidth}
            mx="0.5rem" my="0.5rem">
            <Flex direction={field.render === "checkbox" || field.render === "radio" ? "row" : "column"}>

                {showLabel && <FormLabel h={highLabel ? "90px" : "50px"} mb="10px" >{field.label}</FormLabel>}
                {field?.render === "show" ?
                    <Flex bg="white" justify={"right"} border="1px solid" borderColor="brand.300" borderRadius={"5px"} h="30px">
                        <Text mx="0.5rem">{field.value}</Text>
                    </Flex>
                    :
                    field.type === "datetime" ?
                        <DatePicker
                            style={{ padding: '0' }}
                            onChange={onFieldChange}
                            selectedDate={
                                field?.value ? new Date(moment(field.value, 'DD-MM-YYYY').format('YYYY-MM-DD')) : undefined
                            }
                            startDate={new Date()}
                            isRange={false}
                            isClearable={false}
                        />
                        :
                        field.render === "select" ?
                            <Select placeholder='Select option' defaultValue={field?.value}  >
                                {field.options?.map((option) => (
                                    <option value={option}>
                                        {option.toString()}
                                    </option>)
                                )}
                            </Select>
                            :
                            field.render === "radio" ?
                                <RadioGroup defaultValue={field?.value}>
                                    <Stack direction='column'>
                                        {field.options?.map((option) => (<Radio value={option}>{option}</Radio>))}
                                    </Stack>
                                </RadioGroup>
                                :
                                field.type === "bool" ?
                                    field.render === "trafficlight" ?
                                        <Text bg={field?.value === true ? "green.500" : "red.500"} color="brand.200">
                                            {field?.value === true ? "OK" : "KO"}</Text>
                                        :
                                        <Checkbox style={{ outline: "1px solid gray" }} h="18px" />
                                    :
                                    field?.render === "textarea" ?
                                        <Textarea placeholder={'Insert note here'} h="6rem" w="lg" value={field?.value} />
                                        :
                                        (field.type === "number" || field.type === "string") &&
                                        (field.isEditable ?
                                            <Input value={field?.value || ''} h={'30px'} my={1} style={inputBorder ?
                                                { border: "1px solid gray", boxSizing: "border-box" } :
                                                { boxSizing: "content-box", width: "inherit" }} />
                                            :
                                            <Text fontSize={"lg"} mb="1" mt="1.5" minH={'30px'}
                                                bg={field?.action || field?.render === "currency" ? "gray.200" : "brand.200"}>
                                                {field?.value}
                                            </Text>
                                        )

                }
            </Flex>
        </FormControl>
    )
}

export default Field;