import React from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle,  } from "@chakra-ui/react";


export function ErrorMessage(errorMessage) {
    
    return (
        <>
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Error while calling services</AlertTitle>
        <AlertDescription>Message: {errorMessage}</AlertDescription>
      </Alert>
      </>
    )
  }