
import React from "react";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import Field from "./Field";


function FormFields({ data, onChange, section, checkRequiredOnCondition = null, checkDisabledOnCondition = null,
}) {
    // console.log(data)

    const isGroupedColumns = data?.filter((el) => { return el?.type === "group" && el?.groupRender === "column" }).length > 0
    const isGroupedSubSections = data?.filter((el) => { return el?.type === "group" && el?.groupRender === "subSection" }).length > 0

    let columnsGrouped = 0
    let columnsNotGrouped = 0
    if (isGroupedColumns) {
        columnsGrouped = data?.filter((el) => { return el?.type === "group" && el?.groupRender === "column" }).length
        columnsNotGrouped = data?.findIndex((el) => { return el?.type === "group" })
    }

    const highLabel = data.some((el) => { return el?.label?.length > 22 })

    return (
        <Box mx="2rem" my="2rem">
            {isGroupedSubSections ?
                (<Grid >
                    {data?.map((field, index) => (
                        <Box>
                            {field?.type === "group" ?
                                <Flex direction="column" bg={index % 2 ? "brand.300" : "brand.200"} m="0.25rem"
                                    p="1rem" border="1px solid" borderColor={"brand.100"}>
                                    <Text mb="1rem" fontSize={"lg"}>{field?.name.startsWith("not_show") ? "" : field?.name}</Text>
                                    <Flex flexFlow="row wrap" width="100%">
                                        {field?.value.map((val) =>
                                            <Field field={val} onChange={onChange} section={section}
                                                checkRequiredOnCondition={checkRequiredOnCondition}
                                                checkDisabledOnCondition={checkDisabledOnCondition} groupName={field?.name}
                                                formWidth="23%" inputBorder={true} highLabel={highLabel} />
                                        )}
                                    </Flex>
                                </Flex>
                                :
                                <Flex mx="2rem" my="1rem" >
                                    <Field field={field} onChange={onChange} section={section}
                                        checkRequiredOnCondition={checkRequiredOnCondition}
                                        checkDisabledOnCondition={checkDisabledOnCondition} highLabel={highLabel} />
                                </Flex>
                            }
                        </Box>
                    ))}
                </Grid>)
                :
                isGroupedColumns ?
                    (<Grid templateColumns={`repeat(${columnsGrouped * columnsNotGrouped}, minmax(0, 1fr))`} gap={3}>
                        {data?.map((field, index) => (
                            field?.type === "group" ?
                                <GridItem colSpan={columnsNotGrouped} mt="2rem">
                                    <Flex direction="row" height={"40px"}>
                                        <Text mb="1rem">{field?.name.startsWith("not_show") ? "" : field?.name}</Text>
                                        {(field?.isGroupRequired === 1 || field?.isGroupRequired === true) && <Text color="red">{" *"}</Text>}
                                    </Flex>
                                    <Flex flexFlow="column" pt="1rem">
                                        {field?.value.map((val) =>
                                            <Field field={val} onChange={onChange} section={section}
                                                checkRequiredOnCondition={checkRequiredOnCondition}
                                                checkDisabledOnCondition={checkDisabledOnCondition}
                                                groupName={field?.name} showLabel={false} highLabel={highLabel}
                                            />
                                        )}
                                    </Flex>
                                </GridItem>
                                :
                                <GridItem colSpan={field?.render === "textarea" ? columnsGrouped * columnsNotGrouped :
                                    columnsGrouped} my="0.5rem">
                                    <Field field={field} onChange={onChange} section={section}
                                        checkRequiredOnCondition={checkRequiredOnCondition}
                                        checkDisabledOnCondition={checkDisabledOnCondition} highLabel={highLabel} />
                                </GridItem>


                        ))}
                    </Grid>)
                    :
                    <Grid templateColumns='repeat(3, minmax(0, 1fr))' overflow={"initial"} gap={3}>
                        {data?.map((field) => (
                            field?.render === "textarea" ?
                                <GridItem my="0.5rem" colSpan={3}>
                                    <Field field={field} onChange={onChange} section={section}
                                        checkRequiredOnCondition={checkRequiredOnCondition}
                                        checkDisabledOnCondition={checkDisabledOnCondition} highLabel={highLabel} />

                                </GridItem>
                                :
                                <GridItem my="0.5rem" colSpan={1}>
                                    <Field field={field} onChange={onChange} section={section}
                                        checkRequiredOnCondition={checkRequiredOnCondition}
                                        checkDisabledOnCondition={checkDisabledOnCondition} highLabel={highLabel} />

                                </GridItem>
                        )
                        )}
                    </Grid>}
        </Box >
    )
}


export default FormFields;