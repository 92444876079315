
import { Box, Card, CardBody, CardHeader, Divider, Flex, Heading, IconButton, Spacer, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ShowData } from "../../../../structures/types";
import { FiEdit3, FiTrash2, FiX } from "react-icons/fi";
import DBModal from "../DBModal";
import { getOne, updateOne } from "../../../../services/crud";
import { Loader } from "../../../shared/Loader";
import { DetailsData } from ".";
import { CheckDatabasePrivilege } from "../../../../utils/privilegeUtils";
import { editorAction } from "../../../../constants/privileges";
import { DataContext } from "../../../YokohamaTwsApp";



function DetailsActive({ title, onClose, onConfirmAction, updateDetail }) {

    const { sectionName, databaseName, elementId } = useParams();

    const userPrivileges = useContext(DataContext)
    const editPrivileges = CheckDatabasePrivilege(databaseName, sectionName,  editorAction, userPrivileges)

    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<ShowData[]>([])
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const onEdit = (data) => {
        //Object.keys(data).map((key) => data[key].map((el) => { payloadData[el.name] = { value: el.value, type: el.type } }));
        setShowEditModal(false)
        setLoading(true)
        if (databaseName && elementId) {
            updateOne("dashboard/db/edit_row", `?table_name=${databaseName}&row_id=${elementId}`, data).then((resp) => {
                onConfirmAction()
                setLoading(false)

            })
        }
    }

    const onDelete = (deleteData) => {
        onClose()
        if (databaseName && elementId) {
            updateOne("dashboard/db/status_row", `?table_name=${databaseName}&row_id=${elementId}`, deleteData).then((resp) => {
                onConfirmAction()
            })
        }
    }

    function getDetailData(elementId, databaseName) {
        setLoading(true)
        getOne(`dashboard/db/row/${elementId}`, `?table_name=${databaseName}&is_active=${true}`).then((resp) => {
            setData(resp?.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (elementId && databaseName && updateDetail) {
            getDetailData(elementId, databaseName)
        }
    }, [updateDetail])

    useEffect(() => {
        if (elementId && databaseName) {
            getDetailData(elementId, databaseName)
        }
    }, [elementId, databaseName])

    return (
        <Box w="500px" display="block">
            <Card>
                <CardHeader>
                    <Flex direction="row">
                        <Heading size='md'>{title}</Heading>
                        <Spacer />
                        <IconButton aria-label="close-sidedata" as={FiX} onClick={onClose} variant="iconButtonSecondary"
                            size='sm' />
                    </Flex>
                </CardHeader>
                <Divider />
                <CardBody>
                    <Loader isLoading={loading}>
                        {editPrivileges &&
                            <Flex direction="row" justifyContent="end" alignItems={"end"}>
                                <IconButton aria-label="edit" as={FiEdit3} onClick={() => setShowEditModal(true)}
                                    variant={"iconButtonPrimary"} size="sm" />
                                <Text ml="0.5rem" fontSize={"lg"}>Edit</Text>
                            </Flex>}
                        <DetailsData data={data} />
                    </ Loader>
                    {/* <Delete title={`Deleting ${title}`} text="Delete" onDelete={onDelete} /> */}
                    {editPrivileges &&
                        <Flex direction="row" alignItems="end" justifyContent={"end"} m="1rem">
                            <IconButton aria-label="close-sidedata" as={FiTrash2} onClick={() => { setShowDeleteModal(true) }} variant="iconButtonPrimary"
                                size="sm" />
                            <Text ml="0.5rem" fontSize={"lg"}>Delete</Text>
                        </Flex>}
                    {showDeleteModal && <DBModal title={`Deleting ${title}`} isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} action="delete" onAct={onDelete} />}

                </CardBody>
            </Card>
            {showEditModal && <DBModal title={`Edit ${title}`} isOpen={showEditModal} onClose={() => setShowEditModal(false)} action="edit"
                onAct={onEdit} />}
        </Box>
    )
}

export default DetailsActive;

