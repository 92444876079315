import React from "react";
import { Text, Flex, Spacer, Icon } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react'
import { FiPlusCircle } from "react-icons/fi";
import { SettingsItem } from "../../../structures/types";

interface CustomTableProps {
  columns: Array<string>;
  items: Array<SettingsItem>;
  selectedItem: SettingsItem;
  onRowSelect: (el:SettingsItem) => void;
  addButton?: boolean;
  addAction?: () => void;
}

export default function CustomTable({ columns, items, selectedItem, onRowSelect, addButton=false, addAction=null }: CustomTableProps) {

  return (
    <TableContainer>
      <Table variant='unstyled' >
        <Thead borderBottom="1px solid" borderColor="brand.100" bgColor="white">
          <Tr gridColumnGap={"40px"} textAlign="center">
            {columns.map((column) => <Th
              key=""
              //alignContent={"space-between"}
              lineHeight="center"
              textAlign="center"
            >
              <Flex>
                <Text fontSize="lg">{column}</Text>
                {addButton && (
                  <>
                    <Spacer />
                    <Icon
                      as={FiPlusCircle}
                      aria-label={`prova`}
                      height="auto"
                      color="brand.100"
                      onClick={addAction}
                      width="1.5rem"
                      mx="1rem"
                      cursor="pointer"
                      _hover={
                        { color: 'brand.300' }
                      }
                    />
                    <Text fontSize="md" >Add new</Text>
                  </>
                )}
              </Flex>
            </Th>)}
          </Tr>
        </Thead>
        <Tbody textColor={"black"} alignContent={"center"}>
          {items && items?.map((el, index) => {
            return (

              <Tr bg={el?.name === selectedItem?.name ? "brand.300" : index % 2 === 1 ? "brand.200" : "white"} cursor="pointer"
                onClick={(e) => { onRowSelect(el) }}>
                <Td textAlign="center">{el?.label}</Td>
                {el?.description &&
                  <Td textAlign="left" >{el?.description}</Td>}
              </Tr>
            )
          })
          }
        </Tbody>
      </Table>
    </TableContainer>
  )
}