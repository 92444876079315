import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { Outlet, useOutletContext } from "react-router-dom";
import { deleteOne, getAll, overwriteOne } from "../../services/crud.ts";
import CustomTable from "./shared/Table.tsx";
import AddModal from "./shared/AddModal.tsx";
import { Loader } from "../shared/Loader.tsx";
import { SettingsItem } from "../../structures/types.tsx";
import { ErrorMessage } from "../shared/ErrorMessage.tsx";

type ContextType = { users: Array<SettingsItem> | null, privileges: Array<SettingsItem> | null };

function Groups() {

  const navigate = useNavigate()

  const [groupName, setGroupName] = useState("")

  const mockedUsers = [{ name: "s.mantuano", label: "s.mantuano" },
  { name: "g.mandarano", label: "g.mandarano" },
  { name: "utente.example", label: "utente.example" }]
  const mockedPrivileges = [
    { name: "add_dependencies", label: "Add dependencies" },
    { name: "edit_dependencies", label: "Edit dependencies" },
    { name: "delete_dependencies", label: "Delete dependencies" },
    { name: "perm_delete_dependencies", label: "Permanent delete dependencies" }

  ]

  const [loading, setLoading] = useState<boolean>(false)
  const [navigateGroup, setNavigateGroup] = useState<boolean>(false)

  const [users, setUsers] = useState<Array<SettingsItem>>(mockedUsers)
  const [privileges, setPrivileges] = useState<Array<SettingsItem> | null>(mockedPrivileges)
  const [groups, setGroups] = useState<Array<SettingsItem> | null>(null)

  const [group, setGroup] = useState<SettingsItem | null>(groups ? groups[0] : null)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onCloseModal = () => { onClose(); setGroupName(""); setDisabledSubmit(true) }


  const onSaveGroupName = (e) => {
    setLoading(true)
    overwriteOne('groups/group', `?group_name=${groupName}`).then((resp) => {
      if (resp?.status === 200 || resp?.status === "200" || resp?.data) {
        getAll("/groups/groups_list").then((r) => {
          setGroups(r?.data);
          setGroup(r?.data[0])
          setLoading(false)
        })
      }
      else {
        setLoading(false)
        setErrorMessage(resp?.response?.data?.detail || resp?.message)
      };

    })
    //setGroups([...groups, {label:groupName, name: formatName(groupName)}])
  }
  const onChange = (name) => {setGroupName(name); if (name === "") { setDisabledSubmit(true)} else {setDisabledSubmit(false)}}

  function getGroups() {
    setLoading(true)
    getAll("/groups/groups_list").then((resp) => {
      setGroups(resp?.data);
      setGroup(resp?.data[0])
      navigate(resp?.data[0]?.name)
      setLoading(false);
      setNavigateGroup(true)
    }
    )
  }

  function onDeleteGroup(groupName) {
    setNavigateGroup(false)
    deleteOne("groups/group", `?group_name=${groupName}`).then((resp) => {
      getGroups()
    })
  }

 
  useEffect(() => {
    if (group) { navigate(group?.name) }
  }, [group, navigate])

  useEffect(() => {
    setLoading(true)
    getAll("/groups/groups_list").then((resp) => {
      if (resp?.status === 200 || resp?.data) {
        setGroups(resp?.data);
        setGroup(resp?.data[0])
        setLoading(false)
        setNavigateGroup(true)
      }
      else {
        setLoading(false)
        setErrorMessage(resp?.message)
      }
    }
    )
    getAll("/groups/users").then((resp) =>
      setUsers(resp?.data)
    )
    getAll("/groups/privileges").then((resp) =>
      setPrivileges(resp?.data)
    )


  }, [])

  return (
    <Loader isLoading={loading}>

      <Box h="700px">
        {/* <Skeleton startColor='rgba(240, 240, 240)' endColor='brand.300' height='full' isLoaded={!loading}> */}

        {errorMessage && ErrorMessage(errorMessage)}
        {groups &&
          <Grid h="full" m="2rem" templateRows={['repeat(2, 1fr)', 'repeat(1, 1fr)']} templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={4} >
            <GridItem rowSpan={2} colSpan={1} bgColor={"brand.200"} border="1px solid" borderColor="brand.100">
              <CustomTable columns={["Group names"]} items={groups} selectedItem={group} onRowSelect={(el) => setGroup(el)} addButton={true} addAction={onOpen} />
              <AddModal title="Add new group" item="group" isOpen={isOpen} onClose={onCloseModal} onChange={onChange} onSave={onSaveGroupName} 
                        disableSubmit={disabledSubmit} />
            </GridItem>
            <GridItem colSpan={2} bg='brand.200' >
              {navigateGroup && <Outlet context={[users, privileges, onDeleteGroup, setErrorMessage]} />}
            </GridItem>
          </Grid>
        }
        {/* </Skeleton> */}
      </Box>
    </Loader>


  )
}

export function useGrupContext() {
  return useOutletContext<ContextType>();

}

export default Groups;