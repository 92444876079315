
import React, { useEffect, useState } from "react";
import Sidebar from "./database/Sidebar";
import View from "./database";
import { Outlet } from "react-router";

function Database() {


    return (
        <>
            <Sidebar />
            <Outlet />
        </>
    )
}


export default Database;