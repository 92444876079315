import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PermissionDenied from "./PermissionDenied";


function RequireAuth({allowedRoles, userPrivileges}) {
    console.log(allowedRoles)
    console.log(userPrivileges)

    allowedRoles = [...allowedRoles, "general_admin"]

    return (
        userPrivileges && userPrivileges.find(privilege => allowedRoles.includes(privilege))
        ? <Outlet />
        : <PermissionDenied />
    )
}


export default RequireAuth;

