
import React, { ReactNode } from 'react';
import { Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';

export function Loader({
    isLoading,
    children,
    message,
    opacity="0.75"
}: {
    isLoading?: boolean;
    children: ReactNode;
    message?: string;
    opacity?: string;
}) {
    return (
        <div style={{ position: 'relative' }}>
            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: `rgba(255, 255, 255, ${opacity})`,
                        position: 'absolute',
                        zIndex: 999,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                >
                    <div
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'fixed',
                            top: '50%',
                            textAlign: 'center',
                        }}
                    >
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.100" size="xl" />
                        <Text fontWeight={'bold'}>{message}</Text>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
}
