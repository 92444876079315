
export const formula =
{
    average: (fields) => { return (fields.reduce((pv, cv) => pv + cv, 0)) / fields.length },
    percentage: ([value, total]) => { return value === "0" || total === "0" ? 0 : (value / total) * 100 },
    formula1: ([weight, spg_average]) => {
        return weight === "0" || spg_average === "0" ? 0 :
            parseFloat(weight) / (parseFloat(spg_average) * (10 ** -3))
    },
    formula2: (weights) => { return weights.reduce((pv, cv) => parseFloat(pv) + parseFloat(cv), 0) },
    formula3: (volumes) => {
        return volumes.reduce((pv, cv) => {
            return cv.weight === "0" || cv.spg_average === "0" ? pv + 0 :
                pv + (parseFloat(cv.weight) / (parseFloat(cv.spg_average) * (10 ** -3)))
        }, 0)
    },
    formula4: (costs) => {
        return costs.reduce((pv, cv) => {
            return cv.weight === "0" || cv.cost === "0" ? pv + 0 :
                pv + cv.weight * cv.cost
        }, 0)
    },
    formula5: (componentsWeights) => { return componentsWeights.reduce((pv, cv) => parseFloat(pv) + parseFloat(cv), 0) },
    formula6: (totalWeights) => { return totalWeights.reduce((pv, pc) => parseFloat(pv) + parseFloat(pc)) }

}


const calcGeometryString = (geometryArgs) => {
    return `${geometryArgs.totalHeight}-${geometryArgs.baseHeight}/${geometryArgs.cushionHeight}/${geometryArgs.treadHeight}`
}
const calcCompoundString = (compoundArgs) => {
    return `${compoundArgs.baseCode}/${compoundArgs.cushionHeight > 0 ? compoundArgs.cushionCode : "NoCus."}/${compoundArgs.
        treadCode}/${compoundArgs.bondingCode ? compoundArgs.bondingCode : "NoBon."}`
}

export const concatenation = {
    complete_size_name: ({size, tread, loc, spec, ...props}) => {
        const locValue = props?.loc || loc
        return `${props?.size_name || size} ${props?.tread_profile_name || tread} ${ 
            locValue === "true" ? "LOC" : "" } ${props?.spec_name || spec}` },
    identification_product_string: ({itemCode, otherCode, size, tread, loc, spec, ...props}) => {
        const itemCodeValue = props?.itemCode || itemCode
        const otherCodeValue = props?.otherCode || otherCode
        const locValue = props?.loc || loc

        return `${itemCodeValue ? itemCodeValue : "No item"}${otherCodeValue && `-${otherCodeValue}`} : ${props?.
            size_name || size} ${props?.tread_profile_name || tread} ${locValue === "true" ? "LOC" : ""} ${props.spec_name || spec}`
    },
    construction_identification_string: (isDefaultItem, isReal, productIdString, psl, geometryArgs, compoundArgs, ...props) => {


        return `${(isDefaultItem && isReal) ? "D.I." : (!isDefaultItem && isReal) ? "R.P." : (!isDefaultItem && !isReal) ?
            "V.P.": ""} | ${productIdString} | ${psl ? "PSL" : "NoPSL"} | ${calcGeometryString(geometryArgs)} | ${calcCompoundString(compoundArgs)}`
    }
}


export const conditioningValues = ["is_existing_item", "is_in_production", "cost_kg", "currency", "tire_item_type", "construction_type"]

const requiredOnCondition = {

    ProductionPlant: { "isInProduction": true },

    Currency: { "Cost/kg": !null || !"" },

    ItemCode: { "is_existing_item": true },

    OtherCode: { "isExistingItem": false }

}



