
import React from "react";
import { Card, CardHeader, CardBody, StackDivider, Stack, Box, Text, Stat, StatLabel, StatNumber, StatGroup, Spinner, Flex } from '@chakra-ui/react'
import { Usage } from "../../../structures/types";

interface SettingsCardProps {
  title: string;
  entities: Array<Usage>;
}

function SettingsCard({ title, entities }: SettingsCardProps) {


  return (
    <Card m="1rem">
      <CardHeader>
        <Text fontSize='lg'>{title}</Text>
      </CardHeader>
      <CardBody >
        <Stack divider={<StackDivider />} spacing='8'>
          <Box>
            <StatGroup>
              {entities?.map((entity, index) =>
              (<Stat mx="2rem" my="2rem">
                <StatNumber>{entity.count}</StatNumber>
                <StatLabel fontSize={"lg"}>{entity.label}</StatLabel>
              </Stat>)
              )}
            </StatGroup>
          </Box>
        </Stack>
      </CardBody>
    </Card>)
}


export default SettingsCard;