
import React, { useState } from "react";
import { Card, CardBody, CardHeader, Flex, Grid, GridItem, Text, Icon } from "@chakra-ui/react";
import ProductSelection from "./ProductSelection";
import CustomConstruction from "./CustomConstrucion";
import Summary from "./Summary";
import { FiRefreshCcw } from "react-icons/fi";
import { getWithPayload, saveOne } from "../../../../services/crud";
import { groupFlatFormData } from "../../../../utils/dataStructureUtils";

function TireSetup() {

    const [constructionData, setConstructionData] = useState<Object>(null);
    const [constructionIdField, setConstructionIdField] = useState<Object>(null);

    const [databaseData, setDatabaseData] = useState<Object>(null);
    const [summaryData, setSummaryData] = useState<Object>(null)
    const [bondingCode, setBondingCode] = useState<Object>(null)
    const [includeBonding, setIncludeBonding] = useState<boolean>(false)
    const [isConstructionId, setIsConstructionId] = useState<boolean>(false)
    const [sectionHeight, setSectionHeight] = useState<number>(0)

    const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true)
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
    const [constructionIdSummary, setConstructionIdSummary] = useState("")

    const SummaryFieldsToSave = [
        "BaseSectionHeight_mm", "CushionSectionHeight_mm", "TreadSectionHeight_mm", "BaseVolume_cm3", "CushionVolume_cm3",
        "TreadVolume_cm3", "BondingVolume_cm3", "TotalCompoundVolume_cm3", "WeightCompoundBase_kg", "WeightCompoundCushion_kg",
        "WeightCompoundTread_kg", "WeightCompoundBonding_kg", "TotalCompoundWeight_kg", "TotalCompoundCost_USD", "OuterDiameter_mm",
        "Width_mm", "AvgWidthBase_mm", "AvgWidthCushion_mm", "AvgWidthTread_mm", "ConstructionNote"
    ]

    const CustomConstructionFieldsToSave = [
        "base_compound_code_select", "cushion_compound_code_select", "tread_compound_code_select", "SectionHeight_mm",
        "Base_percent", "Cushion_percent", "Tread_percent"
    ]


    const onRefreshSummary = () => {
        if (!isUpdateDisabled && isConstructionId) {
            let payload = {}
            if (includeBonding && bondingCode) {
                payload = { ...constructionData, ...constructionIdField, ...bondingCode }
            }
            else {
                payload = { ...constructionData, ...constructionIdField }
            }
            getWithPayload("dashboard/ai_test/tire_setup/summary", {
                ...payload
            }).then((resp) => {
                if (resp?.data) {
                    setConstructionIdSummary(resp?.data?.ConstructionIdentificationString)
                    resp?.data?.values?.map((el) => { if (!el?.group) { el["group"] = "not_show"; el["groupRender"] = "subSection" } })
                    const data = groupFlatFormData(resp?.data?.values)
                    setSummaryData(data)

                    const payloadData = { ...databaseData }
                    payloadData['ConstructionIdentificationString'] = {
                        value: resp?.data?.ConstructionIdentificationString,
                        type: "string"
                    }
                    resp?.data.values.map((el) => {
                        if (SummaryFieldsToSave.includes(el.name)) {
                            payloadData[el.name] = {
                                value: el.value,
                                type: el.type
                            }
                        }
                    })
                    setDatabaseData(payloadData)
                }

            })
        }
    }

    const onChangeProductId = (productId) => {
        const payloadData = { ...databaseData }
        payloadData['product_identification_string'] = {
            value: productId,
            type: "string"
        }
        setDatabaseData(payloadData)

    }

    const onChangeConstruction = (constructionId, bonding = null, constructionSH = null) => {

        setIsConstructionId(Boolean(constructionId))

        if (bonding) {
            const tempBonding = {
                value: bonding,
                type: "string"
            }

            setBondingCode({ bonding_compound_code_select: tempBonding })
        }
        else {
            setBondingCode(null)
        }

        if (constructionSH != null) {
            setSectionHeight(Number(constructionSH))
        }

        const tempConstruction = {
            value: constructionId,
            type: "string"
        }

        setConstructionIdField({ construction_id: tempConstruction })

    }

    const onChangeConstructionData = (data, isDisabled) => {
        setIsUpdateDisabled(isDisabled)
        setIsSaveDisabled(isDisabled)

        if (Object.keys(data).includes("include_bonding")) {
            if (data.include_bonding.value === true) {
                setIncludeBonding(true)

            }
            else if (data.include_bonding.value === false) {
                setIncludeBonding(false)
            }
        }
        setConstructionData(data)

        const payloadData = { ...databaseData }
        Object.entries(data).map(([key, el]) => {
            if (CustomConstructionFieldsToSave.includes(key)) {
                payloadData[key] = el
            }
        })
        setDatabaseData(payloadData)
    }

    const onDatabaseSave = (comments) => {
        databaseData["ConstructionNote"] = {
            value: comments,
            type: 'string'
        }
        saveOne("dashboard/ai_test/tire_setup/save_cc", "", databaseData)
    }



    return (

        <Grid templateColumns={"repeat(14, 1fr)"} templateRows={"repeat(1, 1fr)"} gap={"0.5rem"} h="full">
            <GridItem h="2xl" colSpan={5}>
                <Card border="1px solid" borderColor="brand.200" h="inherit">
                    <CardHeader textAlign={"center"} fontSize={"22px"} mb="-2rem" >Product selection</CardHeader>
                    <CardBody>
                        <ProductSelection onChangeConstruction={onChangeConstruction} onChangeProductId={onChangeProductId} />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem h="2xl" colSpan={5}>
                <Card border="1px solid" borderColor="brand.200" h="inherit">
                    <CardHeader textAlign={"center"} fontSize={"22px"} mb="-2rem">Custom construction</CardHeader>
                    <CardBody>
                        <CustomConstruction onChangeConstructionData={onChangeConstructionData} isBondingDisabled={!bondingCode}
                            sectionHeight={sectionHeight} />
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem h="2xl" colSpan={4}>
                <Card bg="brand.200" border="1px solid" borderColor="brand.300" h="inherit">
                    <CardHeader textAlign={"center"} mb="-2rem">
                        <Flex direction="column">
                            <Flex justifyContent={"right"} alignItems={"center"} gap="0.5rem">
                                {/* <Text>Refresh</Text> */}
                                <Icon as={FiRefreshCcw} width="2rem" h="30px" color={(isUpdateDisabled || !isConstructionId) ? "brand.300" : "brand.100"}
                                    onClick={onRefreshSummary}
                                    cursor={(isUpdateDisabled || !isConstructionId) ? "" : "pointer"} />

                                {/*                                 <IconButton aria-label="" icon={<FiRefreshCcw/>} variant="iconButtonPrimary" onClick={onRefreshSummary} cursor="pointer" />
 */}                            </Flex>
                            <Flex fontSize={"22px"} justifyContent={"center"} >
                                <Text>Summary</Text>
                            </Flex>
                        </Flex >
                    </CardHeader>
                    <CardBody>
                        <Summary constructionId={constructionIdSummary} data={summaryData} isSaveDisabled={isSaveDisabled}
                            onSave={onDatabaseSave} />
                    </CardBody>
                </Card>
            </GridItem>
        </Grid>
    )

}


export default TireSetup;