import React, { useEffect, useState } from "react";
import { Box, Fade, Flex, Grid, GridItem, Icon, IconButton, Popover, PopoverTrigger, Spacer, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { deleteOne, getAll, getOne, overwriteOne, updateOne } from "../../services/crud.ts";
import CustomTable from "./shared/Table.tsx";
import AddModal from "./shared/AddModal.tsx";
import { FiEdit3, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import SettingsCard from "./shared/SettingsCard.tsx";
import { formatLabel } from "../../utils/formatUtils.ts";
import DeletePopover from "./shared/DeletePopover.tsx";
import { Loader } from "../shared/Loader.tsx";
import { SettingsItem, Usage } from "../../structures/types.tsx";
import { ErrorMessage } from "../shared/ErrorMessage.tsx";
import { useNavigate } from "react-router";
import Alert from "../shared/Alert.tsx";


function DeleteReasons() {

  const navigate = useNavigate()

  const [reasons, setReasons] = useState<Array<SettingsItem>>([])
  /* [{name: "no_more_useful", 
      label: "No more useful",
      description: "This reason is used for data no longer useful"},
      {name: "wrong_records", 
      label: "Wrong records",
      description: "This reason is used for entities with wrong data"}
  ] */
  const [usages, setUsages] = useState<Array<Usage>>([
    { label: "Brands", count: 20 },
    { label: "Compounds", count: 3 },
    { label: "Construction", count: 60 },
    { label: "Patterns", count: 5 },
    { label: "Plants", count: 2 }
  ])
  const [reason, setReason] = useState<SettingsItem>(reasons ? reasons[0] : null)
  const [reasonToAdd, setReasonToAdd] = useState<SettingsItem | null>({ name: "", label: "", description: "" })
  const [reasonToEdit, setReasonToEdit] = useState<SettingsItem | null>({ name: "", label: "", description: "" })

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingReason, setLoadingReason] = useState<boolean>(false)

  const [disableAddSubmit, setDisableAddSubmit] = useState<boolean>(true)
  const [disableEditSubmit, setDisableEditSubmit] = useState<boolean>(true)


  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure()
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
  const onCloseAddModal = () => { onAddClose(); setReasonToAdd({ name: "", label: "", description: "" }); setDisableAddSubmit(true) }
  const onCloseEditModal = () => { onEditClose(); setReasonToEdit({ name: "", label: "", description: "" }); setDisableEditSubmit(true) }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const onClose = () => { setIsOpen(false) }
  const onOpen = () => { setIsOpen(true) }

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [isDeleteDisabled, setIsDeleteDisable] = useState<boolean>(false)

  const initialFocusRef = React.useRef()

  function onDeleteReason() {
    setLoading(true)
    deleteOne("reasons/delete", `?reason_name=${reason?.name}`).then((resp) => {
      if (resp?.status === 200 || resp?.status === "200" || resp?.data) {
        getAll("reasons/all").then((r) => {
          if (resp?.status === 200 || resp?.data)
         {setReasons(r?.data)
          setReason(r?.data[0])
          setLoading(false)}
          else {
            setLoading(false)
            setErrorMessage(resp?.response?.data || resp?.message)
          }

        })
      }
      else {
        setErrorMessage(resp?.response?.data || resp?.message)
        setLoading(false)
      }
    })
  }

  const onConfirm = () => { onDeleteReason(); setIsOpen(false) }

  const onChange = (label) => {
    let tempReason = { ...reasonToAdd }; tempReason["label"] = label; tempReason["name"] = formatLabel(label);
    setReasonToAdd(tempReason); if (tempReason.description === "" || tempReason.name === "") { setDisableAddSubmit(true) } else { setDisableAddSubmit(false) }
  }
  const onChangeDescription = (description) => {
    let tempReason = { ...reasonToAdd }; tempReason["description"] = description; setReasonToAdd(tempReason);
    if (tempReason.description === "" || tempReason.name === "") { setDisableAddSubmit(true) } else { setDisableAddSubmit(false) }
  }

  function onSaveReason() {
    setLoading(true)
    overwriteOne("reasons/save", "", { ...reasonToAdd }).then((resp) => {
      getAll("reasons/all").then((r) => {
        setReasons(r?.data)
        setReason(r?.data[0])
      })
      setReasonToAdd({ name: "", label: "", description: "" })
      setDisableAddSubmit(true)
      setLoading(false)
    })

  }

  const onEdit = (label) => {
    let tempReason = { ...reasonToEdit }; tempReason["label"] = label; tempReason["name"] = formatLabel(label);
    setReasonToEdit(tempReason); if (tempReason.description === "" || tempReason.name === "") { setDisableEditSubmit(true) }
    else { setDisableEditSubmit(false) }
  }
  const onEditDescription = (description) => {
    let tempReason = { ...reasonToEdit }; tempReason["description"] = description; setReasonToEdit(tempReason);
    if (tempReason.description === "" || tempReason.name === "") { setDisableEditSubmit(true) } else { setDisableEditSubmit(false) }
  }

  function onSaveEdit() {
    setLoading(true)
    updateOne("reasons/update", `?reason_name=${reason?.name}`, { ...reasonToEdit }).then((resp) => {
      getAll("reasons/all").then((r) => {
        setReasons(r?.data)
        setReason(r?.data[0])
      })
      setReasonToEdit({ name: "", label: "", description: "" })
      setDisableEditSubmit(true)
      setLoading(false)
    })
  }

  function handleRowSelect(element) {
    setReason(element);
    setErrorMessage(null)

  }


  useEffect(() => {
    setLoading(true)
    getAll("reasons/all").then((resp) => {
      if (resp?.status === 200 || resp?.data)
      {setReasons(resp?.data)
      setReason(resp?.data[0])
      setLoading(false)}
      else{
        setLoading(false)
        setErrorMessage(resp?.response?.data || resp?.message)
      }
    })
  }, [])

  useEffect(() => {
    if (reason) {
      setLoadingReason(true)
      navigate(`reasons/${reason?.name}`)
      getOne("reasons/usages", reason?.name).then((resp) => {
        setUsages(resp?.data?.entities)
        setIsDeleteDisable(!resp?.data?.deletable)
        setLoadingReason(false)
      })
    }
  }, [reason])


  return (
    <Loader isLoading={loading}>
      <Box h="700px">
        {errorMessage && ErrorMessage(errorMessage)}
        <Grid h="full" m="2rem" templateRows={['repeat(2, 1fr)', 'repeat(1, 1fr)']} templateColumns={['repeat(1, 1fr)', 'repeat(5, 1fr)']} gap={4} >
          <GridItem rowSpan={2} colSpan={3} bgColor={"brand.200"} border="1px solid" borderColor="brand.100">
            <CustomTable columns={["Delete reason", "Description"]} items={reasons} selectedItem={reason} onRowSelect={(el) => handleRowSelect(el)} />
          </GridItem>
          <GridItem colSpan={2} bg='brand.200' >
            <Flex direction={"row"} m="1rem">
              <Spacer />
              <Icon
                as={FiPlusCircle}
                aria-label={`prova`}
                height="auto"
                color="brand.100"
                onClick={onAddOpen}
                width="1.5rem"
                mx="1rem"
                cursor="pointer"
                _hover={
                  { color: 'brand.300' }
                }
              />
              <Text fontSize="xl" >Add new</Text>
              <AddModal title="Add new reason" item="reason" isOpen={isAddOpen} onClose={onCloseAddModal} onChange={onChange} onSave={onSaveReason}
                disableSubmit={disableAddSubmit} description onChangeDescription={onChangeDescription} />
            </Flex>
            {reason &&
              <>
                {loadingReason ?
                  <Flex justifyContent={"center"} alignSelf={"center"} pt="5rem">
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.100" size="lg" />
                  </Flex> :
                  <>
                    <SettingsCard title={"Deleted entities that are using this label:"} entities={usages} />
                    <Flex direction="row" justifyContent={"end"} alignContent={"end"} mx="1rem">
                      <IconButton aria-label="edit-reason" icon={<FiEdit3 />} size='lg' ml="1rem" cursor="pointer"
                        bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={onEditOpen}
                      />
                      <Text alignSelf="center" fontSize="lg" >Edit</Text>
                      <IconButton aria-label="delete-reason" icon={<FiTrash2 />} size='lg' ml="1rem" cursor="pointer"
                            bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen}
                            isDisabled={isDeleteDisabled} />
                      <Alert message={`You are deleting reason ${reason?.label}`} isOpen={isOpen} onClose={onClose} onConfirm={onConfirm} 
                      />
                      {/* <Popover
                        initialFocusRef={initialFocusRef}
                        placement={'bottom'}
                        closeOnBlur={false}
                        isOpen={isOpen}
                        size={"lg"}
                      >
                        <PopoverTrigger>
                          <IconButton aria-label="delete-reason" icon={<FiTrash2 />} size='lg' ml="1rem" cursor="pointer"
                            bgColor={"brand.200"} _hover={{ bgColor: 'brand.300' }} onClick={isOpen ? onClose : onOpen}
                            isDisabled={isDeleteDisabled} />

                        </PopoverTrigger>
                        <DeletePopover item="reason" label={reason?.label} onClose={onClose} onConfirm={onConfirm} />

                      </Popover> */}
                      

                      <Text alignSelf="center" fontSize="lg">Delete</Text>
                    </Flex>
                  </>}
              </>}
          </GridItem>

          <AddModal title="Edit reason" item="reason" isOpen={isEditOpen} onClose={onCloseEditModal} onChange={onEdit} onSave={onSaveEdit} disableSubmit={disableEditSubmit} description
            onChangeDescription={onEditDescription} warningMessage={"Be careful, performing this operation will change all records with this label. Proceed only if strictly necessary."} />


        </Grid>
      </Box>
    </Loader>
  )
}


export default DeleteReasons;