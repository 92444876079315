
import React, { createRef, useEffect, useState } from "react";
import { Card, CardBody, Grid, GridItem, useDisclosure, CardFooter, Button, Box, Popover, PopoverTrigger, Text, PopoverBody, PopoverContent } from "@chakra-ui/react";
import { Slot } from "../../../../structures/types";
import Slots from "../Slots";
import Alert from "../../../shared/Alert";
import SaveModal from "../SaveModal";
import ShowModal from "../ShowModal";
import Test from "./Test";
import { getWithPayload, saveOne } from "../../../../services/crud";
import { convertPredictDataForCsv, convertPredictDataForSave, convertPredictDataForXlsx } from "../../../../utils/dataStructureUtils";
import ExportModal from "../ExportModal";
import { downloadFileCsv, downloadFileXlsx } from "../../../../utils/exportUtils";
import { graphFileNamesHbu } from "../../../../constants/export";
import { Loader } from "../../../shared/Loader";



function HeatBuildUp() {

    const [savedTests, setSavedTests] = useState(null)
    const { isOpen: isOpenReset, onOpen: onOpenReset, onClose: onCloseReset } = useDisclosure();
    const { isOpen: isOpenShow, onOpen: onOpenShow, onClose: onCloseShow } = useDisclosure();

    const [isOpenSave, setIsOpenSave] = useState<boolean>(false)
    const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false)

    const [slotsReset, setSlotsReset] = useState<boolean>(false)
    const [testReset, setTestReset] = useState<boolean>(false)

    const [swlField, setSwlField] = useState<Object>(null)

    const [predictResults, setPredictResults] = useState(null)
    const [graphData, setGraphData] = useState()

    const [slotsIds, setSlotsIds] = useState<string[]>([])
    const [slotsFields, setSlotsFields] = useState<Slot[]>([])

    const [slotConstructionsFather, setSlotConstructionsFather] = useState<Slot[]>(null)
    const [lastSlotsRun, setLastSlotsRun] = useState<Slot[]>([])

    const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false)

    const [slotsDeleted, setSlotsDeleted] = useState<boolean>(false)

    const [swlValue, setSwlValue] = useState<string>("")
    const [testNote, setTestNote] = useState<string>("")
    const [testPurpose, setTestPurpose] = useState<string>("")

    const [loading, setLoading] = useState<boolean>(false)

    const graphRef1 = createRef();
    const graphRefs = [graphRef1]

    const handleSetSwl = (value) => {
        setSwlValue(value)
    }

    const handleSetSlots = (slots) => {
        //if (slots.every((slot) => { return !slot?.id })) { setIsRunDisabled(true) }
        let slotsFieldsTemp = []
        let slotsIdsTemp = []
        if (slots.some(slot => { return !slot?.deleted })) {
            setSlotsDeleted(false)
            slots.map((slot) => {
                if (slot?.id && !slot?.deleted) { slotsIdsTemp.push(slot.id.toString()); slotsFieldsTemp.push(slot) }
            })
        }
        setSlotsIds(slotsIdsTemp)
        setSlotsFields(slotsFieldsTemp)

    }

    const handleSetTestNote = (value) => {
        setTestNote(value)
    }

    const handleSetTestPurpose = (value) => {
        setTestPurpose(value)
    }

    function onRunAlgorithms() {
        setLoading(true)
        const payload = {
            "slot_ids": slotsIds,
            "SteeringWheelLoad_kg": swlValue,
        }
        getWithPayload("/dashboard/ai_test/predict", payload, "?test_type=hbu").then((resp) => {
            if (resp?.data){
            setPredictResults(resp?.data)
            setGraphData(resp?.data?.graphs)
            setSlotsDeleted(true)
            setSlotsReset(true)
            setLoading(false)}
        })
        //setPredictResults(resultsStructureStaticValorizedTemp)
        //setGraphData(graphDataTemp)
        setLastSlotsRun(slotConstructionsFather)
    }

    const onReset = () => {
        setTestReset(true)
        setSlotsReset(true)
        onCloseReset()
        console.log("reset")
    }

    useEffect(() => {
        if (testReset) {
            setTestReset(false)
        }
    }, [testReset])

    useEffect(() => {
        if (slotsReset) {
            setSlotsReset(false)
        }
    }, [slotsReset])

    const onSave = () => {
        setIsOpenSave(false)
        setLoading(true)
        const saveData = convertPredictDataForSave(predictResults, slotsFields, { SteeringWheelLoad_kg: swlValue },
            { Hbu_test_note: testNote },
            testPurpose
        )
        saveOne("/dashboard/ai_test/save_test", "?test_type=hbu", saveData).then((resp) => {
            setSavedTests(resp?.data)
            setLoading(false)
            onOpenShow()
        })
        // setSavedTests([{ "construction_id": "construction_1", "test_id": "test_1", "static_test_id": "static_test_1" },
        // { "construction_id": "construction_2", "test_id": "test_2", "static_test_id": "static_test_2" }])
        // onOpenShow()
    }

    function onDownload(fileName, format, includeGraphs = false) {
        if (format === 'xlsx') {
            console.log("download xlsx")
            const imageRefs = includeGraphs ? graphRefs : null
            const exportData = convertPredictDataForXlsx(predictResults, slotsFields, 'Hbu', 'True', { swl_kg: swlValue })
            downloadFileXlsx(exportData, fileName, imageRefs)
        }
        else if (format === 'csv') {
            console.log("download csv")
            const exportData = convertPredictDataForCsv(predictResults, slotsFields, 'Hbu', 'True', { swl_kg: swlValue })
            downloadFileCsv(exportData, fileName)

        }
    }


    useEffect(() => {
        setSwlField({ value: "SWL PROVA", label: "SWL(Kg)", name: "swl_kg" })
    }, [])


    return (
        <Loader isLoading={loading} >
            <Grid templateColumns={"repeat(7, 1fr)"} templateRows={"repeat(1, 1fr)"} gap={"0.5rem"} >
                <GridItem h="full" colSpan={2}>
                    <Card border="1px solid" borderColor="brand.200" h="inherit">
                        <CardBody>
                            <Slots reset={slotsReset} onSetSlots={handleSetSlots} slotsDeleted={slotsDeleted} 
                                    setSlotConstructionsFather={setSlotConstructionsFather} lastSlotsRun={lastSlotsRun} />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem h="2xl" colSpan={5}>
                    <Card border="1px solid" borderColor="brand.200" style={{ boxShadow: '3px 3px 9px 6px #cccccc' }} >
                        <CardBody>
                            <Test onOpenReset={onOpenReset} reset={testReset} onRun={onRunAlgorithms}
                                isRunDisabled={slotsDeleted || slotsFields.every((slot) => { return !slot?.id })} predictResults={predictResults}
                                onSetSwl={handleSetSwl} />
                        </CardBody>
                        <CardFooter justifyContent={"space-between"}>
                            <Box onMouseEnter={() => setPopoverIsOpen(true)} onMouseLeave={() => setPopoverIsOpen(false)}>
                                <Popover isOpen={popoverIsOpen} placement="top-start">
                                    <PopoverTrigger>
                                        <Text fontWeight={'bold'} color={'#9b6b55'} borderColor={'#9b6b55'} textTransform={'uppercase'}
                                                borderWidth={'1px'} p={2} opacity={!lastSlotsRun?.length > 0 ? '0' : '1'}>
                                            Slots last test
                                        </Text>
                                    </PopoverTrigger>
                                    <PopoverContent fontSize={'11px'} w={'60%'}>
                                        <PopoverBody display={'flex'} flexDirection={'column'} gap={3}>
                                            {lastSlotsRun?.length > 0 && lastSlotsRun.map((dict_slots) => {
                                                if(dict_slots.construction) {
                                                    return (
                                                    <>
                                                        <Text fontWeight={'bold'}>{dict_slots.name}</Text> <Text fontSize={'10px'}>{dict_slots.construction}</Text>
                                                    </>)
                                                }
                                            })}
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Box>
                            <Box>
                                <Button variant="buttonSecondary" onClick={() => setIsOpenSave(true)} isDisabled={!predictResults}>SAVE IN DATABASE</Button>
                                <Button variant="buttonSecondary" onClick={() => setIsOpenDownload(true)} isDisabled={!predictResults}>DOWNLOAD DATA</Button>
                            </Box>
                        </CardFooter>
                    </Card>
                </GridItem>
                <Alert message="You are deleting all the information in the page"
                    warning="Information that has not been saved in the database will be lost"
                    isOpen={isOpenReset} onClose={onCloseReset} onConfirm={onReset} />
                {isOpenSave && <SaveModal isOpen={isOpenSave} onClose={() => setIsOpenSave(false)} onSave={onSave}
                    onSetTestNote={handleSetTestNote} onSetTestPurpose={handleSetTestPurpose} />}
                {savedTests && <ShowModal tests={savedTests} type={"Hbu"} isOpen={isOpenShow} onClose={onCloseShow} />}
                {/* {isOpenDownload && <DownloadModal isOpen={isOpenDownload} onClose={() => setIsOpenDownload(false)} onDownload={onDownload} />} */}
                {isOpenDownload &&
                    <ExportModal graphData={graphData} isOpen={isOpenDownload} onClose={() => setIsOpenDownload(false)}
                        onDownload={onDownload} graphStyle={"hbu"} graphRefs={graphRefs} graphFileNames={graphFileNamesHbu} />}
            </Grid>
        </Loader>

    )

}


export default HeatBuildUp;