import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ExtendedAxiosConfig } from './crud';
import { toast } from 'react-toastify';


// RESPONSE (response, instance)
function successHandler(
    response: AxiosResponse & { config: AxiosRequestConfig & ExtendedAxiosConfig },
): AxiosResponse | any {
    const allResponse = response.config.full;

    return response;
}

// (error, instance)

function errorHandler(
    error: AxiosError & { config: AxiosRequestConfig & ExtendedAxiosConfig },
): Promise<{}> | undefined {
    console.log(error)
    const networkTypeError = error?.code === "ERR_NETWORK"
    const errorToNotify =
        (error.response &&
            error?.response?.status >= 400 &&
            error?.response?.status < 600) ||
        networkTypeError

    if (errorToNotify) {
        console.log('Logging the error', error);
        toast.error(`Error while calling the api: ${error?.response?.data?.detail || error?.response?.data || error?.message}`, {
            position: "top-center",
            autoClose: networkTypeError ? 2000 : 4000,
            closeOnClick: true,
            rtl: true,
            pauseOnFocusLoss: true,
            draggable: true,
            theme: "colored",
        })
    }

    function resolved(result) {
        console.log('Resolved');
    }

    function rejected(result) {
        console.error(result);
    }

    return Promise.resolve(error);
    // Expected output: Error: fail


    //return Promise.reject({...error})


    // return Promise.reject({ ...error });
}

function saveResponseHandler(
    response: AxiosResponse & { config: AxiosRequestConfig & ExtendedAxiosConfig },
): AxiosResponse | any {

    console.log('Logging the save response', response);
    toast.success(`Successfully saved. ${response?.data}`, {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        rtl: true,
        pauseOnFocusLoss: true,
        draggable: true,
        theme: "colored",
    })

    return response;
}



export { successHandler, saveResponseHandler, errorHandler };
