import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, Flex, FormLabel, FormControl, Select, Checkbox, Text, Input, Icon, Divider, Tooltip } from "@chakra-ui/react";
import { FormData } from "../../../../structures/types";
import { getAll, getOne } from "../../../../services/crud";
import { formatName } from "../../../../utils/formatUtils";
import { FiAlertTriangle, FiTriangle } from "react-icons/fi";
import { groupFlatFormData } from "../../../../utils/dataStructureUtils";
import { sectionHeightFieldName } from "../../../../constants/fieldNames";

function CustomConstruction({ onChangeConstructionData, sectionHeight, isBondingDisabled = true }) {
    const [compounds, setCompounds] = useState<FormData[] | null>(null)
    const [compound, setCompound] = useState<string>("")
    const [compoundFields, setCompoundFields] = useState<FormData[]>([])
    const [constructionFields, setConstructionFields] = useState<FormData[]>([])
    const [editedConstructionFields, setEditedConstructionFields] = useState<{}>({})
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [wrongPercentage, setWrongPercentage] = useState<boolean>(false)

    const checkPercentage = (data) => {
        const basePercentField = data.filter((el) => { return el?.name === "Base_percent" })[0]
        const cushionPercentField = data.filter((el) => { return el?.name === "Cushion_percent" })[0]
        const treadPercentField = data.filter((el) => { return el?.name === "Tread_percent" })[0]

        return parseFloat(basePercentField?.value) + parseFloat(cushionPercentField?.value) + parseFloat(treadPercentField?.value) !== 100
    }


    const onChange = (fieldName, value, type) => {
        let changedData = [...constructionFields]
        let index = constructionFields.findIndex((el) => { return el?.name === fieldName })
        let field = { ...constructionFields[index] }
        field["value"] = value === 'true' ? true : value === 'false' ? false : value
        if (field?.inner) {
            field.inner.delta = (Number(field.value) - sectionHeight).toString()
        }
        changedData[index] = field
        const missingValues = Object.values(changedData).flat().some((el) => { return el?.value === "" || el?.value === null })
        const wrongPercentageValue = checkPercentage(changedData)
        setWrongPercentage(wrongPercentageValue)
        setIsDisabled(missingValues || wrongPercentageValue)
        setConstructionFields(changedData)

        let tempEditedData = { ...editedConstructionFields }
        tempEditedData[fieldName] = {
            value: value === "" ? null : value === 'true' ? true : value === 'false' ? false : value,
            type: type
        }
        setEditedConstructionFields(tempEditedData)

    }

    useEffect(() => {
        if (sectionHeight !== null && constructionFields.length > 0) {
            let changedData = [...constructionFields]
            let index = constructionFields.findIndex((el) => { return el?.name === sectionHeightFieldName })
            let field = { ...constructionFields[index] }
            field.inner.delta = (Number(field.value) - sectionHeight).toString()
            changedData[index] = field
            setConstructionFields(changedData)
        }
    }, [sectionHeight])


    useEffect(() => {
        if (isBondingDisabled && constructionFields && constructionFields.length > 0) {
            onChange("include_bonding", false, "bool")

        }
    }, [isBondingDisabled])


    useEffect(() => {
        if (editedConstructionFields) {
            onChangeConstructionData(editedConstructionFields, isDisabled)
        }
    }, [editedConstructionFields])

    useEffect(() => {
        /* call api for compound fields with param compound */
        if (compound) {
            getOne("dashboard/ai_test/tire_setup/detail", `?table_name=Compounds&value=${compound}`).then((resp) => {
                console.log(resp?.data)
                resp?.data?.map((el) => { if (!el?.group) { el["group"] = "not_show"; el["groupRender"] = "subSection" } })
                setCompoundFields(groupFlatFormData(resp?.data))
            })
        }
    }, [compound])

    useEffect(() => {
        getAll("dashboard/ai_test/tire_setup/custom_construction").then((resp) => {
            setConstructionFields(resp?.data)
            const tempCompounds = []
            resp?.data.map((el) => {
                if (el.name.includes("compound_code")) {
                    el.options.map((compound) => {
                        tempCompounds.push(compound)
                    })
                }

            })
            setCompounds(tempCompounds)

        })

    }, [])

    return (
        <Box mx="0.5rem" >
            <Flex direction={"column"} gap="1rem">

                {constructionFields && constructionFields.length > 0 &&
                    <Flex direction={"column"} >
                        <Flex justifyContent={"end"} pr="3rem" mb="0.5rem" h="30px">
                            {wrongPercentage &&
                                <Tooltip label="The sum of the percentages must be 100" bg="red.500"
                                    placement="left" shouldWrapChildren
                                >
                                    <Icon as={FiAlertTriangle} color="red" width="30px" h="30px" />
                                </Tooltip>
                            }
                        </Flex>
                        <Flex direction="row" flexFlow={"row wrap"} gap="0.5rem" mr="-1rem">
                            {constructionFields.map((field, index) => (
                                <Flex direction="column" width={index === 0 ? "36%" : index < 4 ? "17%" : field.label.length > 14 ? "33%" : "28%"}>
                                    <FormControl
                                        onChange={(e) => onChange(field.name, field.type === "bool" ? e.target?.checked : e.target?.value, field.type)}
                                        isDisabled={field.name === "include_bonding" && isBondingDisabled}
                                    >

                                        <label>{index < 4 ? field.label.replace(" ", "") : formatName(field.label)}</label>
                                        {field.render === "checkbox" ?
                                            <Checkbox ml="0.5rem" mt="0.25rem" />
                                            :
                                            field.options?.length > 0 ?
                                                <Select placeholder="Select a compound code"
                                                    bg="brand.300">
                                                    {field?.options?.map((option) => (
                                                        <option value={option}>{option}</option>)
                                                    )}
                                                </Select>
                                                :
                                                <Input justify={"right"} border="1px solid" borderColor="brand.300"
                                                    bg="brand.200" borderRadius={"5px"} h="30px" value={field?.value} />
                                        }
                                        {field?.inner &&
                                            <Flex direction="row" width="100%" my="0.5rem" alignItems={"center"} justifyContent={"right"}>
                                                <Icon as={FiTriangle} borderRadius={0} mx="1rem" />
                                                <Flex justify={"right"} width="50%" border="1px solid" borderColor="brand.300"
                                                    borderRadius={"5px"} h="30px" bg="white">
                                                    <Text mx="0.5rem" >{field?.inner?.delta}</Text>
                                                </Flex>
                                            </Flex>}
                                    </FormControl>
                                </Flex>
                                /* <Field field={field} onChange={onChange} section={null}
                                    checkRequiredOnCondition={null}
                                    checkDisabledOnCondition={null} formWidth="auto"/> */
                            ))}
                        </Flex>
                    </Flex>
                }
                <Card bg="brand.200" border="1px solid" borderColor={"brand.300"}>
                    <CardBody>
                        <Text textAlign={"center"} fontSize={"18px"}>View compound properties</Text>

                        {compounds?.length > 0 &&
                            <Box>
                                <FormControl
                                    onChange={(e) => setCompound(e.target?.value)}
                                    width={"100%"}
                                    mx="0.5rem" my="0.5rem">
                                    <FormLabel>Compound</FormLabel>
                                    <Select bg="brand.300"  >
                                        {compounds?.map((el) =>
                                            <option value={el}>{el}</option>)}</Select>
                                </FormControl>
                            </Box>}
                        {compoundFields.length > 0 &&

                            <Flex direction="column" height="44" display="block" overflowY={"scroll"}>
                                {compoundFields.map((section) => (
                                    <>
                                        {!section.name.startsWith("not_show") &&
                                            <Flex direction={"column"} alignItems="center" my="0.5rem" >
                                                <Divider borderColor={"brand.100"} variant={"dashed"} mb="0.5rem" />
                                                <Text fontWeight={"bold"}>{formatName(section.name)}</Text>
                                            </Flex>}

                                        <Flex direction="row" flexFlow={"row wrap"} mt="0.5rem" gap="0.5rem">
                                            {section?.value?.map((field) => {
                                                return (
                                                    <Flex direction="column" width={field.label.length > 15 ? "fit-content" : "30%"}>
                                                        <label>{formatName(field.label)}</label>
                                                        <Flex bg="white" justify={"right"} border="1px solid" borderColor="brand.300"
                                                            borderRadius={"5px"} h="30px" >
                                                            <Text mx="0.5rem" >{field?.value}</Text>
                                                        </Flex>
                                                    </Flex>
                                                )
                                            }

                                            )}
                                        </Flex>
                                    </>
                                ))

                                }
                            </Flex >
                        }
                    </CardBody>
                </Card>
            </Flex>
        </Box >
    )
}


export default CustomConstruction;