import { Box, Button, Flex, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Spacer, Text }
    from "@chakra-ui/react";
import React, { useState } from "react";
import { ShowData } from "../../../structures/types";
import { FiX } from "react-icons/fi";
import { DBModalProps } from "../../../structures/interfaces";
import { DeleteForm, UpdateForm } from "./DBForms";
import { calculateFormula, getOne } from "../../../services/crud";
import { destructureFlatGroupedFormData } from "../../../utils/dataStructureUtils";
import { Loader } from "../../shared/Loader";


function DBModal({ title, isOpen, onClose, action, onAct }: DBModalProps) {

    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [data, setData] = useState<ShowData[] | { [key: string]: ShowData[] }>({})
    const [calculationData, setCalculationData] = useState<ShowData[] | { [key: string]: ShowData[] }>({})
    const [calculatedData, setCalculatedData] = useState<ShowData[] | { [key: string]: ShowData[] }>({})
    const [toCalculate, setToCalculate] = useState<boolean>(false)
    const [toGetCostUsd, setToGetCostUsd] = useState<boolean>(false)
    const [costUsd, setCostUsd] = useState<number>()
    const [currency, setCurrency] = useState<string>(null)
    const [costKg, setCostKg] = useState(null)

    const [loading, setLoading] = useState<boolean>(false)

    const checkDisabled = (disabled) => {
        setIsDisabled(disabled)
    }

    const onChangeData = (editedData) => {
        setData(editedData)
    }

    const handleSetCalculationData = (calcData) => {
        setCalculationData(calcData)

    }

    const calculateData = () => {
        setLoading(true)
        calculateFormula("/calculate", destructureFlatGroupedFormData(Object.values(calculationData).flat())
        ).then((resp) => {
            setLoading(false)
            setCalculatedData(resp?.data)
        })

    }

    const handleSetToCalculate = (toCalc) => {
        setToCalculate(toCalc)
    }

    const handleSetToGetCostUsd = (toGetCost) => {
        setToGetCostUsd(toGetCost)
    }
    const handleSetCurrencyData = (currencyValue, costKgValue) => {
        setCurrency(currencyValue)
        setCostKg(costKgValue)

    }

    const calculateCostUsd = () => {
        setLoading(true)
        getOne("/currency", `?from_currency=${currency}&to_currency=USD&amount=${costKg}`).then((resp) => {
            setLoading(false)
            setCostUsd(resp?.data)
        })
    }


    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose} size="6xl" >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex direction="row">
                            <Text>{title}</Text>
                            <Spacer />
                            <IconButton aria-label="close-modal" mr={3} onClick={onClose} as={FiX} variant={"iconButtonSecondary"}
                                size="sm" />
                        </Flex>
                    </ModalHeader>
                    {<Loader isLoading={loading}>
                        <ModalBody >
                            {action === "delete" ? <DeleteForm onChangeData={onChangeData} checkDisabled={checkDisabled} /> :
                                <UpdateForm action={action} onChangeData={onChangeData} checkDisabled={checkDisabled}
                                    onSetCalculationData={handleSetCalculationData} calculatedData={calculatedData}
                                    onSetToCalculate={handleSetToCalculate} onSetCurrencyData={handleSetCurrencyData}
                                    costUsd={costUsd} onSetToGetCostUsd={handleSetToGetCostUsd}
                                />}
                        </ModalBody>
                    </Loader>}
                    <ModalFooter>
                        {/* {toGetCostUsd &&
                            <Button variant='buttonSecondary' onClick={(e) => onAct(data)} isDisabled={isDisabled}>
                                Get currency
                            </Button>} */}
                        {toGetCostUsd &&
                            <Button variant='buttonSecondary' onClick={(e) => calculateCostUsd()} isDisabled={!currency || !costKg}>
                                Calculate currency
                            </Button>}
                        {toCalculate &&
                            <Button variant='buttonSecondary' onClick={(e) => calculateData()}>
                                Calculate formula
                            </Button>}
                        <Button variant='buttonPrimary' onClick={(e) => onAct(data)} isDisabled={isDisabled}>
                            {action === "delete" ? "Confirm" : "Save"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default DBModal;