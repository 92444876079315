
import React, { useState } from "react";
import { Button, Input, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Flex, FormControl } from "@chakra-ui/react";


function SaveModal({ isOpen, onClose, onSave, onSetTestNote, onSetTestPurpose }) {

    const [purpose, setPurpose] = useState();
    const [notes, setNotes] = useState();

    return (        
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={"2xl"} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader mt="1rem">Do you want to save these results in the database "Static"?</ModalHeader>
                <ModalCloseButton />
                <ModalBody my="1rem">
                    <Flex direction="column" gap={"0.5rem"}>
                        <FormControl
                            onChange={(e) => {setPurpose(e.target.value); onSetTestPurpose(e.target.value)}} >
                            <Flex direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <label>Test purpose:</label>
                                <Input value={purpose} width="480px" />
                            </Flex>
                        </FormControl>
                        <FormControl
                            onChange={(e) => {setNotes(e.target.value); onSetTestNote(e.target.value)}}>
                            <Flex direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <label>Test notes:</label>
                                <Input value={notes} width="480px"/>
                            </Flex>
                        </FormControl>
                    </Flex>
                </ModalBody>
                <ModalFooter mt="1rem">
                <Button variant='buttonSecondary' onClick={onClose} >Cancel</Button>
                    <Button variant="buttonPrimary" mr={3} onClick={onSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}


export default SaveModal