import React from "react";
import { Divider, Flex, Image, Text } from "@chakra-ui/react";
import logo from '../assets/images/Yokohama-TWS_Logo_positive.svg';
import { useNavigate } from "react-router";

function LogoSection() {

    const navigate = useNavigate()

    return (
        <Flex marginX={["1rem", "2rem", "3rem"]}>
            <Image src={logo} w={["150px","200px"]} marginX={["1rem", "2rem", "3rem"]} cursor="pointer" onClick={(e)=> navigate("/dashboard/database")}/>
            <Divider borderColor="brand.100" h={["fit-content","fit-content","auto"]} orientation='vertical' />
            <Text fontSize={["md", "lg", "xl"]} marginX={["1rem", "1rem", "3rem"]} alignSelf={"end"} lineHeight={"1"}>Solid Tires Tests & AI App</Text>
        </Flex>
    )
}

export default LogoSection;