import React, { useEffect, useState } from "react";
import { Box, Flex, FormLabel, FormControl, Select, Text, ButtonGroup, Button, Icon, Tooltip, Popover, PopoverTrigger, PopoverContent, PopoverBody } from "@chakra-ui/react";
import { deleteOne, getAll, getOne, saveOne } from "../../../services/crud";
import { formatName } from "../../../utils/formatUtils";
import { FiTrash2 } from "react-icons/fi";
import { slotsConstructionTemp } from "../../../constants/temp";
import { Slot } from "../../../structures/types";


function Slots({ onSetSlots, reset = false, slotsDeleted = false, setSlotConstructionsFather, lastSlotsRun }) {
    const [treads, setTreads] = useState<string[]>([])
    const [products, setProducts] = useState<string[]>([])
    const [constructions, setConstructions] = useState<string[]>([])
    const [tread, setTread] = useState<string>("")
    const [product, setProduct] = useState<string>("")
    const [construction, setConstruction] = useState<string>(null)
    const [slotConstructions, setSlotConstructions] = useState<Slot[]>(null)

    const slots = ["slot_1", "slot_2", "slot_3", "slot_4"]

    const onSaveSlot = (slotName) => {
        const payload = {
            "ConstructionIdentificationString": construction,
            "slot_name": slotName
        }
        saveOne("/dashboard/ai_test/tire_setup/save_slot", "", payload).then((resp) => {
            if (resp?.status === 200 && resp?.data) {
                const tempSlots = [...slotConstructions]
                const index = slotConstructions.findIndex((slot) => { return slot.name === slotName })
                tempSlots[index] = {
                    id: resp?.data, name: slotName, construction: construction, tread: tread, product: product,
                    deleted: false
                }
                setSlotConstructions(tempSlots)
                setSlotConstructionsFather(tempSlots)

                //let slotsIdsTemp = []
                //tempSlots.map((slot) => { if(slot?.id) {slotsIdsTemp.push(slot.id.toString())}})
                onSetSlots(tempSlots)
            }

        })
    }

    const onDeleteSlots = (slotName = null) => {
        if (slotName) {
            const tempSlots = [...slotConstructions]
            const index = slotConstructions.findIndex((slot) => { return slot.name === slotName })
            deleteOne("/dashboard/ai_test/tire_setup/delete_slot", tempSlots[index].id.toString()).then((resp) => {
                if (resp?.status === 200 && resp?.data) {
                    tempSlots[index] = { id: "", name: slotName, construction: "", tread: "", product: "", deleted: false }
                    setSlotConstructions(tempSlots)
                    setSlotConstructionsFather(tempSlots)
                    onSetSlots(tempSlots)
                }

            })
        }
        else {
            slotConstructions.map((slot) => {
                if (slot.id) {
                    deleteOne("/dashboard/ai_test/tire_setup/delete_slot", slot.id.toString()).then((resp) => {
                        if (resp?.status === 200 && resp?.data) {
                            setSlotConstructions(slotsConstructionTemp)
                            setSlotConstructionsFather(slotsConstructionTemp)
                            onSetSlots(slotsConstructionTemp)
                        }

                    })
                }
            })
        }
    }

    const onReset = () => {
        // setTreads([])
        // setProducts([])
        // setConstructions([])
        // setTread("")
        // setProduct("")
        // setConstruction("")
        getTreadTypes()
        if (slotsDeleted) {
            const slots = [...slotConstructions]
            slots.map((slot) => slot.deleted = true)
            setSlotConstructions(slots)
            setSlotConstructionsFather(slots)
        }
        else {
            setSlotConstructions(slotsConstructionTemp)
            setSlotConstructionsFather(slotsConstructionTemp)
            onSetSlots(slotsConstructionTemp)
        }
    }

    useEffect(() => {
        if (reset) {
            onReset()
        }
    }, [reset])

    useEffect(() => {
        if (product) {/*call products api with param tread */
            getOne("dashboard/ai_test/tire_setup/constructions", `?product_is=${product}`).then((resp) => {
                //setConstructions(resp?.data)
                setConstructions(resp?.data?.values)
            })
        }

    }, [product])

    useEffect(() => {
        setProducts([])
        setConstructions([])
        if (tread) {/*call products api with param tread */
            getOne("dashboard/ai_test/tire_setup/products", `?tread_type=${tread}`).then((resp) => {
                setProducts(resp?.data)
            })
        }

    }, [tread])

    const getTreadTypes = () => {
        getAll("dashboard/ai_test/tire_setup/tread_types").then((resp) => {
            setTreads(resp?.data)
        })
    }

    useEffect(() => {
        getTreadTypes()
        setSlotConstructions(slotsConstructionTemp)        

    }, [])

    useEffect(() => {
        setSlotConstructions([
        {id:'', name:'slot_1', construction: '', product: '', tread: "", deleted: false},
        {id:'', name:'slot_2', construction: '', product: '', tread: "", deleted: false},
        {id:'', name:'slot_3', construction: '', product: '', tread: "", deleted: false},
        {id:'', name:'slot_4', construction: '', product: '', tread: "", deleted: false}])

    }, [lastSlotsRun])

    return (
        <Box>
            <Flex direction={"column"}>
                <Box>
                    <FormControl
                        onChange={(e) => setTread(e.target?.value)}
                        width={"100%"}
                        mx="0.5rem">
                        <FormLabel>Tread type</FormLabel>
                        <Select placeholder="Select tread type">{treads && treads.length > 0 &&
                            treads.map((el) =>
                                <option value={el}>{el}</option>)}</Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl
                        onChange={(e) => setProduct(e.target?.value)}
                        width={"100%"}
                        mx="0.5rem" my="0.5rem">
                        <FormLabel>Product</FormLabel>
                        <Select placeholder="Select product">{products && products.length > 0 &&
                            products.map((el) =>
                                <option value={el}>{el}</option>)}</Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl
                        onChange={(e) => setConstruction(e.target?.value)}
                        width={"100%"}
                        mx="0.5rem" my="0.5rem"
                    >
                        <FormLabel>Construction</FormLabel>
                        <Select placeholder="Select construction">
                            {constructions && constructions?.length > 0 &&
                                constructions?.map((el) =>
                                    <option value={el} >{el}</option>)}</Select>
                    </FormControl>
                </Box>
                {/* inizio codice pronto per integrare il memo degli slot utilizzati nell'ultima run (manca collegare il tasto reload) */}
                {/* <Popover>
                    <PopoverTrigger>
                        <Button isDisabled={!lastSlotsRun?.length > 0} size={'xs'} h={'30px'} my={2}>Show last test</Button>
                    </PopoverTrigger>
                    <PopoverContent fontSize={'11px'} w={'60%'} m={'auto'}>
                        <PopoverBody display={'flex'} flexDirection={'column'} gap={3}>
                            {lastSlotsRun?.length > 0 && lastSlotsRun.map((dict_slots) => <><Text fontWeight={'bold'}>{dict_slots.name}</Text> <Text fontSize={'10px'}>{dict_slots.construction}</Text></>)}
                            <Text>
                                Click here to reload the slots of the last test performed
                            </Text>
                            <Button variant={'buttonPrimary'} size={'xs'} fontSize={'10px'}>
                                Reload
                            </Button>
                        </PopoverBody>
                    </PopoverContent>
                </Popover> */}
                {/* fine codice pronto per futura richiesta cliente */}
                <ButtonGroup my="1rem">
                    {slots.map((slotName) =>
                        <Button
                            onClick={() => onSaveSlot(slotName)} style={{ "white-space": "normal", "word-wrap": "break-word" }}
                            isDisabled={!construction}
                            variant="buttonSecondary" h="60px" fontSize={'15px'}>
                            {`Save ${formatName(slotName)}`}
                        </Button>)}
                </ButtonGroup>
                <Flex direction={"column"} mt="1rem" mr="-1rem">
                    {slotConstructions && slotConstructions.length > 0 &&
                        slotConstructions.map((slot, index) =>
                            <Flex key={`slot-${index}`} dir="row" my="0.5rem" alignItems={"end"} justifyContent={"space-between"}>
                                <Flex direction="column" width="full">
                                    <label>{`${formatName(slot?.name)}:`}</label>
                                    <Box width={[200, 300, 425]} bg="brand.200" border="1px solid" borderColor={"brand.300"}
                                        h="30px">
                                        {!slot?.deleted &&
                                            <Tooltip label={slot?.construction}>
                                                <Text isTruncated >
                                                    {slot?.construction}
                                                </Text>
                                            </Tooltip>}
                                    </Box>
                                </Flex>
                                <Icon aria-label={`delete-slot-${index}`} as={FiTrash2} color="brand.100"
                                    width="30px" height="30px" border="0" cursor="pointer" mx="0.5rem"
                                    onClick={() => onDeleteSlots(slot?.name)} />
                            </Flex>
                        )}
                </Flex>

            </Flex>
        </Box >
    )
}

export default Slots;