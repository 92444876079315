
import React from "react";
import {
    Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Flex,
    Text, Box, Tooltip
} from "@chakra-ui/react";


function ShowModal({ tests, type, isOpen, onClose }) {

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader mt="1rem">Tests saved</ModalHeader>
                <ModalCloseButton />
                {tests &&
                    <ModalBody my="1rem">
                        <Flex direction="column" gap={"0.5rem"}>
                            <Flex direction="row" gap="1rem">
                                <Box width="70%" />
                                <Box width="20%"><Text>Test id</Text></Box>
                                <Box width="22%"><Text>{type} test id</Text></Box>
                            </Flex>
                            {tests.map((test) => (
                                <Flex direction="row" gap="1rem" >
                                    <Tooltip label={test.ConstructionId}>
                                        <Text bg="brand.200" width="70%" isTruncated>{test.ConstructionId}</Text>
                                    </Tooltip>
                                    <Text bg="brand.300" width="20%" textAlign={"end"}>{test.TestId}</Text>
                                    <Text bg="brand.300" width="22%" textAlign={"end"}>{type === "Static" ? test.StaticTestId :
                                        test.HeatBuildUpTestId}</Text>
                                </Flex>)
                            )}
                        </Flex>
                    </ModalBody>}
                <ModalFooter mt="1rem">
                    <Button variant='buttonPrimary' onClick={onClose} >Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}


export default ShowModal;