import React, { useContext, useEffect, useState } from "react";
import { Box, Card, CardBody, CardHeader, Divider, Flex, Heading, IconButton, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { FiRefreshCcw, FiTrash2, FiX } from "react-icons/fi";
import { ShowData } from "../../../../structures/types";
import {useNavigate, useParams} from "react-router";
import { deleteOne, getOne, updateOne } from "../../../../services/crud";
import Alert from "../../../shared/Alert";
import { DetailsData } from ".";
import { DataContext } from "../../../YokohamaTwsApp";
import { CheckDatabasePrivilege } from "../../../../utils/privilegeUtils";
import { adminAction } from "../../../../constants/privileges";


function DetailsDeleted({ title, onClose, onConfirmAction }) {

    const { sectionName, databaseName, elementId } = useParams()
    const navigate = useNavigate()
    const userPrivileges = useContext(DataContext)
    const editPrivileges = CheckDatabasePrivilege(databaseName, sectionName, adminAction, userPrivileges)

    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<ShowData[]>([
        // { label: "N° Constructions", name: "n_constructions", value: 100, group: "construction" },
        //{ label: "N constructions", name: "n_constructions", value: 100, },
        //{ label: "Motive", name: "motive", value: "It is a non reasonable element to stay in this table", },

    ])

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { isOpen: isOpenRestore, onOpen: onOpenRestore, onClose: onCloseRestore } = useDisclosure();


    const onRestore = () => {
        setLoading(true)
        onClose()
        if (databaseName && elementId) {
            updateOne("dashboard/db/status_row", `?table_name=${databaseName}&row_id=${elementId}`, { deleted: false }).then((resp) => {
                onConfirmAction()
                setLoading(false)
                navigate("")
            })
        }
    }

    const onPermanentDelete = () => {
        setLoading(true)
        onClose()
        deleteOne("dashboard/db/delete_row", `?table_name=${databaseName}&row_id=${elementId}`).then((resp) => {
            onConfirmAction()
            setLoading(false)
            navigate("")
        })
    }

    useEffect(() => {
        if (elementId && databaseName) {
            setLoading(true)
            getOne(`dashboard/db/row/${elementId.toString()}`, `?table_name=${databaseName}&is_active=${false}`).then((resp) => {
                setData(resp?.data)
                setLoading(false)
            })
        }
    }, [elementId, databaseName])


    return (
        <Box w="500px" display="block">
            <Card>
                <CardHeader>
                    <Flex direction="row">
                        <Heading size='md'>{title}</Heading>
                        <Spacer />
                        <IconButton aria-label="close-sidedata" as={FiX} onClick={onClose} variant="iconButtonSecondary"
                            size='sm' />
                    </Flex>
                </CardHeader>
                <Divider />
                <CardBody>
                    {editPrivileges &&
                        <Flex direction="row" alignItems={"end"} my="2rem" mx="1.5rem">
                            <IconButton aria-label="edit" as={FiRefreshCcw} onClick={onOpenRestore}
                                variant={"iconButtonPrimary"} size="sm" />
                            <Text ml="0.5rem" fontSize={"lg"}>Restore</Text>
                        </Flex>}
                    <DetailsData data={data} />
                    {editPrivileges &&
                        <Flex direction="row" alignItems="end" justifyContent={"end"} m="1rem">
                            <IconButton aria-label="close-sidedata" as={FiTrash2} onClick={onOpenDelete} variant="iconButtonPrimary"
                                size="sm" />
                            <Text ml="0.5rem" fontSize={"lg"}>Permanent delete</Text>
                        </Flex>}
                </CardBody>
            </Card>
            {isOpenRestore && <Alert message={`Your are restoring ${title}`} isOpen={isOpenRestore} onClose={onCloseRestore} onConfirm={onRestore} />}
            {isOpenDelete && <Alert message={`You are permanently deleting ${title}`} isOpen={isOpenDelete} onClose={onCloseDelete} onConfirm={onPermanentDelete} />}

        </Box>
    )
}

export default DetailsDeleted;
