import React, { useContext, useEffect, useState } from "react";
import { Button, Divider, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { formatName } from "../utils/formatUtils";
import { CheckComponentsPrivilege } from "../utils/privilegeUtils";
import { DataContext } from "./YokohamaTwsApp";


function Dashboard() {
    const navigate = useNavigate()
    const userPrivileges = useContext(DataContext)

    const selections = ["database", "AITests", "help"].filter((el) => {
        return userPrivileges ? CheckComponentsPrivilege(formatName(el), userPrivileges) : []
    })
    const [selection, setSelection] = useState<string>(selections[0])


    useEffect(() => {
        navigate(`/dashboard/${selection}`)
    }, [selection])


    return (
        <Flex >
            <Flex bgColor="brand.200" height={"4rem"} border="1px solid"
                borderColor="brand.300" alignItems="center" justifyContent="end" width="full">
                {selections && selections.length > 0 && selections.map((el) => {
                    return (
                        <>
                            <Button variant={selection === el ? "buttonPrimary" : "buttonSecondary"}
                                border={selection === el ? "1px solid" : "0px"}
                                color={selection === el ? "white" : "black"}
                                mx="2rem"
                                onClick={(e) => setSelection(el)} fontSize="xl"
                                isDisabled={el==="help"}>{formatName(el)}</Button>
                            <Divider borderColor="brand.100" h="75%" orientation='vertical' />
                        </>)
                })
                }

            </Flex>
        </Flex>)
}

export default Dashboard;