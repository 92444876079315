import React, { useState, useEffect } from "react";
import logo from '../../assets/images/Yokohama-TWS_Logo_positive.svg';
import { Box, Container, Flex, Grid, IconButton, Image, Text } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { handleLogout } from "../../services/login";
import { useMsal } from "@azure/msal-react";


function UnauthorizedPage() {

    const { instance, accounts } = useMsal();


    return (
        <Flex justifyContent={"center"} alignItems={"center"} h="inherit" >
            <Flex>
                <Container id="form_container" mb="5rem" >
                    <Grid>
                        <Box boxShadow="base" py="2rem" px="1rem">
                            <Flex
                                alignItems="center"
                                flexDirection="column"
                                justifyContent={"center"}
                            >
                                <Image src={logo} w={["200px", "250px"]} marginX={["1rem", "2rem", "3rem"]} />
                                <Text my="1rem" fontSize={["md", "lg", "xl"]} >Solid Tires Tests & AI App</Text>
                            </Flex>
                            <Flex
                                pt="3rem"
                                alignItems="center"
                                justifyContent="start"
                                flexDirection="column"
                                my="2rem"
                            >
                                <Text fontSize={"xl"}>You are not authorized to use the application </Text>
                                <Flex direction="column" alignItems={"center"} mx={["1rem", "1.5rem", "2rem"]} mt="3rem">
                                    <IconButton variant="buttonSecondary" aria-label='Logout'
                                        icon={<FiLogOut />} onClick={() => handleLogout(instance)} />
                                    <Text>Logout</Text>
                                </Flex>
                            </Flex>
                        </Box>
                    </Grid>
                </Container>
            </Flex>
        </Flex>
    )
}


export default UnauthorizedPage;