
import { loginRequest } from "../services/authConfig";

export const handleLogin = (instance) => {

    instance.loginRedirect(loginRequest).catch((e) => {
    });
  }

export const handleLogout = (instance) => {

    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    });

};


