import React from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals.js';
import { customTheme } from './assets/theme.ts';
import App from './App.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, options } from './services/authConfig';
import { LoginPage } from './components/login/LoginPage.tsx';



export const msalInstance = new PublicClientApplication({...msalConfig, ...options});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <MsalProvider instance={msalInstance} >
      <ChakraProvider theme={customTheme}>
        <Box as="section" width="full" height="100vh" >
          <AuthenticatedTemplate>
            <Box height="100%">
              <App />
              <ToastContainer
                position="top-left"
                autoClose={30000}
                newestOnTop={false}
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                theme="colored"
                style={{ width: "lg" }}
              />
            </Box>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
        </Box>
      </ChakraProvider>
    </MsalProvider>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
