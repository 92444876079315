
import React, { useState } from "react";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useParams } from "react-router";
import { formatName } from "../../../utils/formatUtils";
import DBPanel from "./DBPanel";

function DBView() {

    const { databaseName } = useParams()
    const databaseTypes = ["active", "deleted"]
    const [update, setUpdate] = useState<boolean>(false)

    const activateUpdate = () => {
        setUpdate(true)
    }

    const deactivateUpdate = () => {
        setUpdate(false)

    }

    return (
        <Box
            pos="fixed"
            left="80"
            h="auto"
            pb="10"
            w="auto"
            p="1rem"
        >
            {databaseName && (
                <>
                    <Box pt="0.5rem" >
                        <Text fontSize={"2xl"} mb="1rem">{databaseName}</Text>
                    </Box>
                    <Box>
                        <Tabs colorScheme="gray">
                            <TabList>
                                {databaseTypes.map((type) => (
                                    <Tab fontSize={"2xl"} > {formatName(type)}</Tab>
                                ))}
                            </TabList>
                            <TabPanels>
                                {
                                    databaseTypes.map((type) => (
                                        <TabPanel>
                                            <DBPanel type={type} activateUpdate={activateUpdate}
                                                deactivateUpdate={deactivateUpdate} update={update} />
                                        </TabPanel>
                                    ))
                                }
                            </TabPanels>
                        </Tabs>
                    </Box>
                </>)}
        </Box>
    )
}




export default DBView;