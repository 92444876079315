
import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, Flex, FormLabel, FormControl, Grid, GridItem, Text, Input, Button, CardFooter, useDisclosure } from "@chakra-ui/react";
import { formatName } from "../../../../utils/formatUtils";
import { resultsStructureStaticTemp } from "../../../../constants/temp";
import { Loader } from "../../../shared/Loader";

function Test({ onOpenReset, reset, onRun, isRunDisabled, predictResults, onSetLwl
    // graphData, graphRefs,
}) {

    const [lwlField, setLwlField] = useState<Object>(null)
    const [results, setResults] = useState({})
    const [resultsPercentages, setResultsPercentages] = useState([])

    const [loading, setLoading] = useState<boolean>(false)

    const onChangeLwl = (value) => {
        let tempField = { ...lwlField }
        tempField["value"] = value
        setLwlField(tempField)
        onSetLwl(value)
    }

    const onReset = () => {
        setLwlField({ value: "", label: "LWL(Kg)", name: "lwl_kg" })
        setResultsPercentages(resultsStructureStaticTemp.percentage)
        const newResults = { ...resultsStructureStaticTemp }
        delete newResults.percentage
        setResults(newResults)
    }

    useEffect(() => {
        if (reset) {
            onReset()
        }
    }, [reset])

    useEffect(() => {
        if (predictResults) {
            setResultsPercentages(predictResults.percentage)
            const newResults = { ...predictResults }
            delete newResults.percentage
            delete newResults.graphs
            setResults(newResults)
            setLoading(false)
        }
    }, [predictResults])


    useEffect(() => {
        setLwlField({ value: "", label: "LWL(Kg)", name: "lwl_kg" })
        setResultsPercentages(resultsStructureStaticTemp.percentage)
        const newResults = { ...resultsStructureStaticTemp }
        delete newResults.percentage
        setResults(newResults)
    }, [])





    return (

        <Box>
            <Flex direction={"row"}>
                {lwlField &&
                    <FormControl
                        onChange={(e) => onChangeLwl(e.target?.value)}
                        width={"100%"}
                        mx="0.5rem"
                    >
                        <Flex direction={"row"} alignItems={"end"}>
                            <FormLabel>{lwlField.label}</FormLabel>
                            <Input borderColor="brand.300" width="fit-content" value={lwlField?.value} />
                        </Flex>
                    </FormControl>
                }
                <Button variant="buttonSecondary" onClick={onOpenReset}>RESET</Button>
            </Flex>
            <Card bg="brand.200" border="1px solid" borderColor={"brand.300"} mt="1rem" h="500px">
                <CardBody>
                    <Loader isLoading={loading} message={"Executing AI algorithms ... "}>
                        <Grid templateColumns={"repeat(10, 1fr)"}  >
                            <GridItem colSpan={1} >
                                <Flex direction="column" mt="4.1rem" gap="0.5rem">
                                    {resultsPercentages && resultsPercentages.map((percentage) => {
                                        return (
                                            <Flex direction="column" mr="1rem" textAlign={"center"}>
                                                <label >{percentage.percentage}%</label>
                                                <Text border="1px solid" borderColor={"brand.300"} h="30px">
                                                    {percentage.value}
                                                </Text>
                                            </Flex>
                                        )
                                    })}
                                </Flex>
                            </GridItem>
                            {Object.entries(results).map(([section, values]) =>
                                <GridItem colSpan={3} borderLeft="1px solid" borderColor={"brand.300"} px="1rem" >
                                    <Flex justifyContent={"center"}> <Text >{formatName(section)}</Text></Flex>
                                    <Flex direction="row" mt="2rem" gap="0.5rem" width={"full"} >
                                        {values && values.length > 0 &&
                                            values.map((slot) => {
                                                return (
                                                    <Flex direction="column" justifyContent={"center"} textAlign={"center"} width="full">
                                                        <Text as="b" border="1px solid" borderColor={"brand.300"} mb="0.5rem" >
                                                            {slot.slot?.split("_")[1]}
                                                        </Text>
                                                        <Flex direction="column" gap="2rem">
                                                            {slot.values && slot.values.length > 0 &&
                                                                slot.values?.map((value) => (
                                                                    <Text border="1px solid" borderColor={"brand.300"} bg="white" h="30px">
                                                                        {value.value}
                                                                    </Text>
                                                                ))

                                                            }
                                                        </Flex>
                                                    </Flex>
                                                )
                                            })}
                                    </Flex>
                                </GridItem>)}
                            {/* <GridItem colSpan={3} borderLeft="1px solid" borderColor={"brand.300"} px="1rem">
                            <Flex justifyContent={"center"}> <Text >Contact area</Text></Flex>
                            <Flex direction="row" mt="2rem" gap="0.5rem" width={"full"} >
                                {resultsStructure.contact_area.map((slot) => {
                                    return (
                                        <Flex direction="column" justifyContent={"center"} textAlign={"center"} width="full">
                                            <Text as="b" border="1px solid" borderColor={"brand.300"} mb="0.5rem">
                                                {slot.slot.split("_")[1]}
                                            </Text>
                                            <Flex direction="column" gap="2rem">
                                                {slot.values.map((value) => (
                                                    <Text border="1px solid" borderColor={"brand.300"} bg="white">
                                                        {value}
                                                    </Text>
                                                ))

                                                }
                                            </Flex>
                                        </Flex>
                                    )
                                })}
                            </Flex>


                        </GridItem>
                        <GridItem colSpan={3} borderLeft="1px solid" borderColor={"brand.300"} px="1rem">
                            <Flex justifyContent={"center"}><Text>Specific pressure</Text></Flex>
                            <Flex direction="row" mt="2rem" gap="0.5rem" width={"full"} >
                                {resultsStructure.specific_pressure.map((slot) => {
                                    return (
                                        <Flex direction="column" justifyContent={"center"} textAlign={"center"} width="full">
                                            <Text as="b" border="1px solid" borderColor={"brand.300"} mb="0.5rem">
                                                {slot.slot.split("_")[1]}
                                            </Text>
                                            <Flex direction="column" gap="2rem">
                                                {slot.values.map((value) => (
                                                    <Text border="1px solid" borderColor={"brand.300"} bg="white">
                                                        {value}
                                                    </Text>
                                                ))

                                                }
                                            </Flex>
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        </GridItem> */}
                        </Grid>
                    </Loader>
                </CardBody>
                <CardFooter justifyContent={"end"} alignItems={"center"} >
                    {/* <Button variant="buttonPrimary" onClick={onOpenGraphs} >View graphs</Button> */}
                    <Button variant="buttonPrimary" h="60px" isDisabled={!lwlField?.value || isRunDisabled} 
                    onClick={onRun}>RUN</Button>
                </CardFooter>
            </Card>
            {/* <GraphsModal results={graphData} isOpen={isOpenGraphs} onClose={onCloseGraphs} graphRefs={graphRefs} />  */}
        </Box>
    )
}

export default Test;