
export const activeDetailsTempData = [{ label: "N° Constructions", name: "n_constructions", value: 100, group: "construction" },
{ label: "Hardness Min", name: "hardness_min", value: 2, },
{ label: "Hardness Max", name: "spg_min", value: 1.135, },
{ label: "Average", name: "spg_max", value: 1.155, },
{ label: "Spg min", name: "spg_max", value: 1.155, },
{ label: "Spg max", name: "spg_max", value: 1.155, },
{ label: "Average", name: "spg_max", value: 1.155, },
{ label: "Is in production", name: "spg_max", value: 1.155, },
{ label: "Production plant", name: "spg_max", value: 1.155, },
{ label: "Spg max", name: "spg_max", value: 1.155, },
{ label: "Spg max", name: "spg_max", value: 1.155, }]
export const downloadDataTemp =
  [[{
    read_type: "prova",
    product: 'id_product',
    construction: 'id_construction_lunghissimofekrfnwlkefbkbfkabkabflkqbflqblberjnfdweqff',
    test_type: 'static',
    lwl_kg: '60',
    def_25: '12',
    def_50: '11',
    def_75: '13',
    def_100: '13',
    def_125: '13',
    ca_25: '12',
    ca_50: '11',
    ca_75: '13',
    ca_100: '13',
    ca_125: '13',
    sp_25: '12',
    sp_50: '11',
    sp_75: '13',
    sp_100: '13',
    sp_125: '13',
    virtual_test: false
  }],
  [{
    read_type: "prova",
    product: 'id_product',
    construction: 'id_construction_lunghissimofekrfnwlkefbkbfkabkabflkqbflqblberjnfdweqff',
    test_type: 'static',
    lwl_kg: '60',
    def_25: '12',
    def_50: '11',
    def_75: '13',
    def_100: '13',
    def_125: '13',
    ca_25: '12',
    ca_50: '11',
    ca_75: '13',
    ca_100: '13',
    ca_125: '13',
    sp_25: '12',
    sp_50: '11',
    sp_75: '13',
    sp_100: '13',
    sp_125: '13',
    virtual_test: false
  }],
  [{
    read_type: "prova",
    product: 'id_product',
    construction: 'id_construction_lunghissimofekrfnwlkefbkbfkabkabflkqbflqblberjnfdweqff',
    test_type: 'static',
    lwl_kg: '60',
    def_25: '12',
    def_50: '11',
    def_75: '13',
    def_100: '13',
    def_125: '13',
    ca_25: '12',
    ca_50: '11',
    ca_75: '13',
    ca_100: '13',
    ca_125: '13',
    sp_25: '12',
    sp_50: '11',
    sp_75: '13',
    sp_100: '13',
    sp_125: '13',
    virtual_test: false
  }],
  [{
    read_type: "prova",
    product: 'id_product',
    construction: 'id_construction_lunghissimofekrfnwlkefbkbfkabkabflkqbflqblberjnfdweqff',
    test_type: 'static',
    lwl_kg: '60',
    def_25: '12',
    def_50: '11',
    def_75: '13',
    def_100: '13',
    def_125: '13',
    ca_25: '12',
    ca_50: '11',
    ca_75: '13',
    ca_100: '13',
    ca_125: '13',
    sp_25: '12',
    sp_50: '11',
    sp_75: '13',
    sp_100: '13',
    sp_125: '13',
    virtual_test: false
  }]]


export const resultsStructureHbuValorizedTemp = {
  percentages: [{ percentage: "60", value: "1" }, { percentage: "80", value: "2" }, { percentage: "100", value: "3" },
  { percentage: "120", value: "4" }],
  time: [
    { "value": 0 },
    { "value": 60 },
    { "value": 120 },
    { "value": 180 },
    { "value": 240 },
    { "value": 300 },
    { "value": 360 },
    { "value": 420 },
    { "value": 480 },
    { "value": 540 },
    { "value": 600 },
    { "value": 660 },
    { "value": 720 }],
  temperatures: [{
    slot: "slot_1", values: [
      { "time": 0, value: 1 },
      { "time": 60, value: 2 },
      { "time": 120, value: 3 },
      { "time": 180, value: 4 },
      { "time": 240, value: 5 },
      { "time": 300, value: 6 },
      { "time": 360, value: 7 },
      { "time": 420, value: 8 },
      { "time": 480, value: 9 },
      { "time": 540, value: 10 },
      { "time": 600, value: 11 },
      { "time": 660, value: 12 },
      { "time": 720, value: 13 }]
  },
  {
    slot: "slot_2", values: [{ "time": 0, value: 1 },
    { "time": 60, value: 2 },
    { "time": 120, value: 3 },
    { "time": 180, value: 4 },
    { "time": 240, value: 5 },
    { "time": 300, value: 6 },
    { "time": 360, value: 7 },
    { "time": 420, value: 8 },
    { "time": 480, value: 9 },
    { "time": 540, value: 10 },
    { "time": 600, value: 11 },
    { "time": 660, value: 12 },
    { "time": 720, value: 13 }]
  },
  {
    slot: "slot_3", values: [{ "time": 0, value: 1 },
    { "time": 60, value: 2 },
    { "time": 120, value: 3 },
    { "time": 180, value: 4 },
    { "time": 240, value: 5 },
    { "time": 300, value: 6 },
    { "time": 360, value: 7 },
    { "time": 420, value: 8 },
    { "time": 480, value: 9 },
    { "time": 540, value: 10 },
    { "time": 600, value: 11 },
    { "time": 660, value: 12 },
    { "time": 720, value: 13 }]
  }, {
    slot: "slot_4", values: [{ "time": 0, value: 1 },
    { "time": 60, value: 2 },
    { "time": 120, value: 3 },
    { "time": 180, value: 4 },
    { "time": 240, value: 5 },
    { "time": 300, value: 6 },
    { "time": 360, value: 7 },
    { "time": 420, value: 8 },
    { "time": 480, value: 9 },
    { "time": 540, value: 10 },
    { "time": 600, value: 11 },
    { "time": 660, value: 12 },
    { "time": 720, value: 13 }]
  }],
  time_to_180: [{ slot: "slot_1", values: 30 }, { slot: "slot_2", values: 40 },
  { slot: "slot_3", values: 45 }, { slot: "slot_4", values: 35 }],
}

export const resultsStructureHbuTemp = {
  percentage: [{ percentage: "60", value: "" }, { percentage: "80", value: "" }, { percentage: "100", value: "" },
  { percentage: "120", value: "" }],
  time: [
    { "value": 0 },
    { "value": 60 },
    { "value": 120 },
    { "value": 180 },
    { "value": 240 },
    { "value": 300 },
    { "value": 360 },
    { "value": 420 },
    { "value": 480 },
    { "value": 540 },
    { "value": 600 },
    { "value": 660 },
    { "value": 720 }],
  temperatures: [{
    slot: "slot_1", values: [
      { "time": 0, value: "" },
      { "time": 60, value: "" },
      { "time": 120, value: "" },
      { "time": 180, value: "" },
      { "time": 240, value: "" },
      { "time": 300, value: "" },
      { "time": 360, value: "" },
      { "time": 420, value: "" },
      { "time": 480, value: "" },
      { "time": 540, value: "" },
      { "time": 600, value: "" },
      { "time": 660, value: "" },
      { "time": 720, value: "" }]
  },
  {
    slot: "slot_2", values: [{ "time": 0, value: "" },
    { "time": 60, value: "" },
    { "time": 120, value: "" },
    { "time": 180, value: "" },
    { "time": 240, value: "" },
    { "time": 300, value: "" },
    { "time": 360, value: "" },
    { "time": 420, value: "" },
    { "time": 480, value: "" },
    { "time": 540, value: "" },
    { "time": 600, value: "" },
    { "time": 660, value: "" },
    { "time": 720, value: "" }]
  },
  {
    slot: "slot_3", values: [{ "time": 0, value: "" },
    { "time": 60, value: "" },
    { "time": 120, value: "" },
    { "time": 180, value: "" },
    { "time": 240, value: "" },
    { "time": 300, value: "" },
    { "time": 360, value: "" },
    { "time": 420, value: "" },
    { "time": 480, value: "" },
    { "time": 540, value: "" },
    { "time": 600, value: "" },
    { "time": 660, value: "" },
    { "time": 720, value: "" }]
  }, {
    slot: "slot_4", values: [{ "time": 0, value: "" },
    { "time": 60, value: "" },
    { "time": 120, value: "" },
    { "time": 180, value: "" },
    { "time": 240, value: "" },
    { "time": 300, value: "" },
    { "time": 360, value: "" },
    { "time": 420, value: "" },
    { "time": 480, value: "" },
    { "time": 540, value: "" },
    { "time": 600, value: "" },
    { "time": 660, value: "" },
    { "time": 720, value: "" }]
  }],
  time_to_180: [{ slot: "slot_1", values: "" }, { slot: "slot_2", values: "" },
  { slot: "slot_3", values: "" }, { slot: "slot_4", values: "" }],
}

export const slotsConstructionTemp = [
  { id: "", name: "slot_1", construction: "", tread: "", product: "", deleted: false },
  { id: "", name: "slot_2", construction: "", tread: "", product: "", deleted: false },
  { id: "", name: "slot_3", construction: "", tread: "", product: "", deleted: false },
  { id: "", name: "slot_4", construction: "", tread: "", product: "", deleted: false }]

export const resultsStructureStaticTemp = {
  percentage: [{ percentage: "25", value: "" }, { percentage: "50", value: "" }, { percentage: "75", value: "" },
  { percentage: "100", value: "" }, { percentage: "125", value: "" }],
  deflection: [{ slot: "slot_1", values: ["", "", "", "", ""] }, { slot: "slot_2", values: ["", "", "", "", ""] },
  { slot: "slot_3", values: ["", "", "", "", ""] }, { slot: "slot_4", values: ["", "", "", "", ""] }],
  contact_area: [{ slot: "slot_1", values: ["", "", "", "", ""] }, { slot: "slot_2", values: ["", "", "", "", ""] },
  { slot: "slot_3", values: ["", "", "", "", ""] }, { slot: "slot_4", values: ["", "", "", "", ""] }],
  specific_pressure: [{ slot: "slot_1", values: ["", "", "", "", ""] }, { slot: "slot_2", values: ["", "", "", "", ""] },
  { slot: "slot_3", values: ["", "", "", "", ""] }, { slot: "slot_4", values: ["", "", "", "", ""] }]
}


export const resultsStructureStaticValorizedTemp = {
  percentages: [{ percentage: "25", value: "0.25" }, { percentage: "50", value: "0.5" }, { percentage: "75", value: "0.75" },
  { percentage: "100", value: "1" }, { percentage: "125", value: "1.25" }],
  deflection: [{ slot: "slot_1", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_2", values: ["1", "2", "3", "4", "5"] },
  { slot: "slot_3", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_4", values: ["1", "2", "3", "4", "5"] }],
  contact_area: [{ slot: "slot_1", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_2", values: ["1", "2", "3", "4", "5"] },
  { slot: "slot_3", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_4", values: ["1", "2", "3", "4", "5"] }],
  specific_pressure: [{ slot: "slot_1", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_2", values: ["1", "2", "3", "4", "5"] },
  { slot: "slot_3", values: ["1", "2", "3", "4", "5"] }, { slot: "slot_4", values: ["1", "2", "3", "4", "5"] }]
}

export const graphDataTemp = [{
  axisY: "Vertical Deflection [mm]", axisX: "Load [Kg]", dataPoints: [
    {
      id: "V.P|No item-No Data : 140/55-9/4.00 XP1000 MP|NoPSL|45.3-996.6/1494.9/906.0|116415/NoCush/asd/NoBon",
      data: [
        { x: 1000, y: 9.5 },
        { x: 1500, y: 12.6 },
        { x: 2000, y: 15.6 },
        { x: 2500, y: 18 },
        { x: 3000, y: 20.1 },
        { x: 3500, y: 22.1 }]

    },
    {
      id: "V.P|No item-No Data : 140/55-9/4.00 XP1000 MP|NoPSL|45.3-996.6/1494.9/906.0|116415/NoCush/NoTre/NoBon",
      data: [
        { x: 1000, y: 9.6 },
        { x: 1500, y: 12.9 },
        { x: 2000, y: 15.8 },
        { x: 2500, y: 18.4 },
        { x: 3000, y: 20.5 },
        { x: 3500, y: 22.5 }]

    },
    /* {
      id: "construction_3/3k4knk3nkbfwdcmkvnkvV/VrvrvRKVk",
      data: [
        { x: 1000, y: 9.5 },
        { x: 1500, y: 12.6 },
        { x: 2000, y: 15.6 },
        { x: 2500, y: 18 },
        { x: 3000, y: 20.1 },
        { x: 3500, y: 22.1 }]

    },
    {
      id: "construction_4/3k4knk3nkbfwdcmkvnkvV/VrvrvRKVk",
      data: [
        { x: 1000, y: 9.6 },
        { x: 1500, y: 12.9 },
        { x: 2000, y: 15.8 },
        { x: 2500, y: 18.4 },
        { x: 3000, y: 20.5 },
        { x: 3500, y: 22.5 }]

    }, */
    /* {
        id: "construction_3",
        data:[
            { x: 1000, y: 9.5 },
            { x: 1500, y: 12.5 },
            { x: 2000, y: 15.75 },
            { x: 2500, y: 18.02 },
            { x: 3000, y: 20.6 },
            { x: 3500, y: 22.3 }]

    },

    {
        id: "construction_4",
        data:[
            { x: 1000, y: 9.5 },
            { x: 1500, y: 12.5 },
            { x: 2000, y: 15.75 },
            { x: 2500, y: 18.02 },
            { x: 3000, y: 20.6 },
            { x: 3500, y: 22.3 }]
    } */
  ]
},
{
  axisY: "Contact Area [cm^2]", axisX: "Load [Kg]", dataPoints: [
    {
      id: "V.P|No item-No Data : 140/55-9/4.00 XP1000 MP|NoPSL|45.3-996.6/1494.9/906.0|116415/NoCush/asd/NoBon",
      data: [
        { x: 1000, y: 9.5 },
        { x: 1500, y: 12.6 },
        { x: 2000, y: 15.6 },
        { x: 2500, y: 18 },
        { x: 3000, y: 20.1 },
        { x: 3500, y: null }]

    },
    {
      id: "V.P|No item-No Data : 140/55-9/4.00 XP1000 MP|NoPSL|45.3-996.6/1494.9/906.0|116415/NoCush/NoTre/NoBon",
      data: [
        { x: 1000, y: 9.6 },
        { x: 1500, y: 12.9 },
        { x: 2000, y: 15.8 },
        { x: 2500, y: 18.3 },
        { x: 3000, y: 20.5 },
        { x: 3500, y: null }]

    },
    /* {
      id: "construction_3/3k4knk3nkbfwdcmkvnkvV/VrvrvRKVk",
      data: [
        { x: 1000, y: 9.5 },
        { x: 1500, y: 12.6 },
        { x: 2000, y: 15.6 },
        { x: 2500, y: 18 },
        { x: 3000, y: 20.1 },
        { x: 3500, y: 22.1 }]

    },
    {
      id: "construction_4/3k4knk3nkbfwdcmkvnkvV/VrvrvRKVk",
      data: [
        { x: 1000, y: 9.6 },
        { x: 1500, y: 12.9 },
        { x: 2000, y: 15.8 },
        { x: 2500, y: 18.4 },
        { x: 3000, y: 20.5 },
        { x: 3500, y: 22.5 }]

    } */
    /* {
        id: "construction_3",
        data:[{ x: 600, y: 8 },
            { x: 1100, y: 10 },
            { x: 1400, y: 12 },
            { x: 1700, y: 14 },
            { x: 2050, y: 16 },
            { x: 2400, y: 18 },
            { x: 2900, y: 20 },
            { x: 3300, y: 22 }]
            

    },

    {
        id: "construction_4",
        data:[{ x: 600, y: 8 },
            { x: 1100, y: 10 },
            { x: 1400, y: 12 },
            { x: 1700, y: 14 },
            { x: 2050, y: 16 },
            { x: 2400, y: 18 },
            { x: 2900, y: 20 },
            { x: 3300, y: 22 }]
           
    } */
  ]
}]


export const tireFormDataTemp = {
  "product_selection": [
    { "label": "Tread type", "name": "tread_type", "value": "", options: ["1", "2", "3"], render: "select" },
    { "label": "Product", "name": "product", value: "", options: ["1", "2", "3"], render: "select" }],
  "custom_construction": [],
  "summary": [],
}

export const productsTemp = {
  "name": "product_identification_string",
  "label": "Product",
  "value": "",
  "type": "string",
  "isRequired": true,
  "options": [
    "No item-No Data : 10.00-20/8.00 XP800 MP",
    "No item-No Data : 140/55-9/4.00 XP1000 MP",
    "No item-No Data : 140/55-9/4.00 XP1000 NM",
    "No item-No Data : 140/55-9/4.00 XP800 MP",
    "No item-No Data : 15x4.1/2-8/3.00 XP1000 MP",
    "No item-No Data : 15x4.1/2-8/3.00 XP1000 NM",
    "No item-No Data : 15x4.1/2-8/3.00 XP800 MP",
    "No item-No Data : 16x6-8/4.33 XP1000 MP",
    "No item-No Data : 16x6-8/4.33 XP1000 NM",
    "No item-No Data : 16x6-8/4.33 XP800 MP",
    "No item-No Data : 18x7-8/4.33 XP1000 MP",
    "No item-No Data : 18x7-8/4.33 XP1000 NM",
    "No item-No Data : 18x7-8/4.33 XP800 MP",
    "No item-No Data : 200/50-10/6.50 XP1000 MP",
    "No item-No Data : 200/50-10/6.50 XP1000 NM",
    "No item-No Data : 200/50-10/6.50 XP800 MP",
    "No item-No Data : 21x8-9/6.00 XP1000 MP",
    "No item-No Data : 21x8-9/6.00 XP1000 NM",
    "No item-No Data : 21x8-9/6.00 XP800 MP",
    "No item-No Data : 23x10-12/8.00 XP1000 MP",
    "No item-No Data : 23x10-12/8.00 XP1000 NM",
    "No item-No Data : 23x10-12/8.00 XP800 MP",
    "No item-No Data : 23x9-10/6.50 XP1000 MP",
    "No item-No Data : 23x9-10/6.50 XP1000 NM",
    "No item-No Data : 23x9-10/6.50 XP800 MP",
    "No item-No Data : 250-15/7.00 XP1000 MP",
    "No item-No Data : 250-15/7.00 XP1000 NM",
    "No item-No Data : 250-15/7.00 XP800 MP",
    "No item-No Data : 250-15/7.50 XP1000 MP",
    "No item-No Data : 250-15/7.50 XP1000 NM",
    "No item-No Data : 250-15/7.50 XP800 MP",
    "No item-No Data : 27x10-12/8.00 XP1000 MP",
    "No item-No Data : 27x10-12/8.00 XP1000 NM",
    "No item-No Data : 27x10-12/8.00 XP800 MP",
    "No item-No Data : 28x9-15/7.00 XP1000 MP",
    "No item-No Data : 28x9-15/7.00 XP1000 NM",
    "No item-No Data : 28x9-15/7.00 XP800 MP",
    "No item-No Data : 300-15/8.00 XP1000 MP",
    "No item-No Data : 300-15/8.00 XP1000 NM",
    "No item-No Data : 300-15/8.00 XP800 MP",
    "No item-No Data : 315/45-12/10.00 XP1000 MP",
    "No item-No Data : 315/45-12/10.00 XP1000 NM",
    "No item-No Data : 355/45-15/9.75 XP1000 MP",
    "No item-No Data : 355/45-15/9.75 XP1000 NM",
    "No item-No Data : 355/45-15/9.75 XP800 MP",
    "No item-No Data : 355/50-15/9.75 XP1000 MP",
    "No item-No Data : 355/50-15/9.75 XP1000 NM",
    "No item-No Data : 355/50-15/9.75 XP800 MP",
    "No item-No Data : 355/65-15/9.75 XP1000 MP",
    "No item-No Data : 355/65-15/9.75 XP1000 NM",
    "No item-No Data : 355/65-15/9.75 XP800 MP",
    "No item-No Data : 4.00-8/3.00 XP1000 MP",
    "No item-No Data : 4.00-8/3.00 XP1000 NM",
    "No item-No Data : 4.00-8/3.00 XP800 MP",
    "No item-No Data : 5.00-8/3.00 XP1000 MP",
    "No item-No Data : 5.00-8/3.00 XP1000 NM",
    "No item-No Data : 5.00-8/3.00 XP800 MP",
    "No item-No Data : 6.00-9/4.00 XP1000 MP",
    "No item-No Data : 6.00-9/4.00 XP1000 NM",
    "No item-No Data : 6.00-9/4.00 XP800 MP",
    "No item-No Data : 6.50-10/5.00 XP1000 MP",
    "No item-No Data : 6.50-10/5.00 XP1000 NM",
    "No item-No Data : 6.50-10/5.00 XP800 MP",
    "No item-No Data : 7.00-12/5.00 XP1000 MP",
    "No item-No Data : 7.00-12/5.00 XP1000 NM",
    "No item-No Data : 7.00-12/5.00 XP800 MP",
    "No item-No Data : 7.00-15/5.50 XP1000 MP",
    "No item-No Data : 7.00-15/5.50 XP1000 NM",
    "No item-No Data : 7.00-15/5.50 XP800 MP",
    "No item-No Data : 7.50-15/6.50 XP800 MP",
    "No item-No Data : 8.25-15/6.50 XP1000 MP",
    "No item-No Data : 8.25-15/6.50 XP1000 NM",
    "No item-No Data : 8.25-15/6.50 XP800 MP"
  ],
  "render": "select",
  "isEditable": true
}

export const treadsTemp =
  [
    "XP1000",
    "XP800"
  ]


export const toSendBla = {
  "general": [
    {
      "name": "compound_code",
      "label": "Code",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "rating_name",
      "label": "Rating",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [
        "1° tier",
        "2° tier",
        "3° tier"
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "default_configuration",
      "label": "Default Configuration",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [
        "Base",
        "Bonding",
        "Cushion",
        "No Data",
        "Other",
        "Tread"
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "spg_min",
      "label": "Spg min",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "spg_max",
      "label": "Spg Max",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "spg_average",
      "label": "Average spg",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "action": "average",
      "action_fields": ["spg_min", "spg_max"]
    },
    {
      "name": "hardness_min",
      "label": "Hardness min",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "hardness_max",
      "label": "Hardness max",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "hardness_average",
      "label": "Average Hardness",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "action": "average",
      "action_fields": ["hardness_min", "hardness_max"]
    },
    {
      "name": "is_in_production",
      "label": "Is in production",
      "value": false,
      "type": "bool",
      "isRequired": true,
      "options": [
        true,
        false
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "plant_name",
      "label": "Production Plant",
      "value": "",
      "type": "string",
      "isRequired": false,
      "requiredOnCondition": { name: "is_in_production", value: "true" },
      "options": [
        "No Data",
        "TWS_tivoli"
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "cost_kg",
      "label": "Cost/kg",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "currency",
      "label": "Currency",
      "value": "",
      "type": "string",
      "isRequired": true,
      "requiredOnCondition": { name: "cost_kg", value: "valorized" },
      "options": [
        "LKR",
        "Rais",
        "USD",
        "EUR",
        "JPY",
        "INR",
        "GBP",
        "AUD",
        "CAD",
        "CHF",
        "SEK",
        "NZD",
        "CNY",
        "RUB"
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "cost_kg_usd",
      "label": "Cost/kg(USD)",
      "value": "number",
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "toCalulate": "cost/kg(USD)"
    },
    {
      "name": "compound_note",
      "label": "Note",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    }
  ],
  "performance": [
    {
      "name": "static_base_index",
      "label": "Static test (stiffness) base index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "static_cushion_index",
      "label": "Static test (stiffness) cushion index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "static_tread_index",
      "label": "Static test (stiffness) tread index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "rr_base_index",
      "label": "Rolling resistance test base index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "rr_cushion_index",
      "label": "Rolling resistance test cushion index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "rr_tread_index",
      "label": "Rolling resistance test tread index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "hbu_base_index",
      "label": "Heat build up test base index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "hbu_cushion_index",
      "label": "Heat build up test cushion index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "hbu_tread_index",
      "label": "Heat build up test tread index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "wear_base_index",
      "label": "Wear test base index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "wear_cushion_index",
      "label": "Wear test cushion index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "wear_tread_index",
      "label": "Wear test tread index",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "compound_note",
      "label": "Note",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    }
  ]
}

/* export const tempRespProductsForm = {
    "general": [
        {
            "name": "is_existing_item",
            "label": "Is existing item",
            "value": false,
            "type": "bool",
            "isRequired": true,
            "options": [
                true,
                false
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "item_code",
            "label": "Item code",
            "value": "",
            "type": "string",
            "isRequired": false,
            "requiredOnCondition": { name: "is_existing_item", value: "true" },
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "other_code",
            "label": "Other code",
            "value": "",
            "type": "string",
            "isRequired": false,
            "requiredOnCondition": { name: "is_existing_item", value: "false" },
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "size_name",
            "label": "Size",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "10.00-20/8.00",
                "140/55-9/4.00",
                "15x4.1/2-8/3.00",
                "16x6-8/4.33",
                "18x7-8/4.33",
                "200/50-10/6.50",
                "21x8-9/6.00",
                "23x10-12/8.00",
                "23x9-10/6.50",
                "250-15/7.00",
                "250-15/7.50",
                "27x10-12/8.00",
                "28x9-15/7.00",
                "300-15/8.00",
                "315/45-12/10.00",
                "355/45-15/9.75",
                "355/50-15/9.75",
                "355/65-15/9.75",
                "4.00-8/3.00",
                "5.00-8/3.00",
                "6.00-9/4.00",
                "6.50-10/5.00",
                "7.00-12/5.00",
                "7.00-15/5.50",
                "7.50-15/6.50",
                "8.25-15/6.50",
                "MP",
                "NM"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "tread_profile_name",
            "label": "Tread Profile",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "XP1000",
                "XP800"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "rim_type",
            "label": "Rim Type",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "No Data0",
                "No Data1",
                "No Data10",
                "No Data11",
                "No Data12",
                "No Data13",
                "No Data2",
                "No Data3",
                "No Data4",
                "No Data5",
                "No Data6",
                "No Data7",
                "No Data8",
                "No Data9"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "spec_name",
            "label": "Spec",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "ELCON",
                "MP",
                "NM",
                "No Data",
                "PROTEX",
                "SCRUBBER"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "loc",
            "label": "Loc",
            "value": false,
            "type": "bool",
            "isRequired": true,
            "options": [
                true,
                false
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "openings",
            "label": "Openings",
            "value": false,
            "type": "bool",
            "isRequired": true,
            "options": [
                true,
                false
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "complete_size_name",
            "label": "Complete size name",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true,
            "toConcatenate": true
        },
        {
            "name": "identification_product_string",
            "label": "Identification product string",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true,
            "toConcatenate": true
        },
        {
            "name": "pattern",
            "label": "Pattern",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "Directional",
                "No Data",
                "Other",
                "Ribbed",
                "Smoot",
                "Traction"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "load_index",
            "label": "Load Index",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "speed_symbol",
            "label": "SpeedSymbol",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "plant_name",
            "label": "Plant",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [
                "No Data",
                "TWS_tivoli"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "is_in_production",
            "label": "Is in production",
            "value": false,
            "type": "bool",
            "isRequired": false,
            "options": [
                true,
                false
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "solid_tyre_type",
            "label": "Solid tyre type",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [
                "C - Mold on",
                "C- Resilient",
                "MH - POS",
                "MH - Resilient"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "rating_name",
            "label": "Rating",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [
                "1° tier",
                "2° tier",
                "3° tier"
            ],
            "render": "select",
            "isEditable": true
        }
    ]
}

export const tempRespConstructionForm = {
    "general": [
        {
            "name": "product_identification_string",
            "label": "Product identification string",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "No item-No Data : 10.00-20/8.00 XP800 MP",
                "No item-No Data : 140/55-9/4.00 XP1000 MP",
                "No item-No Data : 140/55-9/4.00 XP1000 NM",
                "No item-No Data : 140/55-9/4.00 XP800 MP",
                "No item-No Data : 15x4.1/2-8/3.00 XP1000 MP",
                "No item-No Data : 15x4.1/2-8/3.00 XP1000 NM",
                "No item-No Data : 15x4.1/2-8/3.00 XP800 MP",
                "No item-No Data : 16x6-8/4.33 XP1000 MP",
                "No item-No Data : 16x6-8/4.33 XP1000 NM",
                "No item-No Data : 16x6-8/4.33 XP800 MP",
                "No item-No Data : 18x7-8/4.33 XP1000 MP",
                "No item-No Data : 18x7-8/4.33 XP1000 NM",
                "No item-No Data : 18x7-8/4.33 XP800 MP",
                "No item-No Data : 200/50-10/6.50 XP1000 MP",
                "No item-No Data : 200/50-10/6.50 XP1000 NM",
                "No item-No Data : 200/50-10/6.50 XP800 MP",
                "No item-No Data : 21x8-9/6.00 XP1000 MP",
                "No item-No Data : 21x8-9/6.00 XP1000 NM",
                "No item-No Data : 21x8-9/6.00 XP800 MP",
                "No item-No Data : 23x10-12/8.00 XP1000 MP",
                "No item-No Data : 23x10-12/8.00 XP1000 NM",
                "No item-No Data : 23x10-12/8.00 XP800 MP",
                "No item-No Data : 23x9-10/6.50 XP1000 MP",
                "No item-No Data : 23x9-10/6.50 XP1000 NM",
                "No item-No Data : 23x9-10/6.50 XP800 MP",
                "No item-No Data : 250-15/7.00 XP1000 MP",
                "No item-No Data : 250-15/7.00 XP1000 NM",
                "No item-No Data : 250-15/7.00 XP800 MP",
                "No item-No Data : 250-15/7.50 XP1000 MP",
                "No item-No Data : 250-15/7.50 XP1000 NM",
                "No item-No Data : 250-15/7.50 XP800 MP",
                "No item-No Data : 27x10-12/8.00 XP1000 MP",
                "No item-No Data : 27x10-12/8.00 XP1000 NM",
                "No item-No Data : 27x10-12/8.00 XP800 MP",
                "No item-No Data : 28x9-15/7.00 XP1000 MP",
                "No item-No Data : 28x9-15/7.00 XP1000 NM",
                "No item-No Data : 28x9-15/7.00 XP800 MP",
                "No item-No Data : 300-15/8.00 XP1000 MP",
                "No item-No Data : 300-15/8.00 XP1000 NM",
                "No item-No Data : 300-15/8.00 XP800 MP",
                "No item-No Data : 315/45-12/10.00 XP1000 MP",
                "No item-No Data : 315/45-12/10.00 XP1000 NM",
                "No item-No Data : 355/45-15/9.75 XP1000 MP",
                "No item-No Data : 355/45-15/9.75 XP1000 NM",
                "No item-No Data : 355/45-15/9.75 XP800 MP",
                "No item-No Data : 355/50-15/9.75 XP1000 MP",
                "No item-No Data : 355/50-15/9.75 XP1000 NM",
                "No item-No Data : 355/50-15/9.75 XP800 MP",
                "No item-No Data : 355/65-15/9.75 XP1000 MP",
                "No item-No Data : 355/65-15/9.75 XP1000 NM",
                "No item-No Data : 355/65-15/9.75 XP800 MP",
                "No item-No Data : 4.00-8/3.00 XP1000 MP",
                "No item-No Data : 4.00-8/3.00 XP1000 NM",
                "No item-No Data : 4.00-8/3.00 XP800 MP",
                "No item-No Data : 5.00-8/3.00 XP1000 MP",
                "No item-No Data : 5.00-8/3.00 XP1000 NM",
                "No item-No Data : 5.00-8/3.00 XP800 MP",
                "No item-No Data : 6.00-9/4.00 XP1000 MP",
                "No item-No Data : 6.00-9/4.00 XP1000 NM",
                "No item-No Data : 6.00-9/4.00 XP800 MP",
                "No item-No Data : 6.50-10/5.00 XP1000 MP",
                "No item-No Data : 6.50-10/5.00 XP1000 NM",
                "No item-No Data : 6.50-10/5.00 XP800 MP",
                "No item-No Data : 7.00-12/5.00 XP1000 MP",
                "No item-No Data : 7.00-12/5.00 XP1000 NM",
                "No item-No Data : 7.00-12/5.00 XP800 MP",
                "No item-No Data : 7.00-15/5.50 XP1000 MP",
                "No item-No Data : 7.00-15/5.50 XP1000 NM",
                "No item-No Data : 7.00-15/5.50 XP800 MP",
                "No item-No Data : 7.50-15/6.50 XP800 MP",
                "No item-No Data : 8.25-15/6.50 XP1000 MP",
                "No item-No Data : 8.25-15/6.50 XP1000 NM",
                "No item-No Data : 8.25-15/6.50 XP800 MP"
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "tire_item_type",
            "label": "Tire item type",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                "Default item construction",
                "Prototype"
            ],
            "render": "radio",
            "isEditable": true
        },
        {
            "name": "construction_type",
            "label": "Construction type",
            "value": "Real",
            "type": "string",
            "isRequired": true,
            "options": [
                "Real",
                "Virtual"
            ],
            "render": "radio",
            "isEditable": true,
            "isDisabled": { name: "tire_item_type", value: "Default item construction" }
        },
        {
            "name": "total_section_height_mm",
            "label": "Total section height (mm)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "pit_stop_line",
            "label": "Pit Stop Line",
            "value": false,
            "type": "bool",
            "isRequired": true,
            "options": [],
            "render": "checkbox",
            "isEditable": true
        },
        {
            "name": "base_section_height_mm",
            "label": "Base section height (mm)",
            "value": 0,
            "type": "number",
            "isRequired": true,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "cushion_section_height_mm",
            "label": "Cushion section height (mm)",
            "value": 0,
            "type": "number",
            "isRequired": true,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "tread_section_height_mm",
            "label": "Tread section height (mm)",
            "value": 0,
            "type": "number",
            "isRequired": true,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "base_section_height_percent",
            "label": "Base section height %",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "percentage",
            "fieldToCalculate": ["base_section_height_mm", "total_section_height_mm"]
        },
        {
            "name": "cushion_section_height_percent",
            "label": "Cushion section height %",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "percentage",
            "action_fields": ["cushion_section_height_mm", "total_section_height_mm"],
        },
        {
            "name": "tread_section_height_percent",
            "label": "Tread section height %",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "percentage",
            "action_fields": ["tread_section_height_mm", "total_section_height_mm"]
        },
        {
            "name": "base_compound_code_select",
            "label": "Base compound",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                { value: "112268", spg_average: 1.8, cost_kg: 30 },
                { value: "116214", spg_average: 1.8, cost_kg: 30 },
                { value: "116410", spg_average: 1.8, cost_kg: 30 },

            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "cushion_compound_code_select",
            "label": "Cushion compound",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [
                { value: "112268", spg_average: 1.8, cost_kg: 30 },
                { value: "116214", spg_average: 1.8, cost_kg: 30 },
                { value: "116410", spg_average: 1.8, cost_kg: 30 },
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "tread_compound_code_select",
            "label": "Tread compound",
            "value": "",
            "type": "string",
            "isRequired": true,
            "options": [
                { value: "112268", spg_average: 1.8, cost_kg: 30 },
                { value: "116214", spg_average: 1.8, cost_kg: 30 },
                { value: "116410", spg_average: 1.8, cost_kg: 30 },
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "bonding_compound_code_select",
            "label": "Bonding compound",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [
                { value: "112268", spg_average: 1.8, cost_kg: 30 },
                { value: "116214", spg_average: 1.8, cost_kg: 30 },
                { value: "116410", spg_average: 1.8, cost_kg: 30 },
            ],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "construction_identification_string",
            "label": "Construction identification string",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "toConcatenate": true
        }
    ],
    "details": [
        {
            "name": "weight_bead_bundle_kg",
            "label": "Bead bundle weight (kg)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_1",
            "label": "Component name 1",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_1",
            "label": "Component weight 1",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_2",
            "label": "Component name 2",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_2",
            "label": "Component weight 2",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_3",
            "label": "Component name 3",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_3",
            "label": "Component weight 3",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_4",
            "label": "Component name 4",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_4",
            "label": "Component weight 4",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_5",
            "label": "Component name 5",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_5",
            "label": "Component weight 5",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_name_6",
            "label": "Component name 6",
            "value": "",
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "additional_component_weight_6",
            "label": "Component weight 6",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "base_weight_kg",
            "label": "Base weight (kg)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "base_volume_cm3",
            "label": "Base volume (cm^3)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula1"
        },
        {
            "name": "cushion_weight_kg",
            "label": "Cushion weight (kg)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "cushion_volume_cm3",
            "label": "Cushion volume (cm^3)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula1"
        },
        {
            "name": "tread_weight_kg",
            "label": "Tread weight (kg)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "tread_volume_cm3",
            "label": "Tread volume (cm^3)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula1"
        },
        {
            "name": "bonding_weight_kg",
            "label": "Bonding weight (kg)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        },
        {
            "name": "bonding_volume_cm3",
            "label": "Bonding volume (cm^3)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula1"
        },
        {
            "name": "total_compound_weight",
            "label": "Total Compound weight",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula2"
        },
        {
            "name": "total_compound_volume",
            "label": "Total Compound volume",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula3",
        },
        {
            "name": "total_tire_compound_cost_usd",
            "label": "Total tire compound cost(USD)",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula4"
        },
        {
            "name": "total_components_weight",
            "label": "Total components weight",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula5"
        },
        {
            "name": "total_tire_weight",
            "label": "Total tire weight",
            "value": 0,
            "type": "number",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": false,
            "action": "formula6"
        },
        {
            "name": "construction_note",
            "label": "Construction note",
            "value": 0,
            "type": "string",
            "isRequired": false,
            "options": [],
            "render": "select",
            "isEditable": true
        }
    ]
}  */



export const testsFormTemp = [
  {
    "name": "hbu_tread_index",
    "label": "Heat build up test tread index",
    "value": 0,
    "type": "number",
    "isRequired": false,
    "options": [],
    "render": "select",
    "isEditable": true
  },
  {
    "name": "wear_base_index",
    "label": "Wear test base index",
    "value": 0,
    "type": "number",
    "isRequired": false,
    "options": [],
    "render": "select",
    "isEditable": true
  },
  {
    "name": "wear_cushion_index",
    "label": "Wear test cushion index",
    "value": 0,
    "type": "number",
    "isRequired": false,
    "options": [],
    "render": "select",
    "isEditable": true
  }, {
    "name": "wear_tread_index",
    "label": "Wear test tread index",
    "value": ["4", "5", "6", "7"],
    "type": "number",
    "isRequired": false,
    "options": [],
    "render": "select",
    "isEditable": true,
    "groupRender": "subSection",
    "group": true,

  },
  {
    "name": "compound_note",
    "label": "Note",
    "value": ["4", "5", "6", "7"],
    "type": "string",
    "isRequired": false,
    "options": [],
    "render": "select",
    "isEditable": true,
    "groupRender": "subSection",
    "group": true,
  }
]


export const toSend = [
  {
    name: 'product_identification_string',
    label: 'Product identification string',
    value: 'No item-No Data : 10.00-20/8.00 XP800 MP',
    type: 'string',
    isRequired: true,
    options: [
      'No item-No Data : 10.00-20/8.00 XP800 MP',
      'No item-No Data : 140/55-9/4.00 XP1000 MP',
      'No item-No Data : 140/55-9/4.00 XP1000 NM',
      'No item-No Data : 140/55-9/4.00 XP800 MP',
      'No item-No Data : 15x4.1/2-8/3.00 XP1000 MP',
      'No item-No Data : 15x4.1/2-8/3.00 XP1000 NM',
      'No item-No Data : 15x4.1/2-8/3.00 XP800 MP',
      'No item-No Data : 16x6-8/4.33 XP1000 MP',
      'No item-No Data : 16x6-8/4.33 XP1000 NM',
      'No item-No Data : 16x6-8/4.33 XP800 MP',
      'No item-No Data : 18x7-8/4.33 XP1000 MP',
      'No item-No Data : 18x7-8/4.33 XP1000 NM',
      'No item-No Data : 18x7-8/4.33 XP800 MP',
      'No item-No Data : 200/50-10/6.50 XP1000 MP',
      'No item-No Data : 200/50-10/6.50 XP1000 NM',
      'No item-No Data : 200/50-10/6.50 XP800 MP',
      'No item-No Data : 21x8-9/6.00 XP1000 MP',
      'No item-No Data : 21x8-9/6.00 XP1000 NM',
      'No item-No Data : 21x8-9/6.00 XP800 MP',
      'No item-No Data : 23x10-12/8.00 XP1000 MP',
      'No item-No Data : 23x10-12/8.00 XP1000 NM',
      'No item-No Data : 23x10-12/8.00 XP800 MP',
      'No item-No Data : 23x9-10/6.50 XP1000 MP',
      'No item-No Data : 23x9-10/6.50 XP1000 NM',
      'No item-No Data : 23x9-10/6.50 XP800 MP',
      'No item-No Data : 250-15/7.00 XP1000 MP',
      'No item-No Data : 250-15/7.00 XP1000 NM',
      'No item-No Data : 250-15/7.00 XP800 MP',
      'No item-No Data : 250-15/7.50 XP1000 MP',
      'No item-No Data : 250-15/7.50 XP1000 NM',
      'No item-No Data : 250-15/7.50 XP800 MP',
      'No item-No Data : 27x10-12/8.00 XP1000 MP',
      'No item-No Data : 27x10-12/8.00 XP1000 NM',
      'No item-No Data : 27x10-12/8.00 XP800 MP',
      'No item-No Data : 28x9-15/7.00 XP1000 MP',
      'No item-No Data : 28x9-15/7.00 XP1000 NM',
      'No item-No Data : 28x9-15/7.00 XP800 MP',
      'No item-No Data : 300-15/8.00 XP1000 MP',
      'No item-No Data : 300-15/8.00 XP1000 NM',
      'No item-No Data : 300-15/8.00 XP800 MP',
      'No item-No Data : 315/45-12/10.00 XP1000 MP',
      'No item-No Data : 315/45-12/10.00 XP1000 NM',
      'No item-No Data : 355/45-15/9.75 XP1000 MP',
      'No item-No Data : 355/45-15/9.75 XP1000 NM',
      'No item-No Data : 355/45-15/9.75 XP800 MP',
      'No item-No Data : 355/50-15/9.75 XP1000 MP',
      'No item-No Data : 355/50-15/9.75 XP1000 NM',
      'No item-No Data : 355/50-15/9.75 XP800 MP',
      'No item-No Data : 355/65-15/9.75 XP1000 MP',
      'No item-No Data : 355/65-15/9.75 XP1000 NM',
      'No item-No Data : 355/65-15/9.75 XP800 MP',
      'No item-No Data : 4.00-8/3.00 XP1000 MP',
      'No item-No Data : 4.00-8/3.00 XP1000 NM',
      'No item-No Data : 4.00-8/3.00 XP800 MP',
      'No item-No Data : 5.00-8/3.00 XP1000 MP',
      'No item-No Data : 5.00-8/3.00 XP1000 NM',
      'No item-No Data : 5.00-8/3.00 XP800 MP',
      'No item-No Data : 6.00-9/4.00 XP1000 MP',
      'No item-No Data : 6.00-9/4.00 XP1000 NM',
      'No item-No Data : 6.00-9/4.00 XP800 MP',
      'No item-No Data : 6.50-10/5.00 XP1000 MP',
      'No item-No Data : 6.50-10/5.00 XP1000 NM',
      'No item-No Data : 6.50-10/5.00 XP800 MP',
      'No item-No Data : 7.00-12/5.00 XP1000 MP',
      'No item-No Data : 7.00-12/5.00 XP1000 NM',
      'No item-No Data : 7.00-12/5.00 XP800 MP',
      'No item-No Data : 7.00-15/5.50 XP1000 MP',
      'No item-No Data : 7.00-15/5.50 XP1000 NM',
      'No item-No Data : 7.00-15/5.50 XP800 MP',
      'No item-No Data : 7.50-15/6.50 XP800 MP',
      'No item-No Data : 8.25-15/6.50 XP1000 MP',
      'No item-No Data : 8.25-15/6.50 XP1000 NM',
      'No item-No Data : 8.25-15/6.50 XP800 MP'
    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'tire_item_type',
    label: 'Tire item type',
    value: 'Default item construction',
    type: 'string',
    isRequired: true,
    options: [
      'Default item construction',
      'Prototype'
    ],
    render: 'radio',
    isEditable: true
  },
  {
    name: 'construction_type',
    label: 'Construction type',
    value: 'Real',
    type: 'string',
    isRequired: true,
    options: [
      'Real',
      'Virtual'
    ],
    render: 'radio',
    isEditable: true
  },
  {
    name: 'total_section_height_mm',
    label: 'Total section height (mm)',
    value: 100,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'pit_stop_line',
    label: 'Pit Stop Line',
    value: false,
    type: 'bool',
    isRequired: true,
    options: [

    ],
    render: 'checkbox',
    isEditable: true
  },
  {
    name: 'base_section_height_mm',
    label: 'Base section height (mm)',
    value: 30,
    type: 'number',
    isRequired: true,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'cushion_section_height_mm',
    label: 'Cushion section height (mm)',
    value: 40,
    type: 'number',
    isRequired: true,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'tread_section_height_mm',
    label: 'Tread section height (mm)',
    value: 30,
    type: 'number',
    isRequired: true,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'base_section_height_percent',
    label: 'Base section height %',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'percentage',
    action_fields: [
      'base_section_height_mm',
      'total_section_height_mm'
    ]
  },
  {
    name: 'cushion_section_height_percent',
    label: 'Cushion section height %',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'percentage',
    action_fields: [
      'cushion_section_height_mm',
      'total_section_height_mm'
    ]
  },
  {
    name: 'tread_section_height_percent',
    label: 'Tread section height %',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'percentage',
    action_fields: [
      'tread_section_height_mm',
      'total_section_height_mm'
    ]
  },
  {
    name: 'base_compound_code_select',
    label: 'Base compound',
    value: '116410',
    type: 'string',
    isRequired: true,
    options: [
      '116410'
    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'cushion_compound_code_select',
    label: 'Cushion compound',
    value: '116714',
    type: 'string',
    isRequired: false,
    options: [
      '116513',
      '116714'
    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'tread_compound_code_select',
    label: 'Tread compound',
    value: '116419',
    type: 'string',
    isRequired: true,
    options: [
      '116419',
      '186716'
    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'bonding_compound_code_select',
    label: 'Bonding compound',
    value: '126813',
    type: 'string',
    isRequired: false,
    options: [
      '126813'
    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'construction_identification_string',
    label: 'Construction identification string',
    value: '',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    action: 'concatenate_construction',
    action_fields: [
      'product_identification_string',
      'tire_item_type',
      'construction_type',
      'pit_stop_line',
      'total_section_height_mm',
      'base_section_height_mm',
      'cushion_section_height_mm',
      'tread_section_height_mm',
      'base_compound_code_select',
      'cushion_compound_code_select',
      'tread_compound_code_select',
      'bonding_compound_code_select'
    ]
  },
  {
    name: 'weight_bead_bundle_kg',
    label: 'Bead bundle weight (kg)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_1',
    label: 'Component name 1',
    value: 'componente1',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_1',
    label: 'Component weight 1',
    value: 20,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_2',
    label: 'Component name 2',
    value: 'componente2',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_2',
    label: 'Component weight 2',
    value: 30,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_3',
    label: 'Component name 3',
    value: '',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_3',
    label: 'Component weight 3',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_4',
    label: 'Component name 4',
    value: '',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_4',
    label: 'Component weight 4',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_5',
    label: 'Component name 5',
    value: '',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_5',
    label: 'Component weight 5',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_name_6',
    label: 'Component name 6',
    value: '',
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'additional_component_weight_6',
    label: 'Component weight 6',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'base_weight_kg',
    label: 'Base weight (kg)',
    value: 20,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'base_volume_cm3',
    label: 'Base volume (cm^3)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula1',
    action_fields: [
      'base_weight_kg',
      'base_compound_code_select'
    ]
  },
  {
    name: 'cushion_weight_kg',
    label: 'Cushion weight (kg)',
    value: 20,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'cushion_volume_cm3',
    label: 'Cushion volume (cm^3)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula1',
    action_fields: [
      'cushion_weight_kg',
      'cushion_compound_code_select'
    ]
  },
  {
    name: 'tread_weight_kg',
    label: 'Tread weight (kg)',
    value: 60,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'tread_volume_cm3',
    label: 'Tread volume (cm^3)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula1',
    action_fields: [
      'tread_weight_kg',
      'tread_compound_code_select'
    ]
  },
  {
    name: 'bonding_weight_kg',
    label: 'Bonding weight (kg)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  },
  {
    name: 'bonding_volume_cm3',
    label: 'Bonding volume (cm^3)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula1',
    action_fields: [
      'bonding_weight_kg',
      'bonding_compound_code_select'
    ]
  },
  {
    name: 'total_compound_weight',
    label: 'Total Compound weight',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula2',
    action_fields: [
      'base_weight_kg',
      'cushion_weight_kg',
      'tread_weight_kg',
      'bonding_weight_kg'
    ]
  },
  {
    name: 'total_compound_volume',
    label: 'Total Compound volume',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula3',
    action_fields: [
      'base_weight_kg',
      'base_compound_code_select',
      'cushion_weight_kg',
      'cushion_compound_code_select',
      'tread_weight_kg',
      'tread_compound_code_select',
      'bonding_weight_kg',
      'bonding_compound_code_select'
    ]
  },
  {
    name: 'total_tire_compound_cost_usd',
    label: 'Total tire compound cost(USD)',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula4',
    action_fields: [
      'base_weight_kg',
      'base_compound_code_select',
      'cushion_weight_kg',
      'cushion_compound_code_select',
      'tread_weight_kg',
      'tread_compound_code_select',
      'bonding_weight_kg',
      'bonding_compound_code_select'
    ]
  },
  {
    name: 'total_components_weight',
    label: 'Total components weight',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula5',
    action_fields: [
      'additional_component_weight_1',
      'additional_component_weight_2',
      'additional_component_weight_3',
      'additional_component_weight_4',
      'additional_component_weight_5',
      'additional_component_weight_6'
    ]
  },
  {
    name: 'total_tire_weight',
    label: 'Total tire weight',
    value: 0,
    type: 'number',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: false,
    action: 'formula6',
    action_fields: [
      'base_weight_kg',
      'cushion_weight_kg',
      'tread_weight_kg',
      'bonding_weight_kg',
      'additional_component_weight_1',
      'additional_component_weight_2',
      'additional_component_weight_3',
      'additional_component_weight_4',
      'additional_component_weight_5',
      'additional_component_weight_6'
    ]
  },
  {
    name: 'construction_note',
    label: 'Construction note',
    value: 0,
    type: 'string',
    isRequired: false,
    options: [

    ],
    render: 'select',
    isEditable: true
  }
]


//const values = Object.values(toSend)
//const flat_ = values[0].concat(values[1])

export const constructionsGroupedTemp = {
  "general": [
    {
      "name": "product_identification_string",
      "label": "Product identification string",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [
        "123456 : 140/55-9/4.00 XP1000 MP",
        "165473 : 23x10-12/8.00 XP800 MP",
        "No item-No Data : 10.00-20/8.00 XP800 MP",
        "No item-No Data : 140/55-9/4.00 XP1000 MP",
        "No item-No Data : 140/55-9/4.00 XP1000 NM",
        "No item-No Data : 140/55-9/4.00 XP800 MP",
        "No item-No Data : 15x4.1/2-8/3.00 XP1000 MP",
        "No item-No Data : 15x4.1/2-8/3.00 XP1000 NM",
        "No item-No Data : 15x4.1/2-8/3.00 XP800 MP",
        "No item-No Data : 16x6-8/4.33 XP1000 MP",
        "No item-No Data : 16x6-8/4.33 XP1000 NM",
        "No item-No Data : 16x6-8/4.33 XP800 MP",
        "No item-No Data : 18x7-8/4.33 XP1000 MP",
        "No item-No Data : 18x7-8/4.33 XP1000 NM",
        "No item-No Data : 18x7-8/4.33 XP800 MP",
        "No item-No Data : 200/50-10/6.50 XP1000 MP",
        "No item-No Data : 200/50-10/6.50 XP1000 NM",
        "No item-No Data : 200/50-10/6.50 XP800 MP",
        "No item-No Data : 21x8-9/6.00 XP1000 MP",
        "No item-No Data : 21x8-9/6.00 XP1000 NM",
        "No item-No Data : 21x8-9/6.00 XP800 MP",
        "No item-No Data : 23x10-12/8.00 XP1000 MP",
        "No item-No Data : 23x10-12/8.00 XP1000 NM",
        "No item-No Data : 23x10-12/8.00 XP800 MP",
        "No item-No Data : 23x9-10/6.50 XP1000 MP",
        "No item-No Data : 23x9-10/6.50 XP1000 NM",
        "No item-No Data : 23x9-10/6.50 XP800 MP",
        "No item-No Data : 250-15/7.00 XP1000 MP",
        "No item-No Data : 250-15/7.00 XP1000 NM",
        "No item-No Data : 250-15/7.00 XP800 MP",
        "No item-No Data : 250-15/7.50 XP1000 MP",
        "No item-No Data : 250-15/7.50 XP1000 NM",
        "No item-No Data : 250-15/7.50 XP800 MP",
        "No item-No Data : 27x10-12/8.00 XP1000 MP",
        "No item-No Data : 27x10-12/8.00 XP1000 NM",
        "No item-No Data : 27x10-12/8.00 XP800 MP",
        "No item-No Data : 28x9-15/7.00 XP1000 MP",
        "No item-No Data : 28x9-15/7.00 XP1000 NM",
        "No item-No Data : 28x9-15/7.00 XP800 MP",
        "No item-No Data : 300-15/8.00 XP1000 MP",
        "No item-No Data : 300-15/8.00 XP1000 NM",
        "No item-No Data : 300-15/8.00 XP800 MP",
        "No item-No Data : 315/45-12/10.00 XP1000 MP",
        "No item-No Data : 315/45-12/10.00 XP1000 NM",
        "No item-No Data : 355/45-15/9.75 XP1000 MP",
        "No item-No Data : 355/45-15/9.75 XP1000 NM",
        "No item-No Data : 355/45-15/9.75 XP800 MP",
        "No item-No Data : 355/50-15/9.75 XP1000 MP",
        "No item-No Data : 355/50-15/9.75 XP1000 NM",
        "No item-No Data : 355/50-15/9.75 XP800 MP",
        "No item-No Data : 355/65-15/9.75 XP1000 MP",
        "No item-No Data : 355/65-15/9.75 XP1000 NM",
        "No item-No Data : 355/65-15/9.75 XP800 MP",
        "No item-No Data : 4.00-8/3.00 XP1000 MP",
        "No item-No Data : 4.00-8/3.00 XP1000 NM",
        "No item-No Data : 4.00-8/3.00 XP800 MP",
        "No item-No Data : 5.00-8/3.00 XP1000 MP",
        "No item-No Data : 5.00-8/3.00 XP1000 NM",
        "No item-No Data : 5.00-8/3.00 XP800 MP",
        "No item-No Data : 6.00-9/4.00 XP1000 MP",
        "No item-No Data : 6.00-9/4.00 XP1000 NM",
        "No item-No Data : 6.00-9/4.00 XP800 MP",
        "No item-No Data : 6.50-10/5.00 XP1000 MP",
        "No item-No Data : 6.50-10/5.00 XP1000 NM",
        "No item-No Data : 6.50-10/5.00 XP800 MP",
        "No item-No Data : 7.00-12/5.00 XP1000 MP",
        "No item-No Data : 7.00-12/5.00 XP1000 NM",
        "No item-No Data : 7.00-12/5.00 XP800 MP",
        "No item-No Data : 7.00-15/5.50 XP1000 MP",
        "No item-No Data : 7.00-15/5.50 XP1000 NM",
        "No item-No Data : 7.00-15/5.50 XP800 MP",
        "No item-No Data : 7.50-15/6.50 XP800 MP",
        "No item-No Data : 8.25-15/6.50 XP1000 MP",
        "No item-No Data : 8.25-15/6.50 XP1000 NM",
        "No item-No Data : 8.25-15/6.50 XP800 MP"
      ],
      "render": "select",
      "isEditable": true
    },
    {
      "name": "tire_item_type",
      "label": "Tire item type",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [
        "Default item construction",
        "Prototype"
      ],
      "render": "radio",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "not_show_1",
    },
    {
      "name": "construction_type",
      "label": "Construction type",
      "value": "Real",
      "type": "string",
      "isRequired": true,
      "options": [
        "Real",
        "Virtual"
      ],
      "render": "radio",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "not_show_1"

    },
    {
      "name": "total_section_height_mm",
      "label": "Total section height (mm)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "dimensions"
    },
    {
      "name": "pit_stop_line",
      "label": "Pit Stop Line",
      "value": false,
      "type": "bool",
      "isRequired": true,
      "options": [],
      "render": "checkbox",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "base_section_height_mm",
      "label": "Base section height (mm)",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "cushion_section_height_mm",
      "label": "Cushion section height (mm)",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "tread_section_height_mm",
      "label": "Tread section height (mm)",
      "value": 0,
      "type": "number",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "base_section_height_percent",
      "label": "Base section height %",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "percentage",
      "action_fields": [
        "base_section_height_mm",
        "total_section_height_mm"
      ],
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "cushion_section_height_percent",
      "label": "Cushion section height %",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "percentage",
      "action_fields": [
        "cushion_section_height_mm",
        "total_section_height_mm"
      ],
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "tread_section_height_percent",
      "label": "Tread section height %",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "percentage",
      "action_fields": [
        "tread_section_height_mm",
        "total_section_height_mm"
      ],
      "groupRender": "subSection",
      "group": "dimensions"

    },
    {
      "name": "base_compound_code_select",
      "label": "Base compound",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "compounds"

    },
    {
      "name": "cushion_compound_code_select",
      "label": "Cushion compound",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "compounds"

    },
    {
      "name": "tread_compound_code_select",
      "label": "Tread compound",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "compounds"

    },
    {
      "name": "bonding_compound_code_select",
      "label": "Bonding compound",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "compounds"

    },
    {
      "name": "construction_identification_string",
      "label": "Construction identification string",
      "value": "",
      "type": "string",
      "isRequired": true,
      "options": [],
      "render": "select",
      "action": "concatenate_construction",
      "action_fields": [
        "tire_item_type",
        "construction_type",
        "pit_stop_line",
        "total_section_height_mm",
        "base_section_height_mm",
        "cushion_section_height_mm",
        "tread_section_height_mm",
        "product_identification_string",
        "base_compound_code_select",
        "cushion_compound_code_select",
        "tread_compound_code_select",
        "bonding_compound_code_select"
      ],
      "isEditable": false
    }
  ],
  "details": [
    {
      "name": "weight_bead_bundle_kg",
      "label": "Bead bundle weight (kg)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_1",
      "label": "Component name 1",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_1",
      "label": "Component weight 1",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_2",
      "label": "Component name 2",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_2",
      "label": "Component weight 2",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_3",
      "label": "Component name 3",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_3",
      "label": "Component weight 3",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_4",
      "label": "Component name 4",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_4",
      "label": "Component weight 4",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_5",
      "label": "Component name 5",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_5",
      "label": "Component weight 5",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_name_6",
      "label": "Component name 6",
      "value": "",
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "additional_component_weight_6",
      "label": "Component weight 6",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "additional_components"

    },
    {
      "name": "base_weight_kg",
      "label": "Base weight (kg)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "base_volume_cm3",
      "label": "Base volume (cm^3)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula1",
      "action_fields": [
        "base_weight_kg",
        "base_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "cushion_weight_kg",
      "label": "Cushion weight (kg)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "cushion_volume_cm3",
      "label": "Cushion volume (cm^3)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula1",
      "action_fields": [
        "cushion_weight_kg",
        "cushion_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "tread_weight_kg",
      "label": "Tread weight (kg)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "tread_volume_cm3",
      "label": "Tread volume (cm^3)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula1",
      "action_fields": [
        "tread_weight_kg",
        "tread_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "bonding_weight_kg",
      "label": "Bonding weight (kg)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true,
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "bonding_volume_cm3",
      "label": "Bonding volume (cm^3)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula1",
      "action_fields": [
        "bonding_weight_kg",
        "bonding_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_2"

    },
    {
      "name": "total_compound_weight",
      "label": "Total Compound weight",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula2",
      "action_fields": [
        "base_weight_kg",
        "cushion_weight_kg",
        "tread_weight_kg",
        "bonding_weight_kg"
      ],
      "groupRender": "subSection",
      "group": "no_show_3"

    },
    {
      "name": "total_compound_volume",
      "label": "Total Compound volume",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula3",
      "action_fields": [
        "base_weight_kg",
        "base_compound_code_select",
        "cushion_weight_kg",
        "cushion_compound_code_select",
        "tread_weight_kg",
        "tread_compound_code_select",
        "bonding_weight_kg",
        "bonding_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_3"

    },
    {
      "name": "total_tire_compound_cost_usd",
      "label": "Total tire compound cost(USD)",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula4",
      "action_fields": [
        "base_weight_kg",
        "base_compound_code_select",
        "cushion_weight_kg",
        "cushion_compound_code_select",
        "tread_weight_kg",
        "tread_compound_code_select",
        "bonding_weight_kg",
        "bonding_compound_code_select"
      ],
      "groupRender": "subSection",
      "group": "no_show_3"

    },
    {
      "name": "total_components_weight",
      "label": "Total components weight",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula5",
      "action_fields": [
        "additional_component_weight_1",
        "additional_component_weight_2",
        "additional_component_weight_3",
        "additional_component_weight_4",
        "additional_component_weight_5",
        "additional_component_weight_6"
      ],
      "groupRender": "subSection",
      "group": "no_show_3"

    },
    {
      "name": "total_tire_weight",
      "label": "Total tire weight",
      "value": 0,
      "type": "number",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": false,
      "action": "formula6",
      "action_fields": [
        "base_weight_kg",
        "cushion_weight_kg",
        "tread_weight_kg",
        "bonding_weight_kg",
        "additional_component_weight_1",
        "additional_component_weight_2",
        "additional_component_weight_3",
        "additional_component_weight_4",
        "additional_component_weight_5",
        "additional_component_weight_6"
      ],
      "groupRender": "subSection",
      "group": "no_show_3"

    },
    {
      "name": "construction_note",
      "label": "Construction note",
      "value": 0,
      "type": "string",
      "isRequired": false,
      "options": [],
      "render": "select",
      "isEditable": true
    }
  ]
}