import { extendTheme, theme, defineStyleConfig } from "@chakra-ui/react";
import { defineStyle } from '@chakra-ui/react';


export const styles = {
  global: ({ colorMode }) => ({
    body: {
      height: "100%",
      margin: 0,
      // backgroundColor: "#718096",
      webkitFontSmoothing: "antialiased",
      mozOsxFontSmoothing: "grayscale",
      userSelect: "none",
      fontFamily:
        "Lexend Exa",
    },

  }),
};

const buttonPrimary = defineStyle({
  background: "brand.100",
  color: "white",
  marginX: ["0.2rem", "1rem"],
  width: ['120px', '120px', '200px'],
  borderRadius: "0",
  //fontStyle:"Lexend Exa Regular",
  _hover: {
    background: 'brand.300'
  }

})

const buttonSecondary = defineStyle({
  marginX: ["0.2rem", "1rem"],
  width: '200px',
  borderRadius: "2px",
  border: '1px solid',
  borderColor: "brand.100",
  color: "brand.100",
  _hover: {
    background: 'brand.300',
    color: "white",
    border: '0px'
  }

})

const buttonExternalLink = defineStyle({
  background: 'brand.400',
  size: 'md',
  color: "white",
  marginX: "1rem",
  width: '200px',
  borderRadius: "0",
  _hover: {
    background: 'brand.300'
  }
})

const buttonPrimarySize = defineStyle({
  h: ['20px', '40px', '60px']
})

const buttonSecondarySize = defineStyle({
  h: '40px'
})

const iconButtonPrimary = defineStyle({
  mx: '0.5rem',
  //bg: 'white',
  color: 'brand.100',
  borderRadius: "0",
  cursor: 'pointer',
  _hover: { background: 'brand.300' }
})

const iconButtonSecondary = defineStyle({
  mx: '0.5rem',
  bg: 'brand.100',
  color: 'white',
  cursor: 'pointer',
  borderRadius: "0",
  _hover: { background: 'brand.300' }
})

export const buttonTheme = defineStyleConfig({
  variants: { buttonPrimary, buttonSecondary, buttonExternalLink, iconButtonPrimary, iconButtonSecondary},
  sizes: { buttonPrimarySize, buttonSecondarySize }
}
)


export const customTheme = extendTheme({
  ...theme,
  styles,
  components: {
    Button: buttonTheme,
  },
  colors: {
    ...theme.colors,
    brand: {
      100: "#9b6b55",
      200: "#f2f2f2",
      300: "#cccccc",
      400: "#e21e31"
    },

  },
})

export const customThemeOld = extendTheme({
  ...theme,
  components: {
    Button: {
      variants: {
        yokohamaPrimary: {
          colorScheme: '9b6b55',
          size: 'lg',
        },
        yokohamaSecondary: {
          colorScheme: '9b6b55',
          size: 'md',
          //variant:'outline'
        },
        yokohamaExternalLink: {
          colorScheme: 'e21e31',
          size: 'md',
          //variant:'outline'
        },

      }
    }
  },
  colors: {
    ...theme.colors,
    brand: {
      50: "#E8E0CB",
      100: "#DBCEAC",
      200: "#C8B682",
      300: "#BFA96D",
      400: "#A48C48",
      500: "#86723B",
      600: "#786634",
      700: "#69592E",
      800: "#4B4021",
      900: "#2D2614",
    },
  },
});


