import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, CardFooter, Divider, Flex, Grid, GridItem, Select, Text } from "@chakra-ui/react";
import { getDatabase, getOne } from "../../../services/crud";
import { Loader } from "../../shared/Loader";

type Metric = {
    label: string;
    values: number[];
    total: number
}

function GeneralTests() {

    const [constructions, setConstructions] = useState<string[]>(null)
    const [construction, setConstruction] = useState<string>(null)
    const [testType, setTestType] = useState<string>(null)
    const [metrics, setMetrics] = useState<Metric[]>(null)

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
     
        if (construction && testType) {
            setLoading(true)
            getOne("dashboard/db/constructions/metrics", `?construction_string=${construction}&test_type=${testType}`).then((resp) => {
                setMetrics(resp?.data)
                setLoading(false)
            })
        }
    }, [construction, testType])

    useEffect(() => {
        // call api for constructions
        getDatabase("dashboard/db/attr_list", "?table_name=Constructions&attribute=ConstructionIdentificationString&order_by=ConstructionId"
        ).
            then((resp) => {
                setConstructions(resp?.data)
            })
        //setConstructions(["construction1", "construction2"])

    }, [])

    return (
        <Box
            pos="fixed"
            left="80"
            h="auto"
            pb="10"
            w="auto"
            p="1rem"
        >
            <Box pt="0.5rem" >
                <Text fontSize={"2xl"} mb="1rem">General Tests</Text>
            </Box>
            <Box>
                {/* <Tabs colorScheme="gray">
                    <TabList>
                        <Tab fontSize={"2xl"}>Active</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel> */}
                            <Flex direction="column" width="8xl" gap="6">
                                <Flex direction={"row"} gap="10">
                                    <Flex direction={"column"} width="xl" gap="2">
                                        <label>Construction ID</label>
                                        <Select placeholder="Select construction" onChange={(e) => setConstruction(e.target?.value)}>
                                            {constructions &&
                                                constructions.map((construction) =>
                                                    <option value={construction}>{construction}</option>)
                                            }
                                        </Select>
                                    </Flex>
                                    <Flex direction={"column"} width="xl" gap="2">
                                        <label>Test type</label>
                                        <Select placeholder="Select test type" onChange={(e) => setTestType(e.target?.value)}>
                                            <option value={'Real'}>{"Real"}</option>
                                            <option value={'Virtual'}>{"Virtual"}</option>
                                        </Select>
                                    </Flex>
                                </Flex>
                                <Loader isLoading={loading}>
                                <Grid templateColumns={"repeat(6, 1fr)"} gap={6}>
                                    {metrics &&
                                        metrics.map((metric) => (
                                            <GridItem >
                                                <Flex bg="brand.200" mb="1rem" h="60px" border="1px solid" borderColor={"brand.300"}
                                                    alignItems={"center"} pl="0.5rem">
                                                    <Text fontSize={"lg"} >
                                                        {metric?.label}
                                                    </Text>
                                                </Flex>
                                                <Card bg="brand.300" h="lg">
                                                    <CardBody p="-0.3rem">
                                                    <Flex direction="column" display={"block"} overflowY={"scroll"}
                                                    sx={{
                                                        '&::-webkit-scrollbar': {
                                                          width: '8px',
                                                          borderRadius: '8px',
                                                          backgroundColor: `rgba(0, 0, 0, 0.2)`,
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                          backgroundColor: `rgba(0, 0, 0, 0.2)`,
                                                        },
                                                      }}>
                                                        {metric.values.map((value) =>
                                                            <Flex direction="row" alignItems={"space-between"} mx="0.5rem" my="1rem"
                                                            border="1px solid" borderColor={"brand.200"}>
                                                                <Flex direction="column" mr="1rem" pl="0.5rem" gap="1">
                                                                    <Text>
                                                                        TestID
                                                                    </Text>
                                                                    <Text>
                                                                        {value["Test ID"]}
                                                                    </Text>
                                                                </Flex>
                                                                <Flex direction="column" pr="0.5rem" gap="1">
                                                                    <Text>
                                                                        Date
                                                                    </Text>
                                                                    <Text>
                                                                        {value.Date?.split("T")[0]}
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </Flex>
                                                    </CardBody>
                                                    <Divider orientation="horizontal" width="90%" alignSelf="center" border="0.5px solid" borderColor={"brand.100"}/>
                                                    <CardFooter h="100px">
                                                        <Flex direction="column" mt="-0.5rem">
                                                            <Text h="50px">
                                                            {metric?.label + " total"}
                                                            </Text>
                                                            <Text mb="-0.5rem">
                                                                {metric?.total}
                                                            </Text>
                                                        </Flex>
                                                    </CardFooter>
                                                </Card>
                                            </GridItem>
                                        )
                                        )
                                    }
                                </Grid>
                                </Loader>
                            </Flex>
                        {/* </TabPanel>
                    </TabPanels>
                </Tabs> */}
            </Box>

        </Box>
    )
}




export default GeneralTests;