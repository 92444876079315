import domtoimage from 'dom-to-image-more';
import * as XLSX from 'xlsx/xlsx.mjs';

import {writeXLSX} from "xlsx";
import ExcelJS from "exceljs";

//return dataURL.replace(/^data:image\/?[A-z]*;base64,/);


export async function getCanvasB64(ref) {
    const canvas = await domtoimage.toCanvas(ref.current)
    var dataURL = canvas.toDataURL("image/png");
    return dataURL
}


export function exportGraphToJpeg(ref, fileName, afterResponseAction) {
    if (ref.current) { //Type Guard
        console.log("exporting graph")

        domtoimage
            .toJpeg(ref.current, {
                width: 800 * 3, // Image quality stuff
                height: 800 * 2.2,
                quality: 1,
                style: {
                    transform: 'scale(' + 3 + ')',
                    transformOrigin: 'top left',

                },
                bgcolor: '#ffffff'

            })
            .then((dataUrl) => {
                afterResponseAction()
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `${fileName}.jpg`;
                link.click();
            })
            .catch(function (error) {
                console.error('Error exporting chart:', error);
            });
    }

}


export function downloadFileCsv(data, fileName) {
    console.log(data)
    console.log("downloading csv")
    const download = function (data) {
        const blob = new Blob([data], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `${fileName}.csv`);
        a.click()
    }

    const csvmaker = function (data) {
        console.log(data)
        let csvRows = [];
        const headers = Object.keys(data);
        console.log(headers)
        csvRows.push(headers.join(','));
        let valuess = {}
        Object.entries(data).map(([key, values]) => {
            values.map((value, index) => {
                if (Object.keys(valuess).includes(index.toString())) {
                    valuess[index.toString()].push(value)
                } else {
                    valuess[index.toString()] = [value]
                }
            })

        })
        Object.entries(valuess).map(([key, val]) => {
            const v = val.join(',');
            console.log(v)
            csvRows.push(v)

        })
        console.log(csvRows)
        return csvRows.join('\n')
    }

    const csvdata = csvmaker(data);
    download(csvdata);

}

export async function downloadFileXlsx(data, fileName, imageRefs = null) {

    const wb = new ExcelJS.Workbook();

    let columns = []
    Object.keys(data[0][0]).map((key) => {
        columns.push({
            header: key,
            key: key,
            width: 10,
        })
    })

    data.map((slot, index) => {
        const ws = wb.addWorksheet(`Slot ${index + 1}`);
        ws.columns = columns
        ws.addRow({
            ...slot[0]
        })

    })

    if (imageRefs) {

        const imageWs = wb.addWorksheet('Graphs');

        let col = 1

        for (const element of imageRefs) {
            const Base64Image = await getCanvasB64(element)

            const imageId = wb.addImage({
                base64: Base64Image,
                extension: 'png',
            });

            imageWs.addImage(imageId, {tl: {col: col, row: 2}, ext: {width: 800, height: 600}})

            col = col + 11
        }
    }

    wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });


}

