import React, { useState } from "react";
import { Box, Flex, Button, Text, Divider, Icon, Tooltip, Textarea } from "@chakra-ui/react";
import { formatName } from "../../../../utils/formatUtils";
import { FiTriangle } from "react-icons/fi";

function Summary({ constructionId, data, isSaveDisabled, onSave }) {


    const [comments, setComments] = useState<string>(null)
    /* useEffect(() => {
        /* const params = action === 'add' ? `?table_name=${databaseName}` : `?table_name=${databaseName}&row_id=${elementId}`
        getOne("dashboard/aitests/form/data", params).then((resp) => {
            setData(resp?.data);
            //setUpdatedData(resp?.data);
            //setFormData(resp?.data)
        }) */
    /* 
            const dataTempWithGroups = {
                construction: { label: "construction", value: "Construction prova" },
                constructionFields: [
                    {
                        label: "Base",
                        value: "23",
                        group: "not_show",
                        groupRender: "subSection"
                    },
                    {
                        label: "Cushion",
                        value: "32",
                        group: "not_show",
                        groupRender: "subSection"
                    },
                    {
                        label: "Tread",
                        value: "43",
                        group: "not_show",
                        groupRender: "subSection"
                    },
                    {
                        label: "Base",
                        value: "23",
                        group: "volumes",
                        groupRender: "subSection"
                    },
                    {
                        label: "Cushion",
                        value: "32",
                        group: "volumes",
                        groupRender: "subSection"
                    },
                    {
                        label: "Tread",
                        value: "43",
                        group: "volumes",
                        groupRender: "subSection"
                    },
                    {
                        label: "Base",
                        value: "23",
                        group: "weights",
                        groupRender: "subSection"
                    },
                    {
                        label: "Cushion",
                        value: "32",
                        group: "weights",
                        groupRender: "subSection"
                    },
                    {
                        label: "Tread",
                        value: "43",
                        group: "weights",
                        groupRender: "subSection"
                    },
                    {
                        label: "Base",
                        value: "23",
                        group: "costs",
                        groupRender: "subSection"
                    },
                    {
                        label: "Cushion",
                        value: "32",
                        group: "costs",
                        groupRender: "subSection"
                    },
                    {
                        label: "Tread",
                        value: "43",
                        group: "costs",
                        groupRender: "subSection"
                    },
                    {
                        label: "Base",
                        value: "23",
                        group: "Other infos",
                        groupRender: "subSection"
                    },
                    {
                        label: "Cushion",
                        value: "32",
                        group: "Other infos",
                        groupRender: "subSection"
                    },
                    {
                        label: "Tread",
                        value: "43",
                        group: "Other infos",
                        groupRender: "subSection"
                    }]
            }
            const d = groupFlatFormData(dataTempWithGroups.constructionFields)
            setData(d)
            setConstruction(dataTempWithGroups?.construction)
    
        }, [])
     */

    return (
        <Box mx="2rem" >
            {data && data.length > 0 && constructionId &&
                <Flex direction={"column"} >
                    <Flex direction={"column"}>
                        <label>Construction</label>

                        <Box bg="white" border="1px solid" borderColor="brand.300" borderRadius={"5px"} h="30px" >
                            <Tooltip label={constructionId}  ><Text isTruncated>{constructionId}</Text></Tooltip>
                        </Box>
                    </Flex>
                    {/* <Field field={construction} onChange={null} section={""} /> */}
                    <Flex direction="column" height={"480px"} display="block" overflowY={"scroll"}
                    >
                        {
                            data.map((section) => {
                                return (
                                    <>
                                        {!section.name.startsWith("not_show") &&
                                            <Flex direction={"column"} alignItems="center" my="2rem" >
                                                <Divider borderColor={"brand.100"} variant={"dashed"} mb="0.5rem" />
                                                <Text fontWeight={"bold"}>{formatName(section.name)}</Text>
                                            </Flex>}
                                        <Flex direction="row" flexFlow="row wrap" my="2rem">
                                            {section?.value.map((field) => (
                                                <Flex direction={"column"} width={"fit-content"} mx="0.5rem"  mt="0.5rem">
                                                    <label>{formatName(field.label)}</label>
                                                    {field.render === "textarea" ?
                                                        <Textarea placeholder={field?.value} h="10rem" w="360px"
                                                            onChange={(e) => setComments(e.target.value)} border="1px solid"
                                                            borderColor={"brand.300"} />
                                                        :
                                                        <Flex bg="white" justify={"right"} border="1px solid" 
                                                        borderColor="brand.300" borderRadius={"5px"} h="30px">
                                                            <Text mx="0.5rem" >{field.value}</Text>
                                                        </Flex>}
                                                    {field?.inner &&
                                                        <Flex direction="row" width={"full"} my="0.5rem" alignItems={"center"}>
                                                            <Icon as={FiTriangle} borderRadius={0} mx="1rem" />
                                                            <Flex bg="white" justify={"right"} border="1px solid" borderColor="brand.300" width="100%" borderRadius={"5px"} h="30px">
                                                                <Text mx="0.5rem" >{field?.inner?.delta}</Text>
                                                            </Flex>
                                                        </Flex>}
                                                </Flex>

                                            )
                                            )
                                            }
                                        </Flex>
                                    </>

                                )

                            })
                        }
                        <Flex justifyContent={"end"} mt="3rem" mb="2rem">
                            <Button variant={"buttonPrimary"} onClick={() => onSave(comments)}
                                isDisabled={isSaveDisabled}>Save in database</Button>
                        </Flex>
                    </Flex>
                </Flex>}
        </Box >

    )
}


export default Summary;