import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

function ResponsiveLineGraph({ data, graphRef, showLegend, graphStyle, index }) {

	const props = {}

	if (showLegend) {
		props["legends"] = [{
			anchor: 'top',
			direction: 'column',
			translateX: 40,
			translateY: -110,
			itemWidth: 650,
			itemHeight: 40,
			symbolSize: 6,
			symbolShape: 'square',
			itemsSpacing: -20,

		}
		]
	}
	const marginLeft =  80 
	const marginRight =  40
	return (
		<Flex height="xl" width={"800px"} bg="white"
			ref={graphRef}
		>
			<ResponsiveLine
				curve={graphStyle === "static" ? "basis" : "linear"}
				data={data.dataPoints}
				enablePoints={graphStyle !== "static"}
				margin={{ top: 100, right: marginRight, bottom: 60, left: marginLeft }}
				xScale={{ type: 'point' }}
				yScale={{
					type: 'linear',
					min: 'auto',
					max: 'auto',

				}}

				//yFormat=" >-.2f"
				axisBottom={{
					legend: data.axisX,
					legendOffset: 35,
					legendPosition: 'middle'
				}}
				axisLeft={{
					legend: data.axisY,
					legendOffset: -55,
					legendPosition: 'middle'
				}}
				pointLabelYOffset={-12}
				{...props}

			/>


		</Flex>

	)
}



export default ResponsiveLineGraph;