import { privileges } from "../constants/privileges"
import { formatName } from "./formatUtils"


export function CheckComponentsPrivilege(component, userPrivileges) {
    const componentPrivileges = privileges.filter((privilege) => { return privilege?.components?.includes(formatName(component)) })
    return componentPrivileges.some((privilege) => { return userPrivileges.includes(privilege.name) })

}


export function CheckSettingsPrivilege(setting, userPrivileges) {

    const settingPrivileges = privileges.filter((privilege) => { return privilege?.components?.includes(setting) })

    return settingPrivileges.some((privilege) => { return userPrivileges.includes(privilege.name) })

}

export function CheckSectionPrivilege(section, action, userPrivileges) {
    const sectionPrivileges = privileges.filter((privilege) => {
        return privilege?.sections?.includes(section) &&
            privilege?.actions?.includes(action)
    })
    return sectionPrivileges.some((privilege) => { return userPrivileges.includes(privilege?.name) })

}

export function CheckDatabasePrivilege(database, section, action, userPrivileges, ignore = false) {
    const databasePrivileges = privileges.filter((privilege) => {
        return ((privilege?.database?.includes(database) ||
            (privilege?.database?.includes("All") &&
                privilege?.sections?.includes(section)))
            &&
            privilege?.actions?.includes(action))
    })
    return databasePrivileges.some((privilege) => { return userPrivileges.includes(privilege?.name) })

}



