import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Header from "./Header";
import Settings from "./Settings"
import Dashboard from "./Dashboard"
import Groups from "./settings/Groups";
import Group from "./settings/Group";
import DeleteReasons from "./settings/DeleteReasons";
import Ratings from "./settings/Ratings";
import Database from "./dashboard/Database";
import AITests from "./dashboard/AItests";
import Help from "./dashboard/Help";
import View from "./dashboard/database/index";
import { getAll } from "../services/crud";
import RequireAuth from "./login/RequireAuth";
import PermissionDenied from "./login/PermissionDenied";
import { databaseAllowedRoles } from "../constants/privileges";
import GeneralTests from "./dashboard/database/GeneralTests";

export const DataContext = React.createContext({})


function YokohamaTwsApp() {

    const [userPrivileges, setUserPrivileges] = useState<string[]>([]);

    useEffect(() => {
        //setUserPrivileges(["general_admin"])
        getAll('/get_privileges').then((resp) => {
            setUserPrivileges(resp?.data)
            //setUserPrivileges(["general_admin"])
        })

    }, []);

    return (

        <DataContext.Provider value={userPrivileges}>
            <Box>
                <Router>
                    <Header />
                    <Routes>
                        <Route element={<RequireAuth allowedRoles={["manage_groups", "manage_reasons", "manage_ratings"]}
                            userPrivileges={userPrivileges} />} >
                            <Route path="/settings" element={<><Settings /><Outlet /></>} >
                                <Route element={<RequireAuth allowedRoles={["manage_groups"]} userPrivileges={userPrivileges} />} >
                                    <Route path="/settings/groups" element={<Groups />} >
                                        <Route path="/settings/groups/:groupName" element={<Group />}
                                        />
                                    </Route>
                                </Route>
                                <Route element={<RequireAuth allowedRoles={["manage_reasons"]} userPrivileges={userPrivileges} />} >
                                    <Route path="/settings/delete_reasons/*" element={<DeleteReasons />} />
                                </Route>
                                <Route element={<RequireAuth allowedRoles={["manage_ratings"]} userPrivileges={userPrivileges} />} >
                                    <Route path="/settings/ratings/*" element={<Ratings />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path={'dashboard/undefined'} element={<><Text fontSize={'2rem'} mt={'10rem'} textAlign={'center'} lineHeight={'1'}>Click logo to begin</Text></>} ></Route>
                        {["/", "/dashboard"].map((el) =>
                            <Route path={el} element={<><Dashboard /><Outlet /></>} >
                                <Route element={<RequireAuth allowedRoles={databaseAllowedRoles} userPrivileges={userPrivileges} />} >
                                    <Route path="/dashboard/database" element={<Database />} >
                                        <Route path="general/general_tests" element={<GeneralTests />} />
                                        <Route path=":sectionName/:databaseName" element={<View />} >
                                            <Route path=":elementId" element={<Outlet />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route element={<RequireAuth allowedRoles={["ai_tester"]} userPrivileges={userPrivileges} />} >
                                    <Route path="/dashboard/AItests" element={<AITests />} />
                                </Route>
                                <Route path="/dashboard/help" element={<Help />} />
                            </Route>)}
                    </Routes>
                </Router>
            </Box>
        </ DataContext.Provider>
    );
}

export default YokohamaTwsApp;
