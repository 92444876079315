import React, { useEffect, useState } from "react";
import { Box, Flex, FormControl, FormLabel, Icon, Select, Text, Textarea } from "@chakra-ui/react";
import { ApiData, FormData, SettingsItem } from "../../../structures/types";
import { getAll, getOne } from "../../../services/crud";
import { formatISODate, formatName } from "../../../utils/formatUtils";
import { useParams } from "react-router";
import { conditioningValues } from "../../../constants/formula";
import _ from "lodash";
import { groupFlatFormData, groupFormData } from "../../../utils/dataStructureUtils";
import FormFields from "../shared/form/FormFields";
import { FiAlertTriangle } from "react-icons/fi";


export function DeleteForm({ onChangeData, checkDisabled }) {
    const [reasons, setReasons] = useState<SettingsItem[]>([])
    const [reason, setReason] = useState<string>("")
    const [motive, setMotive] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)


    useEffect(() => {
        onChangeData({ delete_reason: reason, motive: motive, deleted: true })
        checkDisabled(reason === null || reason === undefined || reason === "")
    }, [reason, motive])

    useEffect(() => {
        setLoading(true)
        getAll("reasons/all").then((resp) => {
            if (resp?.status === 200 || resp?.data) {
                setReasons(resp?.data)
                setLoading(false)
            }
            else {
                setLoading(false)
                setErrorMessage(resp?.response?.data || resp?.message)
            }
        })
    }, [])

    return (
        <Box m="1rem">
            <FormControl isRequired={true} m="1rem">
                <FormLabel fontSize={"xl"}>Deleting reason</FormLabel>
                <Select placeholder='Select reason' onChange={(e) => setReason(e.target.value)}>
                    {reasons?.map((reason) =>
                        <option value={reason?.name} >{reason?.label}</option>)}
                </Select>
            </FormControl>
            <FormControl m="1rem">
                <FormLabel fontSize={"md"}>Motive</FormLabel>
                <Textarea h="xs" onChange={(e) => setMotive(e.target.value)} />
            </FormControl>
            <Flex direction="row" justifyContent={"end"} alignItems={"center"} mt="2rem" gap={2}>
                <Icon as={FiAlertTriangle} h="40px" w="40px" color="black" />
                <Text fontSize="lg">This element will not be selectable anymore</Text>
            </Flex>
        </Box>
    )
}


export function UpdateForm({ action, onChangeData, checkDisabled, onSetCalculationData, calculatedData, onSetToCalculate,
    onSetCurrencyData, onSetToGetCostUsd, costUsd }) {

    const { databaseName, elementId } = useParams()
    const [formSection, setFormSection] = useState<string>("")

    const [data, setData] = useState<{ [key: string]: FormData[] }>({})
    const [formData, setFormData] = useState<{ [key: string]: FormData[] }>({})
    const [editedData, setEditedData] = useState<{ [key: string]: ApiData }>({})

    const [isExistingItem, setIsExistingItem] = useState<boolean>(null)
    const [isInProduction, setIsInProduction] = useState<boolean>(null)
    const [costKg, setCostKg] = useState<number>(null)
    const [currency, setCurrency] = useState<string>(null)
    const [tireItemType, setTireItemType] = useState<string>(null)
    const [constructionType, setConstructionType] = useState<string>(null)

    const setValuesMapping = {
        is_existing_item: (value) => setIsExistingItem(value),
        is_in_production: (value) => setIsInProduction(value),
        cost_kg: (value) => setCostKg(value),
        currency: (value) => setCurrency(value),
        tire_item_type: (value) => setTireItemType(value),
        construction_type: (value) => setConstructionType(value)
    }

    const onChange = (fieldName, value, type, section, groupName = null) => {
        if (conditioningValues.includes(fieldName)) {
            setValuesMapping[fieldName](value)
        }
        let changedSections = { ...formData }
        let changedData = changedSections[section]
        const elementName = groupName ? groupName : fieldName
        let index = formData[section].findIndex((el) => { return el.name === elementName })
        let field = { ...formData[section][index] }
        if (groupName) {
            let groupIndex = field["value"].findIndex((el) => { return el.name === fieldName })
            let val = { ...field["value"][groupIndex] }
            val["value"] = value === 'true' ? true : value === 'false' ? false : value
            field["value"][groupIndex] = val
        }
        else {
            field["value"] = value === 'true' ? true : value === 'false' ? false : value
        }
        changedData[index] = field
        changedSections[section] = changedData
        setFormData(changedSections)

        let payloadData = { ...editedData }
        if (type === "datetime") {
            value = formatISODate(value)
        }
        payloadData[fieldName] = {
            value: value === "" ? null : value === 'true' ? true : value === 'false' ? false : value,
            type: type
        }
        setEditedData(payloadData)

    }

    const conditionFields = {
        is_existing_item: isExistingItem,
        is_in_production: isInProduction,
        cost_kg: costKg,
        tire_item_type: tireItemType,
        construction_type: constructionType

    }

    const checkRequiredOnCondition = (condition) => {
        const isreq = (conditionFields[condition.name] === condition.value) || (condition.value === "valorized" &&
            conditionFields[condition.name] !== "" && conditionFields[condition.name] !== null && conditionFields[condition.name] !== "0")
        return isreq
    }

    const checkDisabledOnCondition = (condition) => {
        const isDisabled = conditionFields[condition.name] === condition.value
        return isDisabled
    }

    useEffect(() => {
        if (currency && costKg) {
            onSetCurrencyData(currency, costKg)
        }
    }, [currency, costKg])

    useEffect(() => {
        if (calculatedData && calculatedData.length > 0) {            
            let newData = {}
            let payloadData = { ...editedData }
            
            const groupedData = groupFormData(formData)
            const groupedCalculateData = groupFlatFormData(calculatedData)
            
            Object.keys(groupedData).map((section) => {
                let sectionData = groupedData[section]
                newData[section] = []
                groupedCalculateData.map((el) => {
                    if (sectionData.filter((sectionEl) => sectionEl.name === el.name).length > 0) {
                        el.isEdited = Boolean(el.isEdited)
                        el.isRequired = Boolean(el.isRequired)
                        el.value = el.type === "bool" ? Boolean(el.value) : el.value
                        if(el?.options) {
                            el.options =  el.type === "bool" ? el.options.map((opt) => { return Boolean(opt) }) : el.options
                        }

                        newData[section].push(el)

                        if (el.type === "group") {
                            el.value.map((groupEl) => {
                                if (groupEl.action && (groupEl.value !== null)) {
                                    payloadData[groupEl.name] = {
                                        value: groupEl.value === 'true' ? true : groupEl.value === 'false' ? false : groupEl.value,
                                        type: groupEl.type
                                    }
                                }
                                if (groupEl.action === 'deviance' && (groupEl.value === null)) {
                                    payloadData[groupEl.name] = {
                                        value: groupEl.value === 'true' ? true : groupEl.value === 'false' ? false : null,
                                        type: groupEl.type
                                    }
                                }
                            })
                        }
                        else {
                            if (el.action && (el.value !== null)) {
                                payloadData[el.name] = {
                                    value: el.value === 'true' ? true : el.value === 'false' ? false : el.value,
                                    type: el.type
                                }
                            }
                        }
                    }
                })
            })
            setFormData(newData)
            setEditedData(payloadData)

        }

    }, [calculatedData])

    useEffect(() => {
        if (costUsd) {
            let changedSections = { ...formData }
            let changedData = changedSections["general"]
            let index = formData["general"].findIndex((el) => { return el.name === "cost_kg_usd" })
            let field = { ...formData["general"][index] }
            field["value"] = costUsd.toString()
            changedData[index] = field
            changedSections["general"] = changedData
            setFormData(changedSections)

            let payloadData = { ...editedData }
            payloadData["cost_kg_usd"] = {
                value: costUsd.toString(),
                type: "number"
            }
            setEditedData(payloadData)
        }
    }, [costUsd])

    useEffect(() => {
        if (formData) {
            const values = Object.values(formData).flat()
            const missingRequired = values.some((el: FormData) => {
                let missingRequiredGroup = false
                if (el?.type === "group" && el?.isGroupRequired) {
                    missingRequiredGroup = el?.value?.some((groupEl) => {
                        return (groupEl?.requiredOnCondition ? checkRequiredOnCondition(groupEl?.requiredOnCondition) :
                            groupEl.isRequired)
                            &&
                            (groupEl.value === null || groupEl.value === undefined || groupEl.value === "")
                    })
                }
                return missingRequiredGroup ||
                    (el?.requiredOnCondition ? checkRequiredOnCondition(el?.requiredOnCondition) : el.isRequired)
                    &&
                    (el.value === null || el.value === undefined || el.value === "")
            })
            checkDisabled(missingRequired)
            onSetCalculationData(formData)
            if (Object.values(formData).flat().some((el) => { return el?.action })) {
                onSetToCalculate(true)
            }
            if (Object.values(formData).flat().some((el) => { return el?.render === "currency" })) {
                const costKgFields = Object.values(formData).flat().filter((el) => { return el?.name === "cost_kg" })
                if (costKgFields.length > 0) {
                    setCostKg(costKgFields[0].value)
                }
                const currencyFields = Object.values(formData).flat().filter((el) => { return el?.name === "currency" })
                if (currencyFields.length > 0) {
                    setCurrency(currencyFields[0].value)
                }
                onSetToGetCostUsd(true)
            }

        }

    }, [formData])

    useEffect(() => {
        if (editedData) {
            onChangeData(editedData)
        }
    }, [editedData])


    useEffect(() => {
        if (data) {
            setFormSection(Object.keys(data).at(0))
            let payloadData = {}
            Object.values(data).flat().map((el: FormData) => {
                if ((el.value !== "" && el.value !== undefined && el.value !== null && el.value?.length !== 0 && el.value !== 0
                    && el.value !== "0") || (el.isRequired && (el.value === 0 || el.value === "0"))) {
                    payloadData[el.name] = {
                        value: el.value === 'true' ? true : el.value === 'false' ? false : el.value,
                        type: el.type
                    }
                }
                if (el.type === "datetime" && el.value) {
                    payloadData[el.name] = {
                        value: formatISODate(el.value),
                        type: el.type
                    }
                }
                el["defaultValue"] = el.value
            })
            setEditedData(payloadData)

            const groupedFormData = groupFormData(data)
            setFormData(groupedFormData)
        }
    }, [data])


    useEffect(() => {
        const params = action === 'add' ? `?table_name=${databaseName}` : `?table_name=${databaseName}&row_id=${elementId}`
        getOne("dashboard/db/form/data", params).then((resp) => {
            setData(resp?.data);
            //setUpdatedData(resp?.data);
            //setFormData(resp?.data)
        })
        /*    setData(constructionsGroupedTemp)
           setUpdatedData(constructionsGroupedTemp)
           setFormData(constructionsGroupedTemp) */

    }, [])


    return (
        <Flex direction="column">
            <Flex direction="row" fontSize="xl" bg="brand.200" h="3rem" alignItems={"center"}>
                {formData && Object.keys(formData).map((section) => (
                    <Text cursor="pointer" onClick={() => setFormSection(section)} mx="1rem"
                        as={section === formSection ? "u" : null}>
                        {formatName(section)}
                    </Text>)
                )
                }
            </Flex>
            {formData && Object.keys(formData).length > 0 &&
                <FormFields data={formData[formSection]} onChange={onChange} section={formSection}
                    checkRequiredOnCondition={checkRequiredOnCondition} checkDisabledOnCondition={checkDisabledOnCondition} />}
        </Flex>)
}
