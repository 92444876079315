import moment from "moment";


export const formatName = (name) => {return name.charAt(0).toUpperCase() + name.slice(1).replaceAll("_", " ") }

export const formatLabel = (label) => { return label.toLowerCase().replaceAll(" ", "_") }

export const formatItemName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1, -1).replaceAll("_", " ") + name.charAt(-1).replace("s", "")
}



export function formatDate(date: Date, withHour: boolean, locale = 'en-GB') {
    if (withHour)
        return new Date(date).toLocaleDateString(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    else
        return new Date(date).toLocaleDateString(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });
}



export function formatISODate(date: Date) {
    var tzoffset = (new Date()).getTimezoneOffset() * 120000; //offset in milliseconds
    var dateTemp = new Date(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).getTime()
    //var dateTemp = new Date(dateTest).getTime()
    console.log(dateTemp)
    var localISOTime = (new Date(dateTemp - tzoffset)).toISOString().slice(0, -1);
    console.log(localISOTime)
    return localISOTime.split('T')[0]
}
