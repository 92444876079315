import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { DBTable } from "./DBTable";
import { useNavigate, useParams } from "react-router";
import { getDatabase, saveOne } from "../../../services/crud";
import { Column, Filter } from "../../../structures/types";
import { FiPlusCircle } from "react-icons/fi";
import DBModal from "./DBModal";
import { formatISODate, formatItemName } from "../../../utils/formatUtils";
import { Loader } from "../../shared/Loader";
import { CheckDatabasePrivilege } from "../../../utils/privilegeUtils";
import { editorAction } from "../../../constants/privileges";
import { DataContext } from "../../YokohamaTwsApp";
import Details from "./Details";

function DBPanel({ type, activateUpdate, deactivateUpdate, update }) {

    const { databaseName, sectionName } = useParams();
    const navigate = useNavigate()

    const [elementId, setElementId] = useState<string>("")
    const [showRowData, setShowRowData] = useState<boolean>(false)
    const [columns, setColumns] = useState<Column[]>([])
    //{ name: "colonna1", label: "colonna 1", type: "number" },
    //{ name: "colonna2", label: "colonna 2", type: "number" },
    //{ name: "colonna3", label: "colonna 3", type: "number" }

    const [data, setData] = useState<Object[]>([])

    //{ _id: "dffvnv", colonna1: 1, colonna2: 2, colonna3: 4 },
    //{ _id: "dewdew", colonna1: 2, colonna2: 43, colonna3: 3 },
    //{ _id: "kedkwd", colonna1: 3, colonna2: 1, colonna3: 9 }

    const [loading, setLoading] = useState<boolean>(false)

    const [detailTitle, setDetailTitle] = useState<string>("")
    const [modalTitle, setModalTitle] = useState<string>("")


    const onRowClick = (id) => { setElementId(id); setShowRowData(true) }

    const onClose = () => { setShowRowData(false) }

    const [showModal, setShowModal] = useState<boolean>(false)

    const [filters, setFilters] = useState<{ [key: string]: Filter }>({})


    const onSave = (data) => {
        setShowModal(false)
        setLoading(true)
        saveOne("dashboard/db/save_row", `?table_name=${databaseName}`, data).then((resp) => {
            callDatabase()
        }).catch((error) => { console.log(error) })
    }

    const userPrivileges = useContext(DataContext)

    function handleFilterEvent(value: any, column: Column, isDate?: boolean) {

        if (value) {
            const columnFilter = {
                name: column.name,
                value: value,
                relationship: column.relationship,
                type: column.type
            }
            if (isDate) {
                columnFilter.value = formatISODate(value)

            }

            let newFilters = { ...filters }
            newFilters[column.name] = columnFilter
            setFilters(newFilters);
        }

        else {
            let newFilters = { ...filters }
            delete newFilters[column.name]
            setFilters(newFilters);
        }

    }


    function callDatabase() {
        setLoading(true)
        getDatabase("dashboard/db/table_rows", `?table_name=${databaseName}&is_active=${type === "active"}&start=${0}&limit=${10000000000}`,
            Object.values(filters)
        ).then((resp) => {
            setColumns(resp?.data?.columns)
            setData(resp?.data?.rows)
            setModalTitle(`Add new ${formatItemName(databaseName)}`)
            setLoading(false)

        })
    }

    const onConfirmAction = () => {
        activateUpdate()
        setElementId("")
    }

    useEffect(() => {

        setShowRowData(false)
        setFilters({})
        setElementId("")
        if (databaseName) {
            callDatabase()
        }
    }, [databaseName, type])

    useEffect(() => {
        if (elementId) {
            setDetailTitle(`${formatItemName(databaseName)} ${data.filter((el) => {
                return el._id === elementId
            })[0][columns[0].name]}`);
            navigate(`${elementId}`)
        }
    }, [elementId])

    useEffect(() => {
        if (update) {
            callDatabase()
            deactivateUpdate()
        }
    }, [update])


    return (
        <Flex direction="column">
            {type === "active" && CheckDatabasePrivilege(databaseName, sectionName, editorAction, userPrivileges) &&
                <Flex direction="row" alignItems={"center"} justifyContent={"end"} my="-1.5" mx="2rem">
                    <IconButton aria-label="add new" as={FiPlusCircle} onClick={() => { setShowModal(true) }}
                        variant={"iconButtonPrimary"} />
                    <Text fontSize="xl">Add new</Text>
                    {showModal && <DBModal title={modalTitle} isOpen={showModal} onClose={(() => { setShowModal(false) })}
                        action="add" onAct={onSave} />}
                </Flex>
            }

            <Flex direction="row" m="1rem">
                <Loader isLoading={loading}>
                    {columns && data &&
                        <Flex direction="column">
                            <Flex mb="1rem">
                                <Button variant="buttonSecondary" onClick={() => callDatabase()}>Apply filters</Button>
                            </Flex>
                            <Flex border="1px solid" borderColor={"brand.300"} w="30">

                                <DBTable columns={columns} data={data} width={showRowData ? "4xl" : "8xl"}
                                    maxWidth={showRowData ? true : false}
                                    height={data?.length > 7 ? "lg" : ""}
                                    filters={filters} elementId={elementId} onDoubleClick={onRowClick}
                                    handleFilterEvent={handleFilterEvent} />
                            </Flex>
                        </Flex>}
                </Loader>

                {showRowData &&
                    <Details type={type} title={detailTitle} onClose={onClose} onConfirmAction={onConfirmAction}
                        updateDetail={update} />
                }
            </Flex>
        </Flex>
    )
}

export default DBPanel;