import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Button,
  Text,
  Flex,
  Icon,
} from '@chakra-ui/react'
import { FiAlertTriangle } from 'react-icons/fi';

interface AlertProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
  onCofirm: () => void;
}

function Alert({ message, isOpen, onClose, onConfirm, warning = null }) {
  const cancelRef = React.useRef()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {message}
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure?
            {warning &&
              <Flex direction={"row"} alignItems={"center"} mt="2rem">
              <Icon as={FiAlertTriangle} color={"brand.400"} width="2.5rem" h="40px" mr="0.5rem"/>
              <Text> {warning} </Text>
            </Flex>}
            
          </AlertDialogBody>
          <AlertDialogFooter>
          
            <Button ref={cancelRef} onClick={onClose} variant={"buttonSecondary"}>
              Cancel
            </Button>
            <Button onClick={onConfirm} ml={3} variant={"buttonPrimary"}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}


export default Alert;