
import React from "react";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { formatName } from "../../../utils/formatUtils";
import AIPanel from "./AIPanel";


function AIView() {

    const AIsections = ["tire setup", "static tests", "hbu tests"]

    return (
        <Box
            //pos="fixed"
            h="full"
            pb="10"
            w="full"
            p="1rem"
        >
            <Box>
                <Tabs colorScheme="gray" variant='enclosed'>
                    <TabList>
                        {AIsections.map((section) => (
                            <Tab fontSize={"2xl"} > {formatName(section)}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {
                            AIsections.map((section) => (
                                <TabPanel>
                                    <AIPanel section={section} />
                                </TabPanel>
                            ))
                        }
                    </TabPanels>
                </Tabs>
                {/* <Flex direction="row">
                            <Text fontSize={"2xl"} cursor="pointer" onClick={() => setPanel("active")}>Active</Text>
                            <Text fontSize={"2xl"} cursor="pointer" onClick={() => setPanel("deleted")}>Deleted</Text>
                        </Flex>
                        <DBPanel type={panel} /> */}

            </Box>

        </Box>
    )
}




export default AIView;