
export const graphFileNamesStatic = ["STATIC - Vertical deflection", "STATIC - Conctact Area"]
export const graphFileNamesHbu = ["HBU - Temperature"]


export const slotSectionsMapping = {
    static: {
        Deflection_mm_25: 'def_25',
        Deflection_mm_50: 'def_50',
        Deflection_mm_75: 'def_75',
        Deflection_mm_100: 'def_100',
        Deflection_mm_125: 'def_125',
        ContactArea_cm2_25: 'ca_25',
        ContactArea_cm2_50: 'ca_50',
        ContactArea_cm2_75: 'ca_75',
        ContactArea_cm2_100: 'ca_100',
        ContactArea_cm2_125: 'ca_125',
        SpecificPressure_kgcm2_25: 'sp_25',
        SpecificPressure_kgcm2_50: 'sp_50',
        SpecificPressure_kgcm2_75: 'sp_75',
        SpecificPressure_kgcm2_100: 'sp_100',
        SpecificPressure_kgcm2_125: 'sp_125',
    },
    hbu: {
        TemperatureAtTime_0: 'temperature_time_0',
        TemperatureAtTime_60: 'temperature_time_60',
        TemperatureAtTime_120: 'temperature_time_120',
        TemperatureAtTime_180: 'temperature_time_180',
        TemperatureAtTime_240: 'temperature_time_240',
        TemperatureAtTime_300: 'temperature_time_300',
        TemperatureAtTime_360: 'temperature_time_360',
        TemperatureAtTime_420: 'temperature_time_420',
        TemperatureAtTime_480: 'temperature_time_480',
        TemperatureAtTime_540: 'temperature_time_540',
        TimeTo_180_celsius: 'time_to_180',
    }


}