

import React from "react";
import { useState } from 'react';
import { Flex, FormControl, Icon, Input, InputGroup, InputRightElement, Text, Tooltip } from '@chakra-ui/react';
import { Table as CTable, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import { FiFilter } from "react-icons/fi";
import { DBTableProps } from "../../../structures/interfaces";
import DatePicker from "../../shared/DatePicker";
import moment from "moment";



export function DBTable({ customKey = "", columns, data, filters, elementId, onDoubleClick, handleFilterEvent,
    width, height, maxWidth, ...props }: DBTableProps) {

    const [filterDate, setFilterDate] = useState(null)
    const [filterValues, setFilterValues] = useState({})

    const columnStyle = { minWidth: 200, width: 1500, borderRight: "1px solid", borderRightColor: "#cccccc" }
    

    return (
        <CTable variant="simple" w={width} size="md" overflow="scroll" h={height} //display={columns.length > 4 || data.length > 6 ? "block" : ""}//height:100px;  display:block;
            display={"block"} textAlign="center" >
            <Thead>
                <Tr>
                    {columns
                        ?.map((column) => (
                            <Th
                                key={column.name.concat('-').concat(customKey).concat('-')}
                                isNumeric={column.type === 'number'}
                                style={columnStyle}
                            >
                                <Text >
                                    {column.label}
                                </Text>
                            </Th>
                        ))}
                </Tr>
                <Tr>
                    {columns?.map((column) => (
                        <Th key={column.name.concat('-').concat(customKey).concat('-').concat('filter')}
                            isNumeric={column.type === 'number'}
                            style={{ minWidth: 200, width: 60, borderRight: "1px solid", borderRightColor: "#cccccc" }}

                        >
                            {filters &&
                                <Flex>
                                    <Icon
                                        as={FiFilter}
                                        boxSize={6}
                                        m={1}
                                    />
                                    <FormControl id={column.name} w="90%" maxW="180px">
                                        {column.type === "datetime" ?
                                            <DatePicker
                                                style={{ padding: '0' }}
                                                onChange={(event) => {
                                                    setFilterDate(event);
                                                    handleFilterEvent(event, column, true)
                                                }}
                                                selectedDate={
                                                    filterDate ? new Date(moment(filterDate).format('YYYY-MM-DD'))
                                                        : undefined
                                                }
                                                startDate={new Date()}
                                                isRange={false}
                                                isClearable={true}
                                            />
                                            :
                                            <InputGroup>
                                                <Input
                                                    size="sm"
                                                    placeholder={'Insert filter'}
                                                    onChange={(event) => {
                                                        handleFilterEvent(event.target.value, column, false);
                                                        setFilterValues((prevFilterValues) => ({
                                                        ...prevFilterValues,
                                                        [column.name]: event.target.value,
                                                        }));
                                                    }}
                                                    value={filterValues[column.name] || ''} />
                                                <InputRightElement>
                                                    <Text
                                                        onClick={() => {
                                                            handleFilterEvent('', column, false);
                                                            setFilterDate(null);
                                                            setFilterValues((prevFilterValues) => ({
                                                              ...prevFilterValues,
                                                              [column.name]: null,
                                                            }));
                                                          }}
                                                        fontSize={'0.7rem'}
                                                        cursor="pointer"
                                                        color="gray.500"
                                                        align={'center'}
                                                        mb={2}>X</Text>
                                                </InputRightElement>
                                            </InputGroup>
                                        }
                                    </FormControl>
                                </Flex>
                            }
                        </Th>

                    ))}
                </Tr>

            </Thead>
            <Tbody >
                {data?.map((row: any, i: number) => (
                    <Tr
                        key={i.toString().concat(customKey)}
                        cursor="pointer"
                        onClick={() => onDoubleClick(row?._id)}
                        //onDoubleClick={() => }
                        bg={row?._id === elementId ? 'blackAlpha.50' : 'transparent'}
                        textAlign={"center"}
                    >
                        {columns.map((column) => getRow(column, row, i))}
                    </Tr>
                ))
                }
            </Tbody>
        </CTable>
    );

    function getRow(column: { [key: string]: any }, row: any, i: number) {

        const style = { minWidth: 200, width: 60, borderRight: "1px solid", borderRightColor: "#cccccc" }
        if (true) {
            style['maxWidth'] = 300
        }

        const value = row[column.name]

        return (
            <Td
                key={column.name.concat(i).concat(customKey)}
                isNumeric={column?.type === 'number'}
                {...column?.styles}
                style={style}
            >
                {column?.type === "string" && value?.length > 120 ?
                    <Tooltip label={value} placement="left">
                        <Text textAlign={"left"} isTruncated={true} >
                            {value}
                        </Text>
                    </Tooltip>
                    :
                    <Text textAlign={"left"}  >
                        {value}
                    </Text>}
            </Td>
        );
    }
}
