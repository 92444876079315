import React from "react";
import { Flex, Spacer } from "@chakra-ui/react";
import LogoSection from "./LogoSection.tsx";
import Options from "./Options.tsx";


function Header() {

  return (
    <Flex alignItems='center' height="6rem" >
      <LogoSection />
      <Spacer />
      <Options />
    </Flex>
  )

}

export default Header;