import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, CardHeader, Flex, FormLabel, FormControl, Select, Checkbox, Text } from "@chakra-ui/react";
import { FormData } from "../../../../structures/types";
import { getAll, getOne } from "../../../../services/crud";
import { formatName } from "../../../../utils/formatUtils";
import { sectionHeightFieldName } from "../../../../constants/fieldNames";

function ProductSelection({ onChangeConstruction, onChangeProductId }) {
    const [treads, setTreads] = useState<string[]>([])
    const [products, setProducts] = useState<string[]>([])
    const [constructions, setConstructions] = useState<string[]>([])

    const [defaultConstruction, setDefaultConstruction] = useState<string>(null)
    const [defaultConstructionChecked, setDefaultConstructionChecked] = useState<boolean>(false)

    const [tread, setTread] = useState<string>("")
    const [product, setProduct] = useState<string>("")
    const [construction, setConstruction] = useState<string>("")
    const [constructionFields, setConstructionFields] = useState<FormData[]>([])

    //const [data, setData] = useState<{ [key: string]: FormData[] }>({})
    // const [formData, setFormData] = useState<FormData[]>([])

    const handleCheckbox = () => {
        setDefaultConstructionChecked(!defaultConstructionChecked)
    }

    useEffect(() => {
        if (construction !== defaultConstruction){
            setDefaultConstructionChecked(false)
        }
        if (construction) {
            getOne("dashboard/ai_test/tire_setup/detail", `?table_name=Constructions&value=${construction}`).then((resp) => {
                //setConstructions(resp?.data)
                setConstructionFields(resp?.data)
                let bondingCode = null
                let sectionHeight = null
                const bondingCodeField = resp?.data.filter((el) => { return el.label === "Compound bonding" })
                if (bondingCodeField && bondingCodeField.length > 0) {
                    // onChangeConstruction(construction, bondingCode[0].value)
                    bondingCode = bondingCodeField[0].value
                }
                const sectionHeightField = resp?.data.filter((el) => { return el.name === sectionHeightFieldName })
                if (sectionHeightField && sectionHeightField.length > 0) {
                    sectionHeight = sectionHeightField[0].value
                }

                onChangeConstruction(construction, bondingCode, sectionHeight)

            })
        }
        else {
            onChangeConstruction(construction)
        }

    }, [construction])

    useEffect(() => {
        if (defaultConstructionChecked && defaultConstruction) {
            setConstruction(defaultConstruction)
        }
    }, [defaultConstructionChecked])

    useEffect(() => {
        if (product) {/*call products api with param tread */
            onChangeProductId(product)
            getOne("dashboard/ai_test/tire_setup/constructions", `?product_is=${product}`).then((resp) => {
                //setConstructions(resp?.data)
                setDefaultConstruction(resp?.data?.default_value)
                setConstructions(resp?.data?.values)
                setDefaultConstructionChecked(false)
                setConstructionFields([])
            })
        }

    }, [product])

    useEffect(() => {
        if (tread) {/*call products api with param tread */
            getOne("dashboard/ai_test/tire_setup/products", `?tread_type=${tread}`).then((resp) => {
                setProducts(resp?.data)
                setDefaultConstructionChecked(false)
                setConstructionFields([])
                setConstructions([])
            })
        }
    }, [tread])

    useEffect(() => {
        /* const params = action === 'add' ? `?table_name=${databaseName}` : `?table_name=${databaseName}&row_id=${elementId}`
        getOne("dashboard/aitests/form/data", params).then((resp) => {
            setData(resp?.data);
            //setUpdatedData(resp?.data);
            //setFormData(resp?.data)
        }) */
        getAll("dashboard/ai_test/tire_setup/tread_types").then((resp) => {
            setTreads(resp?.data)
        })
        //setTreads(treadsTemp)
        // setProducts(productsTemp)
        //setConstructions(productsTemp)
        //setFormData(data)

    }, [])

    return (
        <Box>
            <Flex direction={"column"}>
                <Box mx="2rem">
                    <Box>
                        <FormControl
                            onChange={(e) => setTread(e.target?.value)}
                            width={"100%"}
                            mx="0.5rem" my="0.5rem">
                            <FormLabel>Tread type</FormLabel>
                            <Select placeholder="Select tread type"
                                bg="brand.300">{treads && treads.length > 0 &&
                                    treads.map((el) =>
                                        <option value={el}>{el}</option>)}</Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl
                            onChange={(e) => setProduct(e.target?.value)}
                            width={"100%"}
                            mx="0.5rem" my="0.5rem">
                            <FormLabel>Product</FormLabel>
                            <Select placeholder="Select product"
                                bg="brand.300">{products && products.length > 0 &&
                                    products.map((el) =>
                                        <option value={el}>{el}</option>)}</Select>
                        </FormControl>
                    </Box>
                </Box>
                <Card bg="brand.200" border="1px solid" borderColor={"brand.300"} mt="1rem">
                    <CardHeader textAlign={"center"} fontSize={"18px"} mb="-1.5rem" mt="-1rem">Constructions</CardHeader>
                    <CardBody>
                        <FormControl display="flex" flexDirection={"row"} isDisabled={!defaultConstruction}>
                            <FormLabel>Select default construction</FormLabel>
                            <Checkbox isChecked={defaultConstructionChecked} onChange={handleCheckbox} borderColor={'gray.600'} />
                        </FormControl>
                        {defaultConstruction && defaultConstructionChecked && <Text textAlign={'center'} fontSize={'11px'}>{defaultConstruction}</Text>}
                        <Box>
                            <FormControl
                                onChange={(e) => setConstruction(e.target?.value)}
                                width={"100%"}
                                mx="0.5rem" my="0.5rem"
                                defaultValue={defaultConstruction ? defaultConstruction : constructions[0]}>
                                <FormLabel>Construction</FormLabel>
                                <Select placeholder="Select construction" bg="brand.300" value={defaultConstructionChecked ? defaultConstruction : construction}>
                                    {constructions && constructions?.length > 0 &&
                                    constructions?.map((el) =>
                                        <option key={el} value={el} >{el}</option>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display="block" h="200px" overflowY={"scroll"}>
                            {constructionFields.length > 0 &&
                                <Flex direction="row" flexFlow={"row wrap"} mt="1rem" gap="1rem">
                                    {constructionFields.map((field) => (
                                        <Flex direction="column" >
                                            <label>{formatName(field.label)}</label>
                                            <Flex bg="white" justify={"right"} border="1px solid" borderColor="brand.300" borderRadius={"5px"} h="30px">
                                                <Text mx="0.5rem" >{field?.value}</Text>
                                            </Flex>
                                        </Flex>
                                    )
                                    )}
                                </Flex>
                            }
                        </Box>
                    </CardBody>
                </Card>
            </Flex>
        </Box >
    )
}


export default ProductSelection;