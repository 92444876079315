import axios from 'axios';
import urljoin from 'url-join';
import { errorHandler, saveResponseHandler, successHandler } from './interceptors.ts';

// SE IN PRODUZIONE PRENDE IN AUTOMATICO L'URL DEL SERVER COME BASEURL
const baseURL = process.env.REACT_APP_BASE_URL || "http://localhost:8080/"
console.log(baseURL)
const basePath = '/services';

const http = axios.create({
    baseURL: urljoin(baseURL, basePath)
    //baseURL: baseURL,
});

const httpSave = axios.create({
    baseURL: urljoin(baseURL, basePath)
    //baseURL: baseURL,

});

http.interceptors.response.use(
    (response) => successHandler(response),
    (error) => { errorHandler(error) },
);

httpSave.interceptors.response.use(
    (response) => saveResponseHandler(response),
    (error) => { errorHandler(error) },
);




export { baseURL, http, httpSave, basePath };
