
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Divider, Flex, Link, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { getAll } from "../../../services/crud";
import { useNavigate } from "react-router";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Section } from "../../../structures/types";
import { CheckSectionPrivilege, CheckDatabasePrivilege } from "../../../utils/privilegeUtils";
import { visualizeAction } from "../../../constants/privileges";
import { DataContext } from "../../YokohamaTwsApp";



function Sidebar() {

    const navigate = useNavigate()

    const [sections, setSections] = useState<Array<Section> | null>(null)
    const [sectionName, setSectionName] = useState<string>("")
    const [databaseName, setDatabaseName] = useState<string>("")

    const userPrivileges = useContext(DataContext)

    useEffect(() => {
        if (sectionName && databaseName) { navigate(`${sectionName}/${databaseName}`) }
    }, [databaseName, sectionName])

    useEffect(() => {
        /* setSections([{
            name: "Dependencies", tables: [
                { label: "Brands", name: "Brands" }, { label: "Compounds", name: "Compounds" },
                { label: "Products", name: "Products" }, { label: "Plants", name: "Plants" },
                { label: "Specs", name: "Specs" }]
        },
        { name: "Tests", tables: [{ label: "Test 1", name: "test_1" }, { label: "Test 2", name: "test_2" }] }
        ]) */
        console.log("calling side list")
        getAll("dashboard/db/side_list").then((resp) => {
            setSections(resp?.data?.map((db) => {
                return {
                    name: db.database_name,
                    tables: db.tables
                }
            }))

        }).catch((error) => { console.log(error); console.log(error?.response?.status) })
    }, [])



    return (
        <Box
            as="nav"
            pos="fixed"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg="brand.200"
            borderRightWidth="1px"
            w="80"
            borderColor="brand.300"
            py="1rem"

        >
            <Flex direction="column" fontSize="3xl" m="1rem" alignItems={"center"}>
                <Text>Database</Text>
                <Divider borderColor="brand.100" w="95%" />
            </Flex>
            <Flex direction="column" as="nav" fontSize="2xl" aria-label="Main Navigation" ml="1.25rem">
                <Accordion defaultIndex={[0, 1]} allowMultiple >
                    {sections && sections?.map((section) => {
                        if (CheckSectionPrivilege(section.name, visualizeAction, userPrivileges))

                            return (
                                <AccordionItem border={"0px"}>
                                    {({ isExpanded }) => (
                                        <>
                                            <h2>
                                                <AccordionButton>
                                                    {isExpanded ? (
                                                        <FiChevronDown fontSize={"2xl"} />
                                                    ) : (
                                                        <FiChevronRight fontSize={"2xl"} />
                                                    )}
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        <Text as="i" fontSize="23px">{section.name}</Text>
                                                    </Box>
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4} >
                                                <Flex direction="column" ml="2.5rem">
                                                    {section?.tables?.map((table) => {
                                                        if (CheckDatabasePrivilege(table?.name, section.name, visualizeAction,
                                                            userPrivileges)) {
                                                            return (
                                                                <Link fontSize="xl" cursor="pointer" onClick={() => {
                                                                    setSectionName(section.name);
                                                                    setDatabaseName(table?.name)
                                                                }}>{table?.label}</Link>

                                                            )
                                                        }
                                                    })}
                                                </Flex>
                                            </AccordionPanel>
                                        </>
                                    )}
                                </AccordionItem>
                            )
                    })}
                </Accordion>
            </Flex>
            {CheckDatabasePrivilege("general_tests", "Tests", visualizeAction, userPrivileges) &&
                <Flex direction="column" fontSize="23px" mx="1rem" alignItems={"center"} >
                    <Link cursor="pointer" onClick={() => {
                        setSectionName("general");
                        setDatabaseName("general_tests")
                    }}>General tests</Link>
                </Flex>}
        </Box>
    )

}

export default Sidebar;